import Button from 'commonui/Button';
import Input from 'commonui/Input';
import { inject, observer } from 'mobx-react';
import * as React from "react";
import * as Class from "classnames";
import styles from "./AccountSettings.scss";
import { observable, action } from 'mobx';
import { bind } from 'decko';
import * as $ from "jquery";
import gearsDialog from "commonui/Dialog";


@inject("resolve")
@observer
export default class AccountSettings extends React.Component<{}, never> {
    @observable private newPassword: string = "";
    @observable private confirmPassword: string = "";
    @observable private newPasswordError: string = "";
    @observable private confirmPasswordError: string = "";

    private get canSave() {
        if (!this.newPassword || !this.confirmPassword) {
            return false;
        }
        if (this.newPassword !== this.confirmPassword) {
            return false;
        }
        return true;
    }

    @bind
    private catchSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const formData = new FormData();
        $.ajax({
            url: `/users/${Gears.user.id}`,
            method: "PUT",
            data: {
                user: {
                    password: this.newPassword,
                },
            }
        }).then((result) => {
            if (typeof result === "object") {
                this.props.resolve();
                gearsDialog({
                    title: "Password successfully changed",
                    buttons: ["OK"],
                });
            } else {
                gearsDialog({
                    title: "Error",
                    content: result,
                    buttons: ["OK"],
                });
            }
        });
    }

    @bind
    @action
    private onBlurNewPassword() {
        this.newPasswordError = "";
        if (this.newPassword.length < 6 || this.newPassword.toLowerCase() === this.newPassword) {
            this.newPasswordError = "Password must be at least 6 characters long and must not consist solely of lowercase letters";
        }
    }

    @bind
    @action
    private onBlurConfirmPassword() {
        const passwordMatch = this.newPassword === this.confirmPassword;
        this.confirmPasswordError = passwordMatch ? "" : "Passwords do not match"
    }

    public render() {
        return (
            <div className={styles["account-settings"]}>
                <form
                    onSubmit={this.catchSubmit}>
                    <fieldset>
                        <legend>Change Password</legend>
                        <Input
                            label="New Password"
                            observable={this}
                            value="newPassword"
                            password
                            name="new_password"
                            error={this.newPasswordError}
                            onBlur={this.onBlurNewPassword} />
                        <Input
                            label="Confirm Password"
                            observable={this}
                            value="confirmPassword"
                            password
                            error={this.confirmPasswordError}
                            onBlur={this.onBlurConfirmPassword}
                            name="confirm_password" />
                    </fieldset>
                    <div>
                        <Button
                            title="Cancel"
                            flat
                            onClick={this.props.resolve} />
                        <Button
                            title="Save"
                            disabled={!this.canSave}
                            coloured
                            type="submit"
                            className="blue" />
                    </div>
                </form>
            </div>
        )
    }
}
