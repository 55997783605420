import * as moment from "moment-timezone";

export function datetimeTooltip(format?: string | any, options: {format?: string, tooltip_format?: string} = {}): (time: Date) => string {
    if (format != null) {
        if (format.format != null) {
            options = format;
        } else {
            options.format = format;
        }
    }
    options.format == null && (options.format = "h:mm A");
    options.tooltip_format == null && (options.tooltip_format = "DD MMMM YYYY h:mm:ss A");
    return function timeFormatter(time: Date): string {
        if (time == null) {
            return "";
        }
        return (
            "<span data-toggle=\"tooltip\" title=\"" +
            moment(time).format(options.tooltip_format) +
            "\">" +
            moment(time).format(options.format) +
            "</span>"
        );
    };
}

const timeWithTooltip = datetimeTooltip();
const shortDatetime = datetimeTooltip("D MMM h:mm A");
const shortDatePlus = datetimeTooltip("D MMM");
const shortDate = datetimeTooltip("D MMM", { tooltip_format: "D MMMM YYYY" });
const simpleDate = datetimeTooltip({
    format: "D MMM YYYY",
    tooltip_format: "D MMMM YYYY"
});
const shortMonth = datetimeTooltip({
    format: "MMM YYYY",
    tooltip_format: "MMMM YYYY"
});
export { timeWithTooltip, shortDatetime, shortDatePlus, shortDate, simpleDate, shortMonth };
