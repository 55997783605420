import {Grid, GridColumn} from "kendo-grid-react-wrapper";
import * as _ from "lodash";
import * as React from "react";

interface IQuickGridConfig {
    columns?: any[];
    dataSource?: { data: any[] };
    editable: any;
    sortable?: boolean;
    toolbar?: any;
    filterable?: boolean;
    groupable?: boolean;
    height?: number;
}

export interface IQuickGridProps {
    content: any[];
    editTarget?: string;
    config?: IQuickGridConfig;
}

const GridDefaults = {
    dataSource: {
        batch: false,
    },
    sortable: true,
    filterable: true,
    groupable: false,
    height: 670,
};

const EditExtras = {
    editable: {mode: "incell"},
    toolbar: ["create", "save", "cancel"],
};

export default class QuickGrid extends React.Component<IQuickGridProps, never> {
    private config: IQuickGridConfig;

    constructor(props: IQuickGridProps) {
        super(props);
        const config = props.config || {};
        if (!config.columns) {
            config.columns = this.calcColumns(this.props.content);
            console.log("Calc columns", config.columns);
        }
        _.defaultsDeep(config, GridDefaults);
        if (props.editTarget) {
            _.defaultsDeep(config, EditExtras);
        }
        config.dataSource.schema = config.dataSource.schema || this.calcSchema(this.props.content);
        config.dataSource.transport = this.createTransport();
        console.log("Schema", config.dataSource, config.dataSource.schema);
        const dataSource = new kendo.data.DataSource(config.dataSource);
        config.dataSource = dataSource;
        console.log("datasource", dataSource);
        this.config = config;
    }

    // static defaultProps = {
    //     editTarget: 'users',
    // };

    private createTransport(): any {
        return {
            destroy: (options: any) => console.log("delete called", options),
            create: (options: any) => console.log("create called", options),
            read: this.read,
            update: this.update,
        };
    }

    private read = (readOptions: any) => {
        console.log("Reading", this.props);
        readOptions.success(this.props.content);
    };

    private update = (options: any) => {
        Promise.resolve($.ajax({
            url: "/gears_sql_features/update_row",
            type: "POST",
            data: {
                edit_target: this.props.editTarget,
                data: options.data,
            },
        })).then((result) => {
            console.log("updated", result);
            options.success(result);
        });
    };

    private calcColumns(content: any[]) {
        if (!content || content.length === 0) {
            return [];
        }
        const columns = _(content[0]).keys().map((field: string) => {
            let width;
            if (field == "id") {

                width = 80;
            }
            return {field, width, title: field.replace(/[\W_]+/g, " ").titleize()};
        }).value();
        return columns;
    }

    private calcFields(content: any[]) {
        if (!content || content.length === 0) {
            return [];
        }
        const columns = _(content[0]).keys().reduce((fields: any, field: string) => {
            fields[field] = {type: typeof field};
            return fields;
        }, {});
    }

    private calcSchema(content: any[]) {
        const fields = this.calcFields(content);
        return {
            model: {
                id: "id",
                fields,
            },
        };
    }

    public render() {
        const config = this.config;
        return <Grid {...config}/>;
    }
}
