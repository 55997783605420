/// <reference path="../../../typings/index.d.ts" />

function refreshVisibleKendoEditors() {
    // console.log("RefreshVisible Edited");
    _.each($('[data-role="editor"].k-raw-content'), (editor) => {
      if ($(editor).closest("table").is(":visible")) {
        $(editor).kendoEditor("refresh");
      }
    });
    // $('[data-role="delayededitor"]:not(.k-raw-content):visible').kendoEditor();
  // try {
  // }catch(e){
  //   console.log("Re-init editor", e);
  //   // $('[data-role="delayededitor"]').kendoEditor();
  //   // $('[data-role="delayededitor"]').kendoEditor('refresh');
  // }
}

global.refreshVisibleKendoEditors = refreshVisibleKendoEditors;

function setupValueSafeBinding() {
    const Binder = kendo.data.Binder;
    const binderInit = Binder.fn.init;
    const binderRefresh = Binder.fn.refresh;

    class ValueSafeBinder extends Binder {
        private widget: any;
        constructor(element: any, bindings: kendo.data.Bindings, options?: kendo.data.BinderOptions) {
            super(element, bindings, options);
            super.init(element, bindings, options);

            element.bind("change", () => {
                (bindings as any).valueSafe.set(element.value());
            });
        }
        // init(widget: any, bindings: kendo.data.Bindings, options: kendo.data.BinderOptions) {
        //     console.log("init safe called", this, bindings, options);
        //     //change: function() -> this.bindings.select2Url.set(this.widget.text())
        // }

        public refresh(attribute?: string): void {
            // console.log("Refresh safe called", this, attribute);
            // binderRefresh && binderRefresh.call(this, attribute);
            try {
                this.element.value((this.bindings as any).valueSafe.get());
                this.element.refresh();
            } catch (e) {}

        }
    }
    (kendo.data as any).binders.widget.valueSafe = ValueSafeBinder;
}

export function kendoEditorInsideTabFix() {
  // (<any>kendo).ui.Editor.prototype.value = _.wrap(kendo.ui.Editor.prototype.value, function(old: Function, value: any) {
  //   try{
  //     return old(value)
  //   } catch(e) {
  //       console.log("Kendo editor not yet initialized");
  //       return '';
  //   }
  // });
  // (<any>kendo).ui.Editor.prototype.exec = _.wrap(kendo.ui.Editor.prototype.exec, function(old: Function, value: any, value2: any) {try{return old(value, value2)} catch(e) { }});
  //console.log("Set up kendo editor initializers");

  setupValueSafeBinding();
  $(document).on("shown.bs.tab", refreshVisibleKendoEditors);
  $(document).on("shown.bs.modal", refreshVisibleKendoEditors);
}
