import { computed, observable, when, action, IObservableArray, reaction } from "mobx";
import cable, { ActionEvent, ICableAction } from "../actioncable";
import GearsActionSubscriber from "../../react-components/util/GearsActionSubscriber";
import * as _ from "lodash";
import { notification } from "../../gears/helpers/Notification";
import { INotificationOptions } from "../helpers/Notification";
import * as Promise from "bluebird";

export interface INotification {
    id: number;
    notification_type: string;
    status: string;
    created_by_id?: number;
    users?: { [key: string]: true };
    records: { [key: string]: true };
    details: INotificationOptions;
    action: object;
    read?: boolean;
    created_at: any;
    updated_at: any;
}

export default class NotificationState {
    public readonly notifications: IObservableArray<INotification> = observable.array();
    private subscription: ActionCable.Channel;
    @observable public active: boolean = false;

    public initialize(): void {
        this.subscribeToCable();
        this.readRecent();
        this.active = true;
    }

    public readRecent(): Promise<any> {
        return Promise.resolve($.ajax({
            url: "/notifications/gears_index.json",
            data: {limit: 10, mine: true, visible: true},
            dataType: "json",
            contentType: "application/json",
            type: "GET",
        })).then(({data}: {data: INotification[]}) => {
            this.notifications.spliceWithArray(this.notifications.length,0, data);
        });
    }

    public subscribeToCable(): void {
        this.subscription = cable.subscriptions.create({channel: "NotificationChannel"}, {
            connected: () => {
                // console.log("NC connected");
            },
            disconnected: () => {
                // console.log("NC disconnected");
            },
            received: action((update: ICableAction) => {
                const index = _.findIndex(this.notifications, ["id", update.object.id]);
                if (update.event === ActionEvent.Delete) {
                    this.notifications.splice(index, 1);
                    return;
                }

                let changed = true;

                if (index >= 0) {
                    // Support treating updates of missing objects as inserts
                    // We are updating
                    if (this.notifications[index].status === update.object.status) {
                        changed = false;
                    }
                    this.notifications[index] = update.object;
                } else {
                    // We are creating a new entry
                    this.notifications.unshift(update.object);
                }
                if (changed && !document.hidden) {
                    const details = update.object.details;
                    details.tag = "" + update.object.id;
                    // console.log("Notiii", changed, details.displayType, _.cloneDeep(update.object));
                    if (details.displayType && details.displayType !== "silent") {
                        notification(details);
                    }
                }
            }),
        });
    }

    @action
    public markAllAsRead(): void {
        if (!this.unread.length) {
            return;
        }
        const ids = _.map(this.unread, "id");
        this.subscription.perform("mark_read", {ids: ids});
    }

    @computed
    public get unread(): INotification[] {
        return _.filter(this.notifications, (n) => !n.read);
    }

    @computed
    public get unreadCount(): number {
        return this.unread.length;
    }
}
