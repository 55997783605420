import numberOperators from "./numberOperators";
import dateOperators from "./dateOperators";
import datetimeOperators from "./datetimeOperators";
import boolOperators from "./boolOperators";
import stringOperators from './stringOperators';
import objectOperators from './objectOperators';

const operatorMap = {
    number: numberOperators,
    boolean: boolOperators,
    string: stringOperators,
    rating: numberOperators,
    datetime: datetimeOperators,
    date: dateOperators,
    object: objectOperators,
};

export default operatorMap;
