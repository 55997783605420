import {computed, observable, when, action, IObservableArray, reaction} from "mobx";
import {ISidebarItem} from "../../react-components/SidebarItem";
import Sidebar, {IUserData} from "../../react-components/Sidebar";
import {GearsUser, GearsState} from "./";
import {collapseString} from "../helpers";

class MenuHeaderData implements IUserData {
    private gearsUser: GearsUser;

    constructor(gearsUser: GearsUser) {
        this.gearsUser = gearsUser;
    }

    @computed get user() {
        return this.gearsUser.name;
    }

    @computed get extra() {
        return this.gearsUser.companyName;
    }

    @computed get email() {
        return this.gearsUser.email_address;
    }

    @computed get background() {
        return this.gearsUser.backdrop;
    }

    @computed get avatar() {
        return this.gearsUser.avatar;
    }
}

export default class MenuState {
    @observable public menuItems: IObservableArray<ISidebarItem>;
    @observable public header: MenuHeaderData;
    @observable public footer: string;
    @observable public navHtml: string;

    constructor(gearsState: GearsState, footer: string) {
        this.footer = footer;
        this.header = new MenuHeaderData(gearsState.user);
        this.menuItems = observable([]);
        reaction(() => this.navHtml, (html) => {
           this.parseNavHtml();
        });
    }

    @action
    public parseNavHtml() {
        this.menuItems.replace(parseNavHtml(this.navHtml));
    }

    @computed
    public get loaded() {
        return this.menuItems.length > 0;
    }

}

export function parseNavHtml(html: string): ISidebarItem[] {
    if (!html) {
        if (global.Gears && global.Gears.Auto && global.Gears.Auto.Nav) {
            html = global.Gears.Auto.Nav;
            console.log("Using Auto Nav", html);
        }
    }

    if (!html) {
        return [];
    }

    const xml = $($.parseXML("<div>" + html + "</div>"));
    const json: ISidebarItem[] = [];
    xml.find(".main-nav > li").each(function() {
        const child: ISidebarItem = {
            title: $(this).find("> a").attr("title") || $(this).find("> a span").text(),
            icon: ($(this).find("> a i").attr("class") || "").replace(/(txt\S+)/gi, ""),
            colour: ($(this).find("> a i").attr("class") || "").match(/(txt\S+)/gi),
            href: $(this).find("> a").attr("href") || '',
            target: $(this).find("> a").attr("target"),
            children: [],
            open: $(this).attr("class") === "open",
            active: $(this).attr("class") === "active",
        };
        $(this).find("ul > li").each(function() {
            child.children.push({
                title: collapseString($(this).find("> a").text()),
                icon: $(this).find("> a i").attr("class"),
                colour: ($(this).find("> a i").attr("class") || "").match(/(txt\S+)/gi),
                href: $(this).find("> a").attr("href"),
                target: $(this).find("> a").attr("target"),
                open: $(this).attr("class") === "active",
            } as ISidebarItem);
        });
        json.push(child);
    });
    return json;
}
