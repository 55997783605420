global.logout = () => {
    if ("credentials" in navigator) {
        if ("preventSilentAccess" in navigator.credentials) {
            navigator.credentials.preventSilentAccess();
        } else {
            navigator.credentials.requireUserMediation();
        }
    }
    location.href = "/logout";
};
