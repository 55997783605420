import * as $ from "jquery";
import * as _ from "lodash";

let currentPopover: JQuery;

function createGearsPopover() {
    const $elem = $(this);
    const data = $elem.data();
    data.container = data.container || $elem;
    return $elem.popover(data);
}

function gridGearsPopover() {
    let $elem, data;
    $elem = $(this);
    data = $elem.data();
    data.container = "body";
    return $elem.popover(data);
}

export function gearsPopovers() {
    return $(".modal [data-toggle=popover]").each(createGearsPopover);
}

export function setupGearsPopovers() {
    _.delay(gearsPopovers, 1);
    return $("body").on("show.bs.popover", function() {
        if (currentPopover) {
            currentPopover.find(".popover").hide();
        }
        return currentPopover = $(this);
    });
}
