import {observable, ObservableMap} from "mobx";
type Href = string;

export interface ILookup {
    id: number;
    identifier: string;
    text: string;
    value: number;
    icon?: string;
    short_text?: string;
    initials?: string;
    initials_class?: string;
    image?: Href;
    message?: string;
}

export interface ILookupsMap {
    [key: string]: ILookup[];
}

interface ILookupItemMap {
    [key: string]: ILookup;
    [key: number]: ILookup;
}

declare const global: {Gears: {lookups: any}};

export default class Lookups {
    public lists: ObservableMap<ILookup[]> = observable.map<ILookup[]>(null, {deep: false});

    constructor(raw?: ILookupsMap) {
        if (raw) {
            this.setLookups(raw);
        }
    }

    public resetLookups(raw?: ILookupsMap) {
        this.lists.clear();
        if (raw) {
            this.setLookups(raw);
        }
    }

    public setLookups(raw: ILookupsMap) {
        if (!global.Gears) {global.Gears = {lookups: {}}; }
        const lookups = global.Gears.lookups || (global.Gears.lookups = {});
        for (const lookup in raw) {
            this.setLookup(lookup, raw[lookup]);
        }
    }

    public setLookup(name: string, values: ILookup[]) {
        if (_.isArray(values)) {
            for (const item of values) {
                item.value = item.id;
            }
        }
        global.Gears.lookups[name] = values;
        this.lists.set(name, values);
    }
}
