import { pluralize } from "inflection";
import { ConfigurationError } from "./Errors";
import GridConfig from "./GridConfig";
import { ChildGridController, GridController } from "./GridController";
import * as Promise from "bluebird";

declare const Gears: { Grids: any };

function firstLetterLower(s: string): string {
    return s[0].toLowerCase() + s.slice(1);
}

function instanceName(s: string): string {
    return firstLetterLower(pluralize(s));
}

interface Grid {
    initialize(): void;
}

class ViewConfig {
    public grid_name: string;
    public children: ViewConfig[];
}

export default class GridView {
    public readonly viewConfig: ViewConfig;
    public rootConfig: GridConfig;
    public rootGrid: Grid;
    public childConfigs: GridConfig[] = [];
    public grids: Grid[] = [];
    [key: string]: any;

    constructor(viewConfig: ViewConfig, parentView: GridView) {
        this.viewConfig = viewConfig;
        const pluralName = pluralize(viewConfig.grid_name);
        const configObject = Gears.Grids[pluralName];
        if (!configObject) {
            throw new ConfigurationError(`Error setting up ${pluralName}, parent grid config not found`);
        }
        this.rootConfig = new GridConfig(configObject);
        this.addChildGridConfigs(this.rootConfig, viewConfig.children);
    }

    get pluralName(): string {
        return pluralize(this.viewConfig.grid_name);
    }

    public addChildGridConfigs(parent: GridConfig, children: ViewConfig[]) {
        if (!children) { return; }
        for (const child of children) {
            const pluralName = pluralize(child.grid_name);
            const configObject = Gears.Grids[pluralName];
            if (!configObject) {
                throw new ConfigurationError(`Error setting up ${this.pluralName}, child grid config for '${pluralize(child.grid_name)}' not found`);
            }
            const childConf = new GridConfig(configObject, parent);
            this
                .childConfigs
                .push(childConf);
            if (child.children.length) {
                this.addChildGridConfigs(childConf, child.children);
            }
        }
    }

    public initialize(): void {
        this.createGrids();
        this.initializeGrids();
    }

    public createGrids() {
        this.rootGrid = new GridController(this.rootConfig);
        this
            .grids
            .push(this.rootGrid);
        this[instanceName(this.viewConfig.grid_name) + "Grid"] = this.rootGrid;
        for (const childConf of this.childConfigs) {
            const childGrid = new ChildGridController(childConf, childConf.parentGrid());
            this
                .grids
                .push(childGrid);
            this[instanceName(childConf.modelName()) + "Grid"] = childGrid;
        }
    }

    public initializeGrids(): void {
        const failures = [] as any[];
        Promise.mapSeries(this.grids, function initailizeGrid(grid): Promise<any> {
            try {
                grid.initialize();
            } catch (error) {
                console.log("Error initializing grid: ", grid, error);
                failures.push({grid,error});
                // throw e;
            }
            return Promise.delay(1);
        }).finally(() => {
            if (failures.length) {
                console.error("Some Grids failed", failures);
                throw failures;
            }
        });
    }
}
