import * as jsBeautify from "js-beautify";
import * as vkbeautify from "vkbeautify";
import * as json5 from "json5";
(global as any).vkb = vkbeautify;
(global as any).jsBeautify = jsBeautify;

export function beautifySql(code: string, options?: JsBeautifyOptions): string {
    return vkbeautify.sql(code || "", (options && options.indent_size) ? options.indent_size : 4);
}

const jsOptions: JsBeautifyOptions = {
    indent_size: 4,
    max_preserve_newlines: 2,
    wrap_line_length: 150,
};

export function beautifyJs(code: string, options?: JsBeautifyOptions): string {
    return jsBeautify.js(code || "", options ? _.defaults({}, options, jsOptions) : jsOptions);
}

const htmlOptions: JsBeautifyOptions = {
    brace_style: "expand",
    indent_size: 4,
    e4x: true,
    max_preserve_newlines: 2,
    preserve_newlines: true,
    wrap_attributes: "force-aligned",
};

export function beautifyHtml(code: string, options?: JsBeautifyOptions): string {
    return jsBeautify.html(code || "", options ? _.defaults({}, options, htmlOptions) : htmlOptions);
}

const cssOptions: JsBeautifyOptions = {
    indent_size: 4,
    max_preserve_newlines: 2,
    wrap_line_length: 120,
    selector_separator_newline: false,
};

export function beautifyCss(code: string, options?: JsBeautifyOptions) : string{
    return jsBeautify.css(code || "", options ? _.defaults({}, options, cssOptions) : cssOptions);
}

export function beautifyJson5(code: string, options?: JsBeautifyOptions): string {
    const obj = typeof code === "string" ? json5.parse(code) : code || {};
    return json5.stringify(obj, null, '  ');
}

export type BeautifyFormat = "sql" | "xml" | "dryml" | "rhtml" | "html" | "css" | "scss" | "js" | "javascript" | "tsx" | "typescript" | "ts" | "json" | "json5";

const beautifyFormats = new Set<string>(["sql", "xml", "dryml", "rhtml", "html", "css", "scss", "js", "javascript", "tsx", "typescript", "ts", "json", "json5"]);

export function isBeautifyFormat(format?: string): format is BeautifyFormat {
    return beautifyFormats.has(format);
}

export function beautify(code: string, language?: string, options?: JsBeautifyOptions): string {
    language = language ? language.toLowerCase() : "js";
    switch (language) {
        case "json":
        case "json5":
            return beautifyJson5(code, options);
        case "sql":
            return beautifySql(code, options);
        case "xml":
        case "dryml":
        case "rhtml":
        case "html":
            return beautifyHtml(code, options);
        case "css":
        case "scss":
            return beautifyCss(code, options);
        default:
            return beautifyJs(code, options);
            break;
    }
}
