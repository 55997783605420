export default function addGridKeyboard(gearsGrid: any) {
    let keyboardSelect: Function;
    gearsGrid.keypress = function(e: Event) {
        let anyModalIsOpen, keyCode, previous, next;
        anyModalIsOpen = !!$(".widget-modal.in").length;
        keyCode = e.which || e.keyCode;
        switch (false) {
            case !(keyCode === 83 && e.ctrlKey):
                if (!gearsGrid.modalIsOpen()) {
                    return;
                }
                $(document.activeElement).blur();
                e.preventDefault();
                gearsGrid.saveModal();
                break;
            case keyCode !== 27:
                console.log("Escape pressed");
                if (!gearsGrid.modalIsOpen()) {
                    return;
                }
                $(document.activeElement).blur();
                _.defer(function() {
                    return gearsGrid.cancelModal();
                });
        }
        if (!gearsGrid.keyboard_selected) {
            return;
        }
        switch (false) {
            case keyCode !== 38:
                if (anyModalIsOpen) {
                    return;
                }
                e.preventDefault();
                previous = gearsGrid.gearsGrid.select().prev();
                if (previous) {
                    return gearsGrid.gearsGrid.select(previous);
                }
                break;
            case keyCode !== 40:
                if (anyModalIsOpen) {
                    return;
                }
                e.preventDefault();
                next = gearsGrid.gearsGrid.select().next();
                if (next) {
                    return gearsGrid.gearsGrid.select(next);
                }
                break;
            case keyCode !== 37:
                if (anyModalIsOpen) {
                    return;
                }
                e.preventDefault();
                return gearsGrid.keyboardSelectLeft();
            case keyCode !== 39:
                if (anyModalIsOpen) {
                    return;
                }
                e.preventDefault();
                return gearsGrid.keyboardSelectRight();
        }
    };
    keyboardSelect = function(diff) {
        let index, newg;
        index = _.findIndex(Gears.all_grids, gearsGrid) || 0;
        index += diff + Gears.all_grids.length;
        index %= Gears.all_grids.length;
        newg = Gears.all_grids[index];
        return _.defer(function() {
            gearsGrid.set("keyboard_selected", false);
            newg.set("keyboard_selected", true);
            if (!newg.isVisible()) {
                return newg.keyboardSelect(diff);
            }
        });
    };
    gearsGrid.bind("change", function(it) {
        let selected;
        if (it.field === "keyboard_selected") {
            selected = gearsGrid.keyboard_selected;
            gearsGrid.gearsGrid.element.removeClass("gears-animation-done");
            gearsGrid.gearsGrid.element.toggleClass("gears-grid-selected", selected);
            if (selected) {
                return _.delay(function() {
                    return gearsGrid.gearsGrid.element.addClass("gears-animation-done");
                }, 3000);
            }
        }
    });
    gearsGrid.keyboardSelect = keyboardSelect;
    gearsGrid.keyboardSelectRight = function() {
        return keyboardSelect(1);
    };
    gearsGrid.keyboardSelectLeft = function() {
        return keyboardSelect(-1);
    };
    $(document).keydown(function(it) {
        return gearsGrid.keypress(it);
    });
    return gearsGrid.set("keyboard_selected", !gearsGrid.parentGrid);
}
