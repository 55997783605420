import * as _ from "lodash";
import { extendObservable } from "mobx";
import { ConfigurationError } from "./Errors";
import gearsState from "./GearsState";
import {MultiSelect} from "./GridMultiSelect";

declare var Gears: any;
let observableObject;
let kendoModel: typeof kendo.data.Model;
let kendoModelInit: () => any;
let observableObjectInit;

if (typeof (kendo) === "undefined") {
    observableObject = {
        extend(it: any): any {
            return it;
        },
    };
    kendoModel = {
        define(it: any): any {
            return it;
        },
        extend(it: any): any {
            return it;
        },
    } as any;
} else {
    kendoModel = kendo.data.Model;
    kendoModelInit = kendoModel.fn.init;
    observableObject = kendo.data.ObservableObject;
    observableObjectInit = observableObject.fn.init;
}

function parseGearsBoolean(it: string | boolean | null): boolean | null {
    switch (it) {
        case "false":
            return false;
        case "true":
            return true;
        case "":
            return null;
        default:
            return !!it;
    }
}

// kendoModel.parseGearsBoolean = parseGearsBoolean;

const dateType = {
    type: "date",
    parse(it: string): Date | string {
        let e;
        try {
            return kendo.parseDate(it);
        } catch (e$) {
            e = e$;
            return it;
        }
    },
    defaultValue: null,
};

const numberType = {
    parse: typeof kendo !== "undefined" && kendo !== null ? kendo.parseFloat : void 8,
    defaultValue: null,
};

const stringType = {
    parse(value: string | number | null | undefined): string | null {
        if (value != null) {
            return value + "";
        } else {
            return null;
        }
    },
    defaultValue: "",
};

const arrayType = {
    parse(it: any): any[] {
        if (typeof it === "object") {
            return it;
        } else {
            return [];
        }
    },
    defaultValue(): any[] {
        return [];
    },
};

const fieldTypes = {
    number: numberType,
    rating: numberType,
    date: dateType,
    date_month: dateType,
    date_year: dateType,
    datetime: dateType,
    boolean: {
        parse: parseGearsBoolean,
        defaultValue: null,
    },
    string: stringType,
    tags_string: stringType,
    interval: stringType,
    tags: arrayType,
    default: {
        parse(it: any): any {
            return it;
        },
        defaultValue: null,
    },
    object: {
        parse(it: any): object {
            if (typeof it === "object") {
                return it;
            } else {
                return {};
            }
        },
        defaultValue(): object {
            return {};
        },
        validation(it: any): boolean {
            console.log("validation called!", it);
            return true;
        },
    },
};

(kendoModel as any).fieldTypes = fieldTypes;

/**
 * TODO: Properly name these variables
 */
function setupGearsDefaults(model): void {
    if (!model.fields) {
        model.fields = {};
    }
    const {fields} = model;

    if (fields.webfront_relations == null) {
        fields.webfront_relations = {defaultValue: {}};
    }

    model._all_fields = _.clone(model.fields);
    model.nested_fields == null && (model.nested_fields = {});
    _.each(model.fields, function setupFieldDefault(f, g: any): void {
        const type = f.type || "default";
        const conf = (fieldTypes as any)[type];
        if (!conf) {
            throw new ConfigurationError(`${f.type} is not a valid Kendo field type. Model: ${model.gears_model_name}`);
        }
        _.defaults(f, conf);
        if (conf.type) {
            f.type = conf.type; // Force to kendo type
        }
        const when_null = f.when_null;
        if (g.indexOf(".") > 0) {
            model.nested_fields[g] = f;
            delete model.fields[g];
        }
        const {defaultValue, parse} = f;

        if (f.gearsInit == null) {
            f.gearsInit = function autoFieldInit(): void {
                const oldValue = _.get(this, g) as any;
                let value = oldValue;
                if (value == null) {
                    if (when_null) {
                        value = when_null;
                    } else if (defaultValue && this.isNew()) {
                        value = defaultValue;
                    }
                    if (typeof value === "function") {
                        value = value.call(this);
                    }
                }
                if (parse) {
                    value = parse(value);
                }
                if (oldValue !== value) {
                    try {
                        this.set(g, value);
                    } catch (e) {
                        console.log(`Error setting '${g}' (missing parent object field?)`);
                        _.set(this, g, value);
                    }
                }
            };
        }
    });
}

function validatorCollect(result: any, field: {validation?: any}, fieldName: string): any {
    result[fieldName] = field.validation ? field.validation : result[fieldName];
    return result;
}

function initCollect(result: any, field: {gearsInit?: any}, fieldName: string): any {
    result[fieldName] = field.gearsInit ? field.gearsInit : result[fieldName];
    return result;
}

const emptyLookup = {
    id: "&nbsp;&nbsp;&nbsp;&nbsp;",
    icon: "icon-blank",
};

function valueFindFunction(lookupName: string, field: string, lookupId = "id"): any {
    return function() {
        const id = this.get(field);
        return _.find(gearsState.lookups.lists.get(lookupName), (it: any) => {
            return id === it[lookupId];
        });
    };
}

function lookupTemplateFunction(propName: string, options) {
    return function() {
        return Gears.formatLookup(this[propName] || emptyLookup, options);
    };
}

function setupCustomProperties(model: any, options: any): void {
    const customProperties = _.pickBy(options, (o) => o && o.get || o.set);
    model.prototype._customProperties = customProperties;
    Object.defineProperties(model.prototype, customProperties);
}

function setupLookupProperties(model: any): void {
    const allFields = model.prototype._all_fields;
    _.each(allFields, (config: any,field: string) => {
        const {lookup} = config;
        if (lookup) {
            const fl = field + "_lookup";
            const options = lookup.options || {};
            switch (lookup.type) {
                case "inline":
                    const dataPath = lookup.data_path || "webfront_relations." + lookup.name;
                    Object.defineProperty(model.prototype, fl, {
                        get: function getPath(): any {return this.get(dataPath);},
                        set: function setPath(val: any): any {return this.set(dataPath, val);},
                    });
                    break;
                case "values":
                    const getter = valueFindFunction(lookup.values, field, config.lookup.id);
                    Object.defineProperty(model.prototype, fl, {
                        get: getter,
                    });
            }
            const valgetter = lookupTemplateFunction(fl, options);
            Object.defineProperty(model.prototype, field + "_template", {
                get: valgetter,
            });
        }
    });
}

function setupGearsInitializers(model: any): void {
    const inits = model._gears_initializers || (model._gears_initializers = {});
    if (model.gearsInit != null) {
        inits.gearsInit = model.gearsInit;
    }
    _.reduce(model.fields, initCollect, inits);
    _.reduce(model.nested_fields, initCollect, inits);
    if (_.some(model.fields, "onChange")) {
        inits._onChange = function initOnChange() {
            this.bind("change", this._onFieldChanged);
        };
    }
}

function setupGearsValidators(model) {
    let validators;
    validators = model._validators != null
        ? model._validators
        : model._validators = {};
    if (model.modelValidator != null) {
        validators.modelValidator = model.modelValidator;
    }
    _(model.fields).reduce(validatorCollect, validators);
    _(model.nested_fields).reduce(validatorCollect, validators);
}

export class Model extends kendo.data.Model {
    public static gears_model_name: string;
    [key: string]: any;
    public _multiSelect?: MultiSelect;

    // public [key: string]: any;
    private _gears_initializers: { [key: string]: () => any };

    constructor(data: any) {
        super(data);
        this.init(data);
    }

    public static define(base: typeof kendo.data.Model | IGearsModelConfig, options?: IGearsModelConfig): typeof Model {
        if (options == null) {
            options = base;
            base = Model;
        }
        options = _.cloneDeep(options);
        setupGearsDefaults(options);
        setupGearsInitializers(options);
        setupGearsValidators(options);
        const newModel = kendoModel.define.call(this, base, options);
        setupCustomProperties(newModel, options);
        setupLookupProperties(newModel || this);
        newModel.validationConfig = function() {
            let vc;
            vc = {
                rules: {},
                messages: {},
            };
            _.forIn(newModel.prototype._all_fields, function(fconf, field) {
                if (!fconf.validation) {
                    return;
                }
                vc.rules[field] = function(input) {
                    let fn;
                    fn = $(input).attr("data-field-name");
                    if (fn === field) {
                        return fconf.validation(input);
                    } else {
                        return true;
                    }
                };
                if (fconf.validation_message) {
                    return vc.messages[field] = fconf.validation_message;
                } else {
                    return vc.messages[field] = "Invalid Input";
                }
            });
            return vc;
        };
        return newModel;
    }

    public init(data: any) {
        // extendObservable(this, this._customProperties);
        kendoModelInit.call(this, data);
        if (this._gears_initializers) {
            _.each(this._gears_initializers, (it) => it.call(this));
        }
        this.set("_rollthrough", {});
        this.dirty = false;
    }

    public enableRecord(): boolean {
        return true;
    }

    public disableRecord() {
        return !this.enableRecord();
    }

    public enableField(fieldName: string, _default: any) {
        return this._enableField(fieldName, _default);
    }

    public showField(fieldName: string, _default: any) {
        return this._showField(fieldName, _default);
    }

    public accept(data: any) {
        let parent, field, value, this$ = this;
        parent = function() {
            return this$;
        };
        for (field in data) {
            value = data[field];
            if (field.charAt(0) !== "_") {
                value = this.wrap(value, field, parent);
            }
        }
        this._set(field, this._parse(field, value));
        if (this.idField) {
            this.id = this.get(this.idField);
        }
        return this.dirty = false;
    }

    public setAll(obj: any) {
        let key, value, own$ = {}.hasOwnProperty, results$ = [];
        for (key in obj) {
            if (own$.call(obj, key)) {
                {
                    value = obj[key];
                    results$.push(this.set(key, value));
                }
            }
        }
        return results$;
    }

    public fetchLookup(field: string, lookup_name: string) {
        const id = this.get(field);
        return _.find(gearsState.lookups.lists.get(lookup_name), function(it) {
            return id === it.id;
        });
    }

    public displayName() {
        return "#" + this.get("id");
    }

    public reportId() {
        return this.get("id");
    }

    public reportDisplayName() {
        return this.displayName();
    }

    public get gearsSelected(): boolean {
        return !!(this._multiSelect && this._multiSelect.selectedIds.has(this.id));
    }

    public gearsSelectedTemplate(): string {
        const selected = this.gearsSelected;
        const checked = selected ? "checked" : "";
        const rowclass = selected ? "gears-row-class gears-multi-selected" : "";
        return `<div class='checkbox ${rowclass}"'><label><input class='checkbox multi-select style-0' type='checkbox' ${checked}/><span></span></label></div>`;
    }

    protected _showField(fieldName: string, _default: any): boolean {
        let func;
        _default == null && (_default = true);
        func = this["show" + fieldName];
        if (func == null) {
            return _default;
        }
        this.get("");
        return func.call(this);
    }

    protected _enableField(fieldName: string, _default: any): boolean {
        let func;
        _default == null && (_default = true);
        func = this["enable" + fieldName];
        if (func == null) {
            return _default;
        }
        this.get("");
        return func.call(this);
    }

    protected _onFieldChanged(e: {field: string}): void {
        const field = this._all_fields[e.field];
        const onChange = field && field.onChange;
        if (onChange) {
            onChange.call(this, e);
        }
    }
}
