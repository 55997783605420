import * as Promise from "bluebird";
import {beautify, beautifyHtml, beautifyJs, beautifySql} from "../helpers/beautify";
import * as binding_gears_object from "./kendo-binding-gears-object";
import {simpleWidgetBinder} from "../helpers/simpleWidgetBinder";
import vendor from "../../modules/vendor";

function kendoAce() {
    binding_gears_object.kendoBindingGearsObject();
    const kendo = (global as any).kendo;
    const ui = kendo.ui;
    const Widget = ui.Widget;

    let allAces: any[] | null = null;
    function addAce(it: AceAjax.Editor) {
        if (!allAces) {
            allAces = [];
            $("body").on("shown.bs.modal", () => { _.invoke(allAces, "resize"); });
        }
        return allAces.push(it);
    }

    const KendoAce = Widget.extend({
        init(element: any, options: any) {
            Widget.fn.init.call(this, element, options);
            if(this.options.enabled !== undefined) {
                this.options.readOnly = !this.options.enabled;
            }
            switch (this.options.mode) {
                case "xml":
                case "html":
                case "html_ruby":
                    this.beautify = beautifyHtml;
                    this.options.autoCompletion = false;
                    break;
                case "ruby":
                    this.options.autoCompletion = false;
                    break;
                case "livescript":
                    this.options.autoCompletion = false;
                    break;
                case "javascript":
                    this.beautify = null;
                    break;
                case "typescript":
                case "jsx":
                case "tsx":
                    this.options.mode = "tsx";
                    this.beautify = beautifyJs;
                    break;
                case "json":
                case "json5":
                case "object":
                    this.options.mode = "hjson";
                    break;
                case "pgsql":
                    this.beautify =  this.options.beautify ? beautifySql : null ;
                    break;
                default:
                    this.beautify = null;
            }
            vendor.ace
                .then((ace: any) => {
                    const editor = ace.edit(this.element[0]);
                    this.aceEditor = editor;
                    const session = editor.getSession();
                    session.setMode("ace/mode/" + this.options.mode);
                    session.setTabSize(this.options.tabSize);
                    session.setUseSoftTabs(this.options.useSoftTabs);
                    editor.setTheme("ace/theme/tomorrow");
                    editor.on("blur", () => this.trigger("change"));
                    editor.blockScrolling = Infinity;
                    editor.setOptions({
                        enableBasicAutocompletion: true,
                        // enableSnippets: true,
                        enableLiveAutocompletion: this.options.autoCompletion,
                        highlightActiveLine: true,
                    });
                    addAce(this.aceEditor);
                });
        },
        options: {
            name: "Ace",
            expanded: true,
            beautify: true,
            mode: "livescript",
            tabSize: 2,
            useSoftTabs: true,
            autoCompletion: true,
            readOnly: false,
        },
        refresh() {
            _.defer(() => {
                const elValue = this.element.value();
                return this.value(elValue != null ? elValue : "");
            });
        },
        readOnly(value?: boolean) {
            if (!this.aceEditor) {
                return false;
            }
            if (value == undefined) {
                return this.aceEditor.getReadOnly();
            } else {
                return this.aceEditor.setReadOnly(!!value);
            }
        },
        enabled(value?: boolean) {
            // console.log("Set enabled", value);
            if (!this.aceEditor) {
                return false;
            }
            if (value === undefined) {
                return !this.aceEditor.getReadOnly();
            } else {
                return this.aceEditor.setReadOnly(!value);
            }
        },
        beautify: null,
        value(value?: string) {
            switch (false) {
                case value !== undefined:
                    return this.aceEditor.getValue().replace(/\t/, "  ");
                default:
                    if (value === null) {
                        value = "";
                    }
                    if (this.beautify) {
                        value = this.beautify(value);
                    }
                    try {
                        return this.aceEditor.setValue(value);
                    } catch (e) {
                        // return console.log("Could not set value");
                        return;
                    }
            }
        },
    });
    return ui.plugin(KendoAce);
}

kendoAce();
