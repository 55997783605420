import * as React from "react";
import operatorMap from "./operatorMap";
import stringOperators from "./stringOperators";
import * as Class from "classnames";
import * as styles from "./FilterBuilder.scss";
import { bind } from "decko";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import StarRating from "commonui/StarRating";
import DatePicker from "commonui/DatePicker";
import Select from "commonui/Select";
import Input from "commonui/Input";
import Button from "commonui/Button";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import * as moment from "moment";

function formatIcon(item) {
    if (item.icon) {
        return (
            <span>
                <b>{item.icon} </b>
                {item.label}
            </span>
        );
    } else {
        return item.label;
    }
}

function formatFontAwesome(item) {
    if (item.icon) {
        return (
            <span>
                <FontAwesomeIcon fixedWidth icon={item.icon} style={{ color: item.colour }} />
                &nbsp;{item.label}
            </span>
        );
    } else {
        return item.label;
    }
}

function renderLookupItem(item) {
    let realIcon: string = item.icon;
    let colourClass = "";
    let hasSpin = false;
    if (realIcon) {
        hasSpin = realIcon.includes("fa-spin");
        realIcon = realIcon.replace("fa-spin", "");
        realIcon = realIcon.replace("fa-fw", "");
        realIcon = realIcon.match(/fa-([a-z-0-9]+)/);
        if (realIcon) {
            realIcon = realIcon[1];
        } else {
            realIcon = ""
        }
        colourClass = item.icon.match(/(txt-[a-z-0-9]+)/g);
        if (colourClass) {
            colourClass = colourClass.join(" ");
        } else {
            colourClass = "";
        }
    }
    return (
        <span>
            {realIcon &&
                <FontAwesomeIcon spin={hasSpin} fixedWidth className={colourClass} icon={realIcon} />}
            &nbsp;{item.label}
        </span>
    )
}

const bools = [
    { value: "false", label: "False", icon: "times", colour: "#f44336" },
    { value: "true", label: "True", icon: "check", colour: "#4CAF50" },
];

interface FilterRowProps {
    filterRow: any;
    filterRowIndex: number;
    parentFilter: any;
    data: any;
    isFirst: boolean;
    logic: "and" | "or";
    onToggleLogic: () => void;
    disableDelete?: boolean;
}

@observer
export default class FilterRow extends React.Component<FilterRowProps, never> {
    @observable private relativeDate: boolean = false;

    constructor(props: FilterRowProps) {
        super(props);

        this.validateDate(props);
    }

    @bind
    @action
    private onChangeField(value) {
        // If new type does not match, reset everything
        let oldField = _.find(this.props.data, { value: this.props.filterRow.field });
        let newField = _.find(this.props.data, { value: value.value });
        if (oldField && oldField.type !== newField.type) {
            this.props.filterRow.operator = "";
            this.props.filterRow.value = "";
        }
        this.props.filterRow.field = value.value;
        this.validateDate(this.props);
    }

    /**
     * Tries to detect if we should use a relative or absolute date format
     */
    private validateDate(props: FilterRowProps) {
        let operatorFind = _.find(props.data, { "value": props.filterRow.field });
        let type = "string";
        if (operatorFind) {
            type = operatorFind.type;
        }
        if (type === "date" || type === "datetime") {
            // Assume if first character is a letter it's relative
            if (props.filterRow.value) {
                if (props.filterRow.value.match(/^[a-z]/i)) {
                    this.relativeDate = true;
                } else if (!moment(props.filterRow.value).isValid()) {
                    this.relativeDate = true;
                } else {
                    this.relativeDate = false;
                }
            } else {
                this.relativeDate = false;
            }
        }
    }

    @action
    public componentWillReceiveProps(nextProps: FilterRowProps) {
        this.validateDate(nextProps);
    }

    @bind
    @action
    private onChangeOperator(value) {
        this.props.filterRow.operator = value.value;
    }

    @bind
    @action
    private destroyFilter() {
        this.props.parentFilter.splice(this.props.filterRowIndex, 1);
    }

    @bind
    @action
    private duplicateFilter() {
        // If we copy the index from this.props.filterRow we get bad errors, we only use the fields we need
        this.props.parentFilter.splice(this.props.filterRowIndex, 0, { field: this.props.filterRow.field, operator: this.props.filterRow.operator, value: this.props.filterRow.value });
    }

    @bind
    @action
    private onToggleRelativeDate() {
        this.props.filterRow.value = "";
        this.relativeDate = !this.relativeDate;
    }


    @bind
    @action
    private onChangeValueFromDatePicker(value) {
        if (value) {
            this.props.filterRow.value = value.toISOString();
        }
    }

    @bind
    @action
    private onChangeValueFromDatePickerDateOnly(value) {
        if (value) {
            this.props.filterRow.value = value.format("YYYY-MM-DD");
        }
    }

    @bind
    @action
    private onChangeValueFromRelativeDate(e) {
        this.props.filterRow.value = e.currentTarget.value;
    }

    @bind
    @action
    private onChangeValueFromSelect(value) {
        this.props.filterRow.value = value.value;
    }

    public render() {
        const { filterRow } = this.props;

        let operatorSet = [];
        let operatorFind = _.find(this.props.data, { "value": filterRow.field });
        let type = "string";
        if (operatorFind) {
            operatorSet = operatorMap[operatorFind.type] || stringOperators;
            type = operatorFind.type;
        }
        let input;
        switch (type) {
            case "number":
                input = (
                    <Input
                        observable={filterRow}
                        value="value"
                        disabled={operatorSet.length <= 0} />
                );
                break;
            case "object":
                input = (
                    <Select
                        options={operatorFind.data}
                        value={filterRow.value}
                        optionRenderer={renderLookupItem}
                        valueRenderer={renderLookupItem}
                        onChange={this.onChangeValueFromSelect}
                        clearable={false}
                    />
                );
                break;
            case "rating":
                input = (
                    <StarRating
                        rating={filterRow.value || 0}
                        editable={operatorSet.length > 0}
                        onChange={this.onChangeValueFromSelect} />
                );
                break;
            case "date":
            case "datetime":
                input = (
                    <div className={styles["datepicker-container"]}>
                        <button onClick={this.onToggleRelativeDate}>
                            {this.relativeDate ? "Relative" : "Absolute"}
                        </button>
                        {this.relativeDate
                            ?
                            <input
                                value={filterRow.value || ""}
                                disabled={operatorSet.length <= 0}
                                onChange={this.onChangeValueFromRelativeDate}
                            />
                            :
                            <DatePicker
                                editFormat={type === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ssA"}
                                displayFormat={type === "date" ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ssA"}
                                value={filterRow.value || ""}
                                disabled={operatorSet.length <= 0}
                                onChange={type === "date" ? this.onChangeValueFromDatePickerDateOnly : this.onChangeValueFromDatePicker} />}
                    </div>
                );
                break;
            case "boolean":
                input = (
                    <Select
                        options={bools}
                        optionRenderer={formatFontAwesome}
                        valueRenderer={formatFontAwesome}
                        value={filterRow.value}
                        onChange={this.onChangeValueFromSelect}
                        clearable={false}
                    />
                );
                break;
            case "string":
            default:
                const operator = _.find(operatorSet, { value: filterRow.operator });
                input = (
                    <Input
                        observable={filterRow}
                        value="value"
                        disabled={operatorSet.length <= 0 || (operator && operator.disableInput)} />
                );
                break;
        }
        return (
            <div
                className={Class(styles["filter-inputs"], { [styles["is-first"]]: this.props.isFirst })}>
                <div
                    className={styles["operator-container"]}>
                    <Select
                        options={this.props.data}
                        value={filterRow.field}
                        onChange={this.onChangeField}
                        clearable={false}
                    />
                    <Select
                        options={operatorSet}
                        optionRenderer={formatIcon}
                        valueRenderer={formatIcon}
                        disabled={operatorSet.length <= 0}
                        value={filterRow.operator}
                        onChange={this.onChangeOperator}
                        clearable={false}
                    />
                    <div>
                        {input}
                    </div>
                </div>
                <Button
                    icon="fa-clone"
                    flat
                    coloured
                    className={Class(styles.duplicate, "blue")}
                    aria-label="Duplicate"
                    onClick={this.duplicateFilter} />
                <Button
                    icon="fa-times"
                    className={Class(styles.delete, "red")}
                    flat
                    coloured
                    disabled={this.props.disableDelete}
                    aria-label="Delete Rule"
                    onClick={!this.props.disableDelete ? this.destroyFilter : undefined} />
                {!this.props.isFirst &&
                    <button
                        onClick={this.props.onToggleLogic}
                        className={styles["inline-logic"]}>
                        {this.props.logic}
                    </button>
                }
            </div>
        );
    }
}
