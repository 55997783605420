import { default as cable, ICableAction, ActionEvent} from "../actioncable"; // Use a singleton action cable to reduce server load.
import { action as mobxAction, observable, reaction } from "mobx";
import { InvalidActionEventError } from "../Errors";
import { default as GridConfig, ISubscriptionOptions } from "../GridConfig";
import {GridController} from "../GridController";
import {underscore} from "inflection";
import * as _ from "lodash";

export default class GridActionSubscriber {
    private subscription: ActionCable.Channel;
    private subscriptionConfig: ISubscriptionOptions;
    private idField: string;
    private readonly grid: GridController;
    private readonly gridConfig: GridConfig;
    private readonly dataSource: kendo.data.DataSource;
    @observable public hasChanges: boolean = false;

    constructor(grid: GridController, gridConfig?: GridConfig, dataSource?: kendo.data.DataSource, subscriptionConfig?: ISubscriptionOptions, idField?: string) {
        this.grid = grid;
        this.gridConfig = gridConfig = (gridConfig || grid.config);
        this.dataSource = dataSource = (dataSource || grid.dataSource);
        this.subscriptionConfig = subscriptionConfig = (subscriptionConfig || gridConfig.subscription);
        this.idField = idField = (idField || gridConfig.gearsModel.idField);
        if (subscriptionConfig.actions) {
            this.subscription = this.createSubscription();
        }
    }

    private createSubscription(): ActionCable.Channel {
        reaction(() => this.hasChanges, () => this.grid.trigger("change", {field: "refreshButtonIcon"}));
        const typed_id = underscore(this.gridConfig.modelName());
        this.dataSource.bind("requestEnd", mobxAction(() => {
            console.log("Request ended");
            this.hasChanges = false
        }));

        return cable.subscriptions.create({
                channel: "ActionChannel",
                typed_id,
            }, {
                connected: () => {
                    console.log("ActionChannel connected");
                },
                disconnected: () => {
                    console.log("ActionChannel disconnected");
                },
                received: mobxAction((action: ICableAction) => {
                    switch (action.event) {
                        case ActionEvent.Delete: {
                            this.onDelete(action.object);
                            break;
                        }
                        case ActionEvent.Update: {
                            this.onUpdate(action.object);
                            break;
                        }
                        case ActionEvent.Create: {
                            this.onCreate(action.object);
                            break;
                        }
                        default: {
                            throw new InvalidActionEventError(`Event type "${action.event}" does not exist.`);
                        }
                    }
                }),
            },
        );
    }

// const index = _.findIndex(options.data, {[matcher]: update.object[matcher]});
    private findExisting(object: {[key: string]: any}): number {
        const {idField} = this;
        return _.findIndex((this.dataSource as any)._pristineData, [idField, object[idField]]);
    }

    @mobxAction
    public onUpdate(object: {[key: string]: any}): void {
        const index = this.findExisting(object);

        const {idField} = this;
        const {selectedRecord} = this.grid;
        const currentlyEditing = this.grid.isEditing && selectedRecord && selectedRecord[idField] === object[idField];
        if (index >= 0 && this.subscriptionConfig.update && !currentlyEditing) {
            this.grid.gearsDataSource.gearsInjectUpdateItem(object, true);
            (this.dataSource as any)._change();
        } else {
            this.hasChanges = true;
        }
    }

    @mobxAction
    public onCreate(object: {[key: string]: any}): void {
        if(this.subscriptionConfig.insert) {
            this.grid.gearsDataSource.gearsInjectUpdateItem(object, true);
            (this.dataSource as any)._change();
        } else {
            this.hasChanges = true;
        }
    }

    @mobxAction
    public onDelete(object: {[key: string]: any}): void {

        const index = this.findExisting(object);

        const {idField} = this;
        const {selectedRecord} = this.grid;
        const currentlyEditing = this.grid.isEditing && selectedRecord && selectedRecord[idField] === object[idField];
        if (index >= 0 && this.subscriptionConfig.update && !currentlyEditing) {
            // this.dataSource.pushDestroy(object);
            this.dataSource.read();
        } else {
            this.hasChanges = true;
        }
    }

    public destroy(): void {
        this.subscription && this.subscription.unsubscribe();
    }
}
