import * as Promise from "bluebird";
import * as $ from "jquery";
import * as _ from "lodash";
import { reaction, toJS, autorun, isObservable, observable } from "mobx";
import { ConfigurationError } from "../Errors";
import gearsState from "../GearsState/index";
import { GridController } from "../GridController";
import { pluralize } from "../helpers/index";
import { dialog } from "../helpers/Notification";
import createFilterBuilder, { defaultFilter } from '../../react-components/FilterBuilder';
import { bind } from 'decko';

declare const Gears: any;

function filterDataSource(modelName: string): kendo.data.DataSource {
    return new kendo.data.DataSource({
        data: gearsState.lookups.lists.get("saved_filters"),
        schema: { model: Gears.Auto.Models.SavedFilter },
        filter: {
            field: "filter_model",
            operator: "eq",
            value: modelName
        },
        sort: [
            {
                field: "user_id",
                dir: "desc"
            },
            {
                field: "filter_name",
                dir: "asc"
            }
        ]
    });
}

interface IFilterSpec {
    filter_name: string;
    id: string;
    filter_json: object;
}

/**
 * Marking every class method as private so I can tell from
 * other files what functions are actually private
 */
export default class GridFilter extends kendo.data.ObservableObject {
    public allFilters: any;
    private selectedFilter = null;
    private restoreAjaxIndicator: Function;
    private timeout: number;
    private grid: GridController;
    private filterActive: boolean;
    private filterMessage: string;
    private lookupMenus: kendo.data.ObservableArray;
    private fullTextSearchString: string;
    private $input: JQuery;

    constructor(grid: GridController) {
        super();
        super.init(this);
        this.grid = grid;
        // let dataConfig = defaultGearsConfig(grid.config.modelName());
        this.set("lookupMenus", new kendo.data.ObservableArray([]));
        this.filterActive = false;
        this.filterMessage = "All Records";
        this.fullTextSearchString = "";
        this.calcFilterActive = this.calcFilterActive.bind(this);
        this.filterSelected = this.filterSelected.bind(this);
        this.chooseFilter = this.chooseFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.updateFullTextSearch = this.updateFullTextSearch.bind(this);
        this.addFilterOption = this.addFilterOption.bind(this);
        this.clearFullTextSearch = this.clearFullTextSearch.bind(this);
        this.setupFilterMenu = this.setupFilterMenu.bind(this);

        this.bind("change", (e: any) => {
            if (e.field === "fullTextSearchString") {
                _.defer(() => this.quietUpdateFullTextSearch());
            }
        });

        this.set("allFilters", {});
        this.watchAllFilters();

        this.restoreAjaxIndicator = _.debounce(() => {
            return this.grid.gearsGrid.element.removeClass("hide-kendo-loading-mask");
        }, 2000);

        reaction(() => toJS(this.allFilters), () => this.trigger("change", { field: "allFilters" }));
    }

    public filterMessageText(): string {
        return this.get("filterMessage");
    }

    private hasFullTextSearch(): boolean {
        return this.get("fullTextSearchString").length > 0;
    }

    private calcFilterActive(): boolean {
        const filterSpec: kendo.data.DataSourceFilters = this.grid && this.grid.dataSource.filter();
        let filterActive: boolean = !!filterSpec;
        const filters: kendo.data.DataSourceFilter[] | undefined = filterSpec ? filterSpec.filters : undefined;
        if (filters) {
            switch (filters.length) {
                case 0:
                    filterActive = false;
                    break;
                case 1:
                    const filter: kendo.data.DataSourceFilterItem = filters[0];
                    const fulltextVal: string | undefined = this.$input && this.$input.val && (this.$input.val() as string);
                    if (filter.field === "fulltext" && fulltextVal === filter.value) {
                        filterActive = false;
                    }
            }
        }
        this.set("filterActive", filterActive);
        return filterActive;
    }

    private filterSelected(): boolean {
        return this.get("selectedFilter") !== null;
    }

    private clearFullTextSearch(): void {
        this.$input.val("");
        return this.set("fullTextSearchString", "");
    }

    /**
     * Open the FilterBuilder
     */
    @bind
    public async editFilter() {
        const fields = await this.getFields();
        const selectedFilter = this.grid.dataSource.filter();
        let name: string = this.get("filterMessage");
        if (name === "All Records") {
            name = "";
        }
        createFilterBuilder(selectedFilter ? selectedFilter : defaultFilter(), fields, name)
            .then((filter) => {
                const cleanedFilter = this.cleanFilter(filter);
                if (cleanedFilter.filters) {
                    if (cleanedFilter !== this.grid.dataSource.filter()) {
                        this.set("filterMessage", "Custom Filter");
                    }
                    this.grid.dataSource.filter(toJS(filter));
                } else {
                    this.set("filterMessage", "All Records");
                    this.grid.dataSource.filter({});
                }
            });
    }

    private async getFields() {
        const lookups = global.gearsState.lookups.lists;
        return Gears.Auto.Grids[pluralize(this.grid.config.modelName())].grid.columns.map((item) => {
            const field = Gears.Auto.Models[this.grid.config.modelName()].fields[item.field];
            if (item.filterable !== undefined && item.filterable === false) {
                return;
            }
            switch (field.type) {
                case "object":
                    if (field.lookup) {
                        return {
                            value: item.field,
                            label: item.title,
                            type: "object",
                            data: lookups.get(field.lookup.source).map((lookup) => {
                                return {
                                    value: lookup.identifier,
                                    label: lookup.text,
                                    icon: lookup.icon,
                                }
                            })
                        }
                    }
                    return {
                        value: item.field,
                        label: item.title,
                        type: "string",
                    }
                case "date":
                    return {
                        value: item.field,
                        label: item.title,
                        type: "date",
                    }
                case "datetime":
                    return {
                        value: item.field,
                        label: item.title,
                        type: "datetime",
                    }
                case "boolean":
                    return {
                        value: item.field,
                        label: item.title,
                        type: "boolean",
                    }
                case "number":
                    return {
                        value: item.field,
                        label: item.title,
                        type: "number",
                    }
                case "string":
                default:
                    if (field.lookup) {
                        return {
                            value: item.field,
                            label: item.title,
                            type: "object",
                            data: lookups.get(field.lookup.source).map((lookup) => {
                                return {
                                    value: lookup.identifier,
                                    label: lookup.text,
                                    icon: lookup.icon,
                                }
                            })
                        }
                    }
                    return {
                        value: item.field,
                        label: item.title,
                        type: "string",
                    }
            }
        }).sort((a, b) => a.label > b.label);
    }

    private cleanFilter(filter) {
        const cleanedFilter = toJS(filter);
        for (let i = 0; i < cleanedFilter.filters.length; i++) {
            const filterItem = cleanedFilter.filters[i];
            if (filterItem.filters) {
                filterItem.filters = this.cleanFilter(filterItem).filters;
                if (!filterItem.filters) {
                    cleanedFilter.filters.splice(i, 1);
                    i--;
                }
            } else {
                if (!filterItem.field || !filterItem.operator) {
                    cleanedFilter.filters.splice(i, 1);
                    i--;
                }
            }
        }
        if (cleanedFilter.filters.length) {
            return cleanedFilter;
        }
        return {};
    }

    private updateFullTextSearch() {
        const ftString = this.get("fullTextSearchString");
        if (ftString.length > 0) {
            return this.addFilterOption("fulltext", ftString, "contains");
        } else {
            return this.clearFilterOption("fulltext");
        }
    }

    private searchStringBlur() {
        return this.quietUpdateFullTextSearch(0);
    }

    private searchStringKeyup(event: KeyboardEvent) {
        if (37 <= event.keyCode && event.keyCode < 40) {
            event.stopPropagation();
            return;
        }

        if (event.keyCode == 13) {
            this.quietUpdateFullTextSearch(0);
        } else {
            this.quietUpdateFullTextSearch(null);
        }

        event.preventDefault();
        event.stopPropagation();
    }

    private searchStringKeydown(event: KeyboardEvent) {
        if (37 <= event.keyCode && event.keyCode < 40) {
            event.stopPropagation();
        }
    }

    private suppressAjaxIndicator() {
        this.grid.gearsGrid.element.addClass("hide-kendo-loading-mask");
        this.restoreAjaxIndicator();
    }

    /**
     * TODO: replace this with the newer notifications
     */
    private deleteFilter(event: { data: { filter_name: string; id: string } }) {
        return dialog({
            content: `Delete Filter: ${event.data.filter_name}`,
            title: "Are you sure?",
            type: "warning"
        })
            .then(() => {
                return $.ajax({
                    url: "/saved_filters/" + event.data.id,
                    dataType: "json",
                    type: "DELETE"
                });
            })
            .then((result: any) => {
                if (result.error) {
                    throw result.error;
                }
                const saved_filters = gearsState.lookups.lists.get("saved_filters") as any[];
                const toRemove = _.findIndex(saved_filters, ["id", event.data.id]);
                saved_filters.splice(toRemove, 1);
            })
            .catch(Gears.handleError);
    }

    public setFilter(filter: any, message: string | null): void {
        message = message === "" ? null : message;
        console.log("Setting Filter", filter, message);
        if (filter.toJSON) {
            filter = filter.toJSON();
        } else if (typeof filter === "string") {
            filter = JSON.parse(filter);
        }
        this.grid.dataSource.filter(filter);
        this.set("filterMessage", message);
    }

    private chooseFilter(inputData: IFilterSpec) {
        let data: IFilterSpec;
        if (inputData.filter_json) {
            data = inputData;
        } else if ((inputData as any).data) {
            data = (inputData as any).data;
        } else {
            data = (inputData as any).parent();
        }

        console.log("ChooseFilter: ", data);
        if (this.filterSelected()) {
            this.set("selectedFilter.background", "");
        }
        this.set("selectedFilter", data);
        this.set("selectedFilter.background", "bg-color-teal txt-color-white");
        return this.setFilter(data.filter_json, data.filter_name);
    }

    public saveFilter = () => {
        return dialog({
            title: "Save Filter As...",
            kendo: "Please enter new filter name<br/><input class='form-control' data-bind='value: filterName'/>",
            type: "question",
            buttons: [{ name: "Cancel", value: "cancel", reject: true }, "Save"]
        })
            .then((result: { filterName: string }) => {
                if (!result.filterName) {
                    return Promise.reject("Filter name required");
                }
                const filter = _.find(this.allFilters, { filter_name: result.filterName });
                if (filter) {
                    console.log("Replacing", filter);
                    return this.replaceFilter(filter);
                } else {
                    return this.doSaveFilter(result.filterName);
                }
            })
            .catch(Gears.handleError);
    };

    private doSaveFilter(filterName: string) {
        const newFilter = {
            filter_name: filterName,
            filter_json: this.grid.dataSource.filter(),
            filter_model: this.grid.config.modelName(),
            user_id: gearsState.user.id
        };
        return Promise.resolve(
            $.ajax({
                url: "/saved_filters",
                dataType: "json",
                type: "POST",
                contentType: "application/json",
                data: JSON.stringify({ saved_filter: newFilter })
            })
        )
            .then(it => {
                if (it.error) {
                    throw it.error;
                }
                // console.log("PROMISE FULLFILLED");
                (gearsState.lookups.lists.get("saved_filters") as any[]).push(it);
            })
            .catch(it => Gears.handleError(it));
    }

    private replaceFilter(filter: any) {
        return dialog({
            title: "Replace Filter",
            content: "Are you sure you wish to replace filter '" + filter.filter_name + "'?"
        })
            .then(() => {
                return $.ajax({
                    url: "/saved_filters/" + filter.id,
                    dataType: "json",
                    type: "DELETE"
                });
            })
            .then((it: any) => {
                if (it.error) {
                    throw it.error;
                }
                const saved_filters = gearsState.lookups.lists.get("saved_filters") as any[];
                const toRemove = _.findIndex(saved_filters, ["id", filter.id]);
                saved_filters.splice(toRemove, 1);
                return this.doSaveFilter(filter.filter_name);
            });
    }

    private setupLookupFilters() {
        if (!this.grid || !this.grid.gearsGrid) {
            console.log("Grid missing in lookup filters!");
            return;
        }
        return _.each(this.grid.gearsGrid.columns, (column: any) => {
            let ref$, i, menu;
            if ((ref$ = column.lookup) != null && ref$.values && column.filter_menu) {
                column.value = null;
                column.context_menu_id = column.field + "-context-menu";
                i = this.lookupMenus.push(column);
                menu = this.lookupMenus[i - 1];
                menu.values = gearsState.lookups.lists.get(column.lookup.values);
                return _.each(menu.values, item => {
                    item.background = "";
                    if (item.icon == null) {
                        item.icon = "";
                    }
                });
            }
        });
    }

    public setupFilterMenu() {
        let searchView, domId, ref$, domElement, ref1$, e;
        this.setupLookupFilters();
        searchView = new kendo.View("#gears-search-template", {
            model: this.grid,
            evalTemplate: true
        });
        domId = this.grid && this.grid.config && this.grid.config.tableContainerDomId();
        if (!domId) {
            throw new ConfigurationError("Grid config not found found");
        }
        domId += " .gears-search";
        domElement = $(domId);
        if (!domElement) {
            throw new ConfigurationError("Grid search element not found: " + domId + " config not found found");
        } else if (domElement.length > 1) {
            domElement = this.grid.parentGrid ? $(`.${this.grid.parentGrid.config.uModelName()} ${domId}`) : domElement.first();
        }
        try {
            searchView.render(domElement);
            if (this.grid != null && ((ref1$ = this.grid.config) != null && ref1$.autocomplete_search)) {
                console.log("Autosuggest disabled");
                // this.grid.autosuggest = new Autosuggest(this.grid, this.grid.config);
                // this.grid.autosuggest.setupTagsinput();
            }
            const $input: any = domElement.find("[data-role=filterinput]");
            this.$input = $input;
            $input.on("keydown", this.searchStringKeydown);
            $input.on("keyup", (it: any) => {
                this.set("fullTextSearchString", $input.val());
                return this.searchStringKeyup(it);
            });
        } catch (e$) {
            e = e$;
            console.log(domElement);
            console.log(searchView);
            console.log(this.grid);
            console.log(e);
            throw new ConfigurationError("Unable to render filter menu for " + this.grid.config.modelName() + "; " + domId);
        }
        this.grid.dataSource.bind("change", this.calcFilterActive);
        $(this.grid.config.tableContainerDomId() + " .gears-search").on("click", ".filter-dropdown a", e => {
            let value, field;
            value = parseInt(
                $(e.target)
                    .closest("a")
                    .attr("data-search-id")
            );
            field = $(e.target)
                .closest("ul")
                .attr("data-search-field");
            return this.setLookupFilter(field, value);
        });
        $(this.grid.config.tableContainerDomId() + " .gears-search").on("click", "a.clear-filter-field", e => {
            let field;
            field = $(e.target)
                .closest("ul")
                .attr("data-search-field");
            return this.clearLookupFilter(field);
        });
        // This is now all handled via the configs
        // if (this.grid.gearsGrid) {
        //     this.grid.gearsGrid.bind("filterMenuInit", (e: any) => {
        //         let column: any, ref$: any, ref1$: any, input, select2options;
        //         column = _.find(this.grid.gearsGrid.columns, {
        //             field: e.field,
        //         });
        //         const lookup = column.lookup;
        //         if (lookup != null && (lookup.type != "values" || lookup.url != null)) {
        //             console.log("FMI", e);
        //             return;
        //             e.container.find(".k-filter-help-text").remove();
        //             console.log("FilterMenu");
        //             input = e.container.find("input");
        //             console.log(input.length);
        //             input.addClass("k-filter-select");
        //             e.container.find(".k-item:contains(less), .k-item:contains(greater)").remove();
        //             e.container.removeClass("k-reset");
        //             e.container.find(".k-numerictextbox").removeClass("k-numerictextbox");
        //             e.container.find(".k-numeric-wrap").removeClass("k-numeric-wrap");
        //             e.container.find(".k-select").remove();
        //             input.width(200);
        //             select2options = {
        //                 formatResult: formatLookup,
        //                 formatSelection: formatLookup,
        //                 id: lookup.id || "id",
        //             };
        //             if (column.lookup.url != null) {
        //                 select2options.ajax = {
        //                     url: lookup.url,
        //                     quietMillis: 200,
        //                     cache: true,
        //                     data: (term, page) => {
        //                         return {
        //                             term,
        //                             page,
        //                             pageSize: 100,
        //                         };
        //                     },
        //                     results: (data, page) => {
        //                         let more;
        //                         more = page * 100 < data.total_entries;
        //                         return {
        //                             results: data.results,
        //                             more,
        //                         };
        //                     },
        //                 };
        //             } else {
        //                 select2options.data = gearsState.lookups.lists.get(column.lookup.source);
        //                 console.log("Setting data");
        //             }
        //             input.select2(select2options);
        //         }
        //     });
        // }
    }

    private setLookupFilter(field, value) {
        this.addFilterOption(field, value);
        field = _.find(this.lookupMenus, {
            field
        });
        field.set("background", "txt-color-white bg-color-teal");
        return _.each(field.values, item => {
            return item.set("background", item.value === value ? "txt-color-white bg-color-teal" : "");
        });
    }

    private filterAlertClass(): string {
        if (this.get("filterMessage") === "All Records") {
            return "alert alert-success";
        } else {
            return "alert alert-info";
        }
    }

    private filterAlertIcon(): string {
        if (this.get("filterMessage") === "All Records") {
            return "fal fa-fw fa-lg fa-database";
        } else {
            return "far fa-fw fa-lg fa-filter";
        }
    }

    private filterMessageHtml(): string {
        // console.log("filterMessageHtml", this.filterMessage);
        return `<i class="${this.filterAlertIcon()}"></i> ${this.get("filterMessage")}`;
    }

    private clearFilter() {
        let realFilter, interceptedFilter, adefault, saveFT;
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        try {
            realFilter = this.grid.dataSource.filter;
            interceptedFilter = {};
            this.grid.dataSource.filter = it => {
                if (it === undefined) {
                    return interceptedFilter;
                } else {
                    return (interceptedFilter = it);
                }
            };
            if (this.grid.parentGrid == null) {
                adefault = _.find(this.allFilters, {
                    is_default: true
                });
            }
            console.log("Default", adefault, this.filterMessage, adefault ? adefault.filter_name : undefined);
            if (adefault && this.filterMessage === adefault.filter_name) {
                adefault = false;
            }
            saveFT = this.get("fullTextSearchString");
            this.set("filterMessage", "All Records");
            if ((saveFT != null ? saveFT.length : void 8) > 0) {
                if (adefault) {
                    this.chooseFilter({
                        data: adefault
                    });
                } else {
                    this.grid.dataSource.filter({});
                }
            } else {
                if (adefault) {
                    this.chooseFilter({
                        data: adefault
                    });
                } else {
                    this.grid.dataSource.filter({});
                }
            }
            this.updateFullTextSearch();
            _.each(this.lookupMenus, field => {
                field.set("background", "");
                return _.each(field.values, item => {
                    return item.set("background", "");
                });
            });
            _.each(this.allFilters, it => {
                it.set("background", "");
            });
            $(".k-filter-menu input.k-filter-select").select2("val", null);
            return this.set("selectedFilter", null);
        } finally {
            this.grid.dataSource.filter = realFilter;
            this.grid.dataSource.filter(interceptedFilter);
            // console.log(interceptedFilter);
        }
    }

    private quietUpdateFullTextSearch(quiet_millis?: number | null) {
        let ref$, ref1$;
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        const ftString = this.get("fullTextSearchString") || "";
        if (((ref$ = ftString.match(/\(/g)) != null ? ref$.length : void 8) !== ((ref1$ = ftString.match(/\)/g)) != null ? ref1$.length : void 8)) {
            return;
        }
        if (quiet_millis == null) {
            if (ftString.length === 0) {
                quiet_millis = 0;
            } else {
                if (ftString.match(/((^|\s)\w{1,2}|\s+)$/)) {
                    quiet_millis = 3000;
                } else {
                    quiet_millis = 1000;
                }
            }
        }
        this.suppressAjaxIndicator();
        return (this.timeout = window.setTimeout(this.updateFullTextSearch, quiet_millis));
    }

    private clearLookupFilter(field) {
        this.clearFilterOption(field);
        field = _.find(this.lookupMenus, {
            field
        });
        field.set("background", "");
        return _.each(field.values, item => {
            item.set("background", "");
        });
    }

    private addFilterOption(field, value, operator) {
        operator == null && (operator = "eq");
        let filterData: any = this.grid.dataSource.filter();
        if ((filterData != null ? filterData.filters : void 8) == null) {
            filterData = {
                filters: [],
                logic: "and"
            };
        }
        if ((filterData != null ? filterData.logic : void 8) !== "and") {
            filterData = {
                filters: [filterData],
                logic: "and"
            };
        }
        let fieldData: any = _.find(filterData.filters, {
            field
        });
        if (fieldData) {
            fieldData.value = value;
            fieldData.operator = operator;
        } else {
            fieldData = {
                field,
                operator,
                value
            };
            filterData.filters.push(fieldData);
        }
        return this.grid.dataSource.filter(filterData);
    }

    private clearFilterOption(field: string) {
        let filterData;
        filterData = this.grid.dataSource.filter();
        if ((filterData != null ? filterData.filters : void 8) == null) {
            return;
        }
        filterData.filters = _.reject(filterData.filters, {
            field
        });
        return this.grid.dataSource.filter(filterData);
    }

    public watchAllFilters(): void {
        const SavedFilter = Gears.Model.define(Gears.Auto.Models.SavedFilter);
        autorun(() => {
            let filtersToObserve = gearsState.lookups.lists.get("saved_filters");
            if (!isObservable(filtersToObserve)) {
                filtersToObserve = observable.array(filtersToObserve);
                gearsState.lookups.lists.set("saved_filters", filtersToObserve);
            }
            const savedFilters = _(toJS(filtersToObserve))
                .filter(["filter_model", this.grid.config.modelName()])
                .orderBy(["user_id", "filter_name"], ["desc", "asc"])
                .map(f => new SavedFilter(f))
                .value();
            this.set("allFilters", savedFilters);
        }, { name: "setAllFilters" });
    }

    public loadFilterList() {
        _.defer(() => {
            let hash_filter, adefault: any;
            if (this.grid.single_record_loading) {
                return;
            }
            hash_filter = Gears.getParamFromHash(location.hash, "filter");
            if (hash_filter) {
                this.setFilter(hash_filter, "URL Filter");
                location.hash = "";
                return;
            }
            if (this.grid.parentGrid == null) {
                adefault = _.find(this.allFilters, {
                    is_default: true
                });
            }
            if (adefault) {
                this.chooseFilter({
                    data: adefault
                });
            } else {
                this.grid.dataSource.read();
            }
            // console.log("loaded filters");
        });
    }
}

export function addGearsFilter(filteredGrid: GridController) {
    const gearsFilter = new GridFilter(filteredGrid);
    filteredGrid.set("gearsFilter", gearsFilter);
    // filteredGrid.gearsFilter = gearsFilter;
    gearsFilter.loadFilterList();
    return gearsFilter;
}
