import {SFC} from "react";

interface IFaTextProps {
    className?: string;
    transform?: string;
    [spanProp: string]: any;
}

export const Text: SFC<IFaTextProps> = ({children, className, transform, ...props}) => {
    return <span className={(className || "") + " fa-layers-text"} {...props} data-fa-transform={transform || ""}>
        {children}
    </span>;
};

interface IFaCounterProps {
    className?: string;
    [spanProp: string]: any;
}

export const Counter: SFC<IFaTextProps> = ({children, className, ...props}) => {
    return <span className={(className || "") + " fa-layers-counter"} {...props}>
        {children}
    </span>;
};

interface IFaLayerProps {
  className?: string;
  size?: string;
  [spanProp: string]: any;
}

interface IFALayer extends SFC<IFaLayerProps> {
    Text: SFC<IFaTextProps>;
    Counter: SFC<IFaCounterProps>;
}

const Layer: SFC<IFaLayerProps> = ({children, className, size, ...props}) => {
    return <span className={size ? `fa-${size}` : ""}>
        <span className={(className || "") + " fa-layers fa-fw"} {...props}>
            {children}
        </span>
    </span>;
};

const mergedLayer: IFALayer = Layer as any;
mergedLayer.Text = Text;
mergedLayer.Counter = Counter;
export {mergedLayer as Layer};

export default mergedLayer;
