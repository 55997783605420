window.storage = false;// Jarvis widget hack
require( './smartadmin/bootstrap/bootstrap');

require( './smartadmin/notification/SmartNotification');

require( './gears.jarvis.widget');


//require( 'jquery-ui/datepicker');
//require( 'jquery-ui/ui/i18n/datepicker-en-AU');

require( './smartadmin/plugin/select2/select2');
require( './smartadmin/plugin/bootstrap-tags/bootstrap-tagsinput');

//require( './smartadmin/plugin/fastclick/fastclick');

require( './gears.smartadmin.app.config');
require( './gears.smartadmin.app');
