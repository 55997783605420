/// <reference types="kendo-ui"/>

import * as Promise from "bluebird";
import * as moment from "moment-timezone";
import { DeleteRecordError, DataFetchError } from "./Errors";
import GridConfig from "./GridConfig";
import { GridController } from "./GridController";
import { Model } from "./Model";

declare const kendo: any;

const KendoDataSource = kendo.data.DataSource;
const kendoCollapseGroup = kendo.ui.Grid.prototype.collapseGroup;
const kendoExpandGroup = kendo.ui.Grid.prototype.expandGroup;
const kendoTrigger = kendo.data.DataSource.prototype.trigger;
const kendoAggregates = kendo.data.DataSource.prototype.aggregates;
const kendo_Params = kendo.data.DataSource.prototype._params;
const kendo_QueryProcess = kendo.data.DataSource.prototype._queryProcess;

const CHANGE = "change";
const arrayPush = [].push;
const arrayUnshift = [].unshift;

function comparer(a: any, b: any) {
    if (!a) {
        return a === b;
    }
}

interface IKendoDataSourceWithInternals extends kendo.data.DataSource {
    _data: kendo.data.ObservableArray;
    _pristineData: object[];
    _group: Array<{ field: string }>;
    _view: Array<{ value: number | string, items: Model[] }>;
    reader: { data: (data: object[]) => any };
    _readData: (data: object[]) => any;
    _params: (data: any) => any;
    _queryProcess: (data: object[], options: object) => any;
    _change: () => void;
}

// class WrappedKendoDataSource extends kendo.data.DataSource

interface IGroupExpansions {
    [group: string]: boolean;
}

export function standardizeAllDates(data: { [p: string]: any }): { [p: string]: any } {
    _.each(data, (value: any, key: string) => {
        if (moment.isDate(value) || moment.isMoment(value)) {
            data[key] = moment(value).format("YYYY-MM-DD HH:mm Z");
        }
    });
    return data;
}

export default class GearsDataSource {
    private readonly kendoDataSource: IKendoDataSourceWithInternals;
    private readonly model: typeof Model;
    public saveActive: boolean = false;
    private _fetchMoreRequest: Promise<any> = Promise.resolve("Just Starting");
    private _saveRequest: Promise<any> = Promise.resolve("Just Starting");
    private _serverTotal: number;
    private _queryConfig?: object;
    private _expandedGroups?: IGroupExpansions;
    private _loadingMessage: string = "<span class='loading-message'><i class='fal fa-spin fa-circle-notch'></i>&nbsp;&nbsp;&nbsp;Loading additional records...</span>";
    private _serverGroups: any;
    private _pagesLoaded: number = 0;

    constructor(private readonly gridConfig: GridConfig, private readonly grid: GridController) {
        this.kendoDataSource = grid.dataSource as IKendoDataSourceWithInternals;
        this.model = gridConfig.gearsModel;
        this.clearData();
        this.wrapKendo();
        this.grid.gearsGrid && $(this.grid.gearsGrid.content).scroll(this.loadMoreIfNecessary.bind(this));
    }

    public gearsInjectUpdate(items: object | object[]): Model | Model[] {
        if (_.isArrayLike(items)) {
            return _.each(items, (i: object) => this.gearsInjectUpdateItem(i));
        } else {
            return this.gearsInjectUpdateItem(items);
        }
    }

    public gearsInjectUpdateItem(item: object, addToTop?: boolean): Model {
        // var addToArray, model, id, targetIndex, target, groupField, ref$, ref1$, group;
        // top == null && (top = false);
        const addToArray = addToTop ? arrayUnshift : arrayPush;
        const model: Model = new this.model(item);
        const id = model.id;
        const kendoDataSource = this.kendoDataSource;
        const targetIndex = _.findIndex(kendoDataSource._data, ["id", id]);
        if (targetIndex !== -1) {
        }
        if (targetIndex !== -1) {
            const target: Model = kendoDataSource._data[targetIndex];
            kendoDataSource._data[targetIndex] = model;
            kendoDataSource._pristineData[targetIndex] = model.toJSON();
            return model;
        } else {
            addToArray.call(kendoDataSource._pristineData, model.toJSON());
            addToArray.call(kendoDataSource._data, model);
            const firstGroup = kendoDataSource._group[0];
            const groupField: string | null = firstGroup && firstGroup.field;
            if (groupField) {
                const group = _.find(kendoDataSource._view, {
                    value: model[groupField],
                });
                if (group) {
                    addToArray.call(group.items, model);
                }
                {
                    addToArray.call(kendoDataSource._view, {value: model[groupField], items: [model]});
                }
            } else {
                addToArray.call(kendoDataSource._view, model);
            }
            return model;
        }
    }

    private wrapKendo() {
        this.kendoDataSource._readData = (data) => {
            this.saveServerData(data);
            return this.kendoDataSource.reader.data(data);
        };

        this.kendoDataSource.aggregates = () => {
            const result = kendoAggregates.call(this.kendoDataSource);
            result.total_entries = this._serverTotal;
            return result;
        };

        this.kendoDataSource._params = (data) => {
            const originalServerGrouping = this.kendoDataSource.options.serverGrouping;
            this.kendoDataSource.options.serverGrouping = true;
            const result = kendo_Params.call(this.kendoDataSource, data);
            this.kendoDataSource.options.serverGrouping = originalServerGrouping;
            this._queryConfig = result;
            return result;
        };

        this.kendoDataSource._queryProcess = (data: object[], options: object) => {
            let res = kendo_QueryProcess.call(this.kendoDataSource, data, options);
            res = this.fillServerGroupData(res);
            return res;
        };

        if (this.grid.gearsGrid) {
            this.grid.gearsGrid.collapseGroup = this.collapseGroup;
            this.grid.gearsGrid.expandGroup = this.expandGroup;
        }
    }

    public expandedGroups(setValue?: IGroupExpansions): IGroupExpansions {
        const key = this.gridConfig.baseName() + "_expanded";
        if (setValue != null) {
            this._expandedGroups = setValue;
            // localStorage.setItem(key, JSON.stringify(setValue));
            return setValue;
        } else {
            if (this._expandedGroups == null) {
                // const localStorageValue = localStorage.getItem(key);
                // if (localStorageValue) {
                //     this._expandedGroups = JSON.parse(localStorageValue);
                // }
                this._expandedGroups = {};
            }
            return this._expandedGroups;
        }
    }

    public collapseGroup = (row: string | JQuery<HTMLElement> | Element, persist?: boolean): void => {
        persist == null && (persist = true);
        const groupData = $(row).find(".group-data").data();
        if (persist && groupData) {
            const egs = this.expandedGroups() || {};
            egs[groupData.groupValue] = false;
            this.expandedGroups(egs);
        }
        if (!groupData) {
            return;
        }
        $(row).removeClass("expanded");
        $(this.grid.gearsGrid.content).find("td[data-group-value='" + groupData.groupValue + "']").parent().addClass("collapsed");
        kendoCollapseGroup.call(this.grid.gearsGrid, row);
        this.loadMoreIfNecessary();
    };

    public expandGroup = (row: string | JQuery<HTMLElement> | Element, persist?: boolean) => {
        persist == null && (persist = true);
        const $group = $(row);
        const groupData = $group.find(".group-data").data();
        if (persist) {
            if (groupData) {
                const egs = this.expandedGroups() || {};
                egs[groupData.groupValue] = true;
                this.expandedGroups(egs);
            }
        }
        $group.addClass("expanded");
        $(this.grid.gearsGrid.content).find("td[data-group-value='" + (groupData != null ? groupData.groupValue : void 8) + "']").parent().removeClass("collapsed");
        kendoExpandGroup.call(this.grid.gearsGrid, row);
        return this.loadMoreIfNecessary();
    };

    public groupStateExpanded = (row: string | JQuery<HTMLElement> | Element) => {
        const groupData = $(row).find(".group-data").data();
        if (!groupData) {
            return false;
        }
        return !!this.expandedGroups()[groupData.groupValue];
    };

    public collapseGroups(): void {
        let openGroupingCells, i$, len$, groupCell, groupRow, expanded;
        openGroupingCells = $(this.grid.gearsGrid.content).find(".k-grouping-row > td[aria-expanded=\"true\"]");
        for (const groupCell of openGroupingCells) {
            $(groupCell).parent().addClass("expanded");
        }
        if (openGroupingCells.length <= 1) {
            return;
        }
        if (!this.expandedGroups()) {
            this.expandGroup(openGroupingCells.first());
        }
        for (const groupCell of openGroupingCells) {
            groupRow = $(groupCell).parent();
            expanded = this.groupStateExpanded(groupRow);
            if (!expanded) {
                this.collapseGroup(groupRow, false);
            }
        }
    }

    public _bufferRowForGroup(group: { items: Model[], value: string, field: string, total_entries: number }) {
        if (group.items.length < group.total_entries) {
            const $groupRow = $(this.grid.gearsGrid.content).find("[data-group-value=\"" + group.value + "\"]").closest("tr");
            $groupRow.addClass("load-incomplete");
            let $bottomRow;
            if (group.items.length === 0) {
                $bottomRow = $groupRow;
            } else {
                $bottomRow = $(this.grid.gearsGrid.content).find("[data-uid=\"" + group.items[group.items.length - 1].uid + "\"]");
            }
            const colspan = $groupRow.find("td").attr("colspan");
            const $tr = $("<tr data-group-value='" + group.value + "' data-group-field='" + group.field + "' class='gears-placeholder'></tr>");
            $tr.addClass($groupRow.hasClass("expanded") ? "expanded" : "collapsed");
            const $td = $("<td colspan='" + colspan + "' class='gears-placeholder' data-group-value='" + group.value + "'>" + this._loadingMessage + "</td>");
            const missing_height = (group.total_entries - group.items.length) * 34;
            $td.height(missing_height > 1200 ? 1200 : missing_height);
            $tr.append($td);
            $bottomRow.after($tr);
        }
    }

    public _placeholderRowUngrouped() {
        // var $content, $last, colspan, $tr, $td, missing_height;
        if (this.kendoDataSource.data().length < this._serverTotal) {
            const $content = $(this.grid.gearsGrid.content);
            const $last = $content.find("tr:last");
            const colspan = $last.find("td").length;
            const $tr = $("<tr class='expanded gears-placeholder'></tr>");
            const $td = $("<td colspan='" + colspan + "' class='gears-placeholder' >" + this._loadingMessage + "</td>");
            const missing_height = (this._serverTotal - this.kendoDataSource.data().length) * 34;
            $td.height(missing_height > 1200 ? 1200 : missing_height);
            $tr.append($td);
            return $last.after($tr);
        }
    }

    public markIncompleteLoad() {
        const view = (this.kendoDataSource.view() as any);
        if (!(view.length > 0)) {
            return;
        }
        if (this._serverGroups) {
            for (const group of view) {
                this._bufferRowForGroup(group);
            }
        } else {
            this._placeholderRowUngrouped();
        }
        this.loadMoreIfNecessary();
    }

    public saveServerData(data: any) {
        if (data.group) {
            this._serverGroups = data.group;
            this.grid.set("groupData", data.group);
        }
        if (data.total_entries != null) {
            this._serverTotal = data.total_entries;
        }
        return this._pagesLoaded = parseInt(data.page);
    }

    public fillServerGroupData(data: any) {
        let calcdata, i$, ref$, len$, serverGroup, calcgroup, items, res;
        if (!this._serverGroups) {
            return data;
        }
        data.total_entries = this._serverTotal;
        calcdata = data.data;
        data.data = [];
        for (const serverGroup of this._serverGroups) {
            calcgroup = _.find(calcdata, (it: any) => it.value === serverGroup.value);
            items = (calcgroup != null ? calcgroup.items : void 8) || [];
            res = {
                field: serverGroup.field,
                aggregates: serverGroup.aggregates,
                hasSubgroups: false,
                items,
                total_entries: serverGroup.total_entries,
                value: serverGroup.value,
            };
            res.aggregates.total_entries = serverGroup.total_entries;
            data.data.push(res);
        }
        return data;
    }

    public recordChanges(record: Model) {
        console.log("Record Changes:", record);
        if (!record) {
            return {};
        }
        const idField = this.model.idField;
        const newData = typeof record.toJSON == "function" ? record.toJSON() : record;
        const pristineData = _.find(this.kendoDataSource._pristineData, [idField, record[idField]]);
        console.log("Changed Data", {
            nd: newData,
            pd: pristineData,
        });
        const changedData = _.pickBy(newData, (value: any, key: string) => {
            return key[0] !== "_"
                && (pristineData == null || !_.isEqualWith(pristineData[key], value, comparer))
                && key !== "webfront_relations";
        });
        console.log("Changed Data", changedData);
        return changedData;
    }

    private _wrapData(data: { [key: string]: any }) {
        standardizeAllDates(data);
        return {[this.gridConfig.instanceName()]: data};
    }

    public deleteById(id: number | string) {
        return Promise.resolve(
            $.ajax({
                url: this.gridConfig.destroyUrl(id),
                dataType: "json",
                type: "DELETE",
            }))
            .catch((result) => {
                throw new DeleteRecordError(result.responseJSON);
            })
            .then((data) => {
                let oldItem;
                if (data.error) {
                    throw new DeleteRecordError(data.error);
                }
                oldItem = this.kendoDataSource.get(id);
                if (oldItem) {
                    return this.kendoDataSource.pushDestroy(oldItem);
                }
            });
    }

    public deleteRecord(recordToDelete: Model) {
        return this.deleteById(recordToDelete.id);
    }

    public kendoHeaderData() {
        const ds = this.grid.dataSource;
        const data = {
            sort: ds.sort(),
            filter: ds.filter(),
            aggregate: this.gridConfig.data_source.aggregate,
            pageSize: this.gridConfig.data_source.pageSize,
            page: this._pagesLoaded + 1,
        };
        return data;
    }

    public getParentId() {
        return _.get(this.grid, "parentGrid.selectedRecord.id");
    }

    public fetchAllData() {
        const headerData = _.cloneDeep(this._queryConfig);
        delete headerData.pageSize;
        return this.fetchMoreData(headerData);
    }

    public fetchGroupData(groupData: { [key: string]: boolean }) {
        const headerData = _.cloneDeep(this._queryConfig);
        const groupItems = _.find(this.kendoDataSource.view(), {
            value: groupData.groupValue,
        }).items;
        const groupIds = _(groupItems).map("id").uniq().value();
        headerData.without_ids = groupIds;
        headerData["expanded-groups"] = groupData;
        return this.fetchMoreData(headerData);
    }

    public fetchMoreData(headerData: any) {
        if (!(this.kendoDataSource.data().length < this._serverTotal)) {
            return Promise.resolve("Already Loaded All Records");
        }
        if (!this._fetchMoreRequest.isFulfilled()) {
            return this._fetchMoreRequest;
        }
        return this._fetchMoreRequest = Promise
            .resolve(headerData)
            .then((headerData) => {
                if (headerData == null) {
                    headerData = this._queryConfig;
                    headerData.offset = this.kendoDataSource._data.length;
                }
                return $.ajax({
                    url: this.gridConfig.readUrl(this.getParentId()),
                    dataType: "json",
                    type: "POST",
                    data: headerData,
                });
            }).then((data) => {
                if (data.error) {
                    throw new DataFetchError(data.error);
                }
                this.saveServerData(data);
                return this.incrementalInject(data.data);
            });
    }

    public incrementalInject(data: object[], addToTop?: boolean, per: number = 50) {
        per == null && (per = 50);
        return _(data).chunk(per).each((ch: object[]) => {
            this.gearsInjectUpdate(ch);
            return this.kendoDataSource._change();
        });
    }

    public _createUrl(): string {
        return this.gridConfig.createUrl(this.getParentId());
    }

    public saveRecord(record: Model) {
        if (this.grid.saveActive || this._saveRequest && this._saveRequest.isPending()) {
            return Promise.reject("Save in Progress");
        }
        this.grid.set("saveActive", true);
        return this._saveRequest = Promise.try(() => {
            const isNew = record.isNew();
            const data = JSON.stringify(this._wrapData(this.recordChanges(record)));
            return $.ajax({
                url: isNew
                    ? this._createUrl()
                    : this.gridConfig.updateUrl(record.id),
                dataType: "json",
                contentType: "application/json",
                type: isNew ? "POST" : "PUT",
                data,
            });
        }).then((data) => {
            let newRec;
            if (data.error) {
                throw data;
            }
            console.log("save success, new record:");
            console.log(data);
            this.grid.set("selectedRecord", null);
            newRec = this.gearsInjectUpdateItem(data, true);
            this.kendoDataSource._change();
            this.grid.set("selectedRecord", newRec);
            this.kendoDataSource.cancelChanges();
            return newRec;
        }).finally(() => {
            console.log("setting save false");
            return this.grid.set("saveActive", false);
        });
    }

    public setGrouping(groupConfig: object): void {
        this.clearData();
        if (!groupConfig) {
            return this.kendoDataSource.group([]);
        }
        if (typeof groupConfig === "string") {
            groupConfig = {
                field: groupConfig,
                dir: "asc",
            };
        }
        this.kendoDataSource.group([groupConfig]);
    }

    public clearData(): void {
        this._serverTotal = 0;
        this._serverGroups = null;
        this._pagesLoaded = 0;
        this._expandedGroups = undefined;
        this._queryConfig = undefined;
    }

    public scrollIsNearBottom(): false | JQuery<HTMLElement> {
        const content = $(this.grid.gearsGrid.content);
        const displayHeight: number = content.height() as number;
        const scrollBottom = content[0].scrollTop + displayHeight;
        const lastRow = $(this.grid.gearsGrid.content).find("tr:not(.collapsed) td.gears-placeholder").first().parent();
        if (!(lastRow.length > 0)) {
            return false;
        }
        const lastRowTop = lastRow.position().top;
        // console.log("scrollIsNearBotton", scrollBottom, lastRowTop);
        if (lastRowTop < scrollBottom + 1000) {
            return lastRow;
        } else {
            return false;
        }
    }

    public loadMoreIfNecessary(event?: any): Promise<any> {
        return this._fetchMoreRequest.bind(this).then(function () {
            const $scrollRow = this.scrollIsNearBottom();
            if ($scrollRow) {
                const groupData = $scrollRow.data();
                if ((groupData != null ? groupData.groupValue : void 8) != null) {
                    return this.fetchGroupData(groupData);
                } else {
                    return this.fetchMoreData();
                }
            }
        });
    }

    public loadFreshRecord(toLoad?: number | string | Model): Promise<Model | null> {
        if (toLoad == null) {
            toLoad = this.grid.get("selectedRecord.id");
        }
        const id: number | string | null = (typeof toLoad === "object") ? toLoad.id : toLoad;
        if (id == null) {
            return Promise.resolve(null);
        }
        return Promise.resolve(
            $.ajax({
                url: this.gridConfig.loadSingleUrl(id),
                dataType: "json",
                type: "GET",
            })).then((data: any) => {
            let newRec;
            if (data.error) {
                throw new DataFetchError(data.error);
            }
            newRec = this.gearsInjectUpdateItem(data);
            this.kendoDataSource._change();
            newRec.trigger("change");
            return newRec;
        });
    }

    public withoutKendoTriggers(func: () => any): any {
        this.kendoDataSource.trigger = _.noop;
        const result = func();
        this.kendoDataSource.trigger = kendoTrigger;
        return result;
    }
}
