export interface ICustomCodeOptions {
    compiled: string;
    name?: string;
    source?: string;
}

export interface CustomCodeResult {
    customCode?: CustomCode;
}

export interface CustomFunction extends CustomCodeResult, Function { }
export interface CustomPropertyDescriptor extends CustomCodeResult, PropertyDescriptor { }
export interface CustomPropertyObject extends CustomCodeResult, Object { }
// interface CustomValue extends CustomCodeResult, PropertyDescriptor { }

export function isCustomFunction(codeResult: CustomCodeResult): codeResult is CustomFunction {
    return _.isFunction(codeResult);
}

export function isCustomPropertyDescriptor(codeResult: CustomCodeResult): codeResult is CustomPropertyDescriptor {
    return typeof codeResult === "object" && ((codeResult as CustomPropertyDescriptor).set !== undefined || (codeResult as CustomPropertyDescriptor).value !== undefined);
}

export function isCustomPropertyObject(codeResult: CustomCodeResult): codeResult is CustomPropertyObject {
    return typeof codeResult === "object" && !isCustomPropertyDescriptor(codeResult);
}

export class CustomCode implements ICustomCodeOptions {
    public compiled: string;
    public name?: string;
    public source?: string;
    public js?: CustomCodeResult;
    public error?: Error;

    constructor(conf: ICustomCodeOptions) {
        this.compiled = conf.compiled;
        this.name = conf.name;
        this.source = conf.source;
    }

    public computeJS() {
        try {
            this.js = eval(this.compiled[0] === "(" ? this.compiled : `(${this.compiled})`);
        } catch (e) {
            this.error = e;
        }
        if (this.js === undefined) {
            const message: string = `Runtime error initializing custom function${this.name && `(${this.name})`}: ${this.error && this.error.message}`;
            this.js = () => {
                console.log(message, this);
                return message;
            };
        }
        try { this.js.customCode = this; } catch (e) {}
        return this.js;
    }
}

function customCode(conf: ICustomCodeOptions) {
    return new CustomCode(conf).computeJS();

}

export default customCode;
