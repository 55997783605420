import { pluralize, singularize } from "inflection";
import { omitBy } from "lodash";
// import { Model } from "./Model";
// import htmlString = JQuery.htmlString;
import { iconLookup, inlineLookup } from "./helpers";

interface ILookupSpec {
    type: "inline" | "values" | null;
    id?: string;
    source?: string;
    url?: string;
    name?: string;
    data_path?: string;
    options?: object;
}

interface INullLookup extends ILookupSpec {
    type: null;
}

interface ILegacyLookupConfig extends Partial<ILookupSpec> {
    inline?: string | boolean;
    values?: string | boolean;
    belongs_to?: string;
}

function isNullLookup(lookup: ILookupSpec | null): lookup is INullLookup {
    return !lookup || !lookup.type;
}

export function normalizeLookupSpec(old: ILegacyLookupConfig | string | boolean): ILookupSpec | null {
    if (old === true) {
        return {type: "inline"};
    }
    if (typeof old === "string") {
        return {type: "inline", source: old};
    }
    const type = old && (old.type || (old.inline ? "inline" : old.values ? "values" : null));
    if (!old || !type) {
        return {type: null};
    }

    let source: string | null | undefined = old.source;
    if (!source) {
        if (old.inline) {
            if (typeof old.inline === "string") {
                // If it has a scope already then don't try to pluralize it.
                if (/\./.test(old.inline)) {
                    source = old.inline;
                } else {
                    source = pluralize(old.inline);
                    if (singularize(source) !== old.inline) {
                        console.log("Inline may not be correctly configured. Should be singular: " + old.inline);
                    }
                }
            }
        } else {
            if (typeof old.values === "string") {
                source = old.values;
            }
        }
    }
    const name = old.name || old.belongs_to;
    return omitBy({
        type,
        source,
        name,
        options: old.options,
        data_path: old.data_path,
        url: old.url,
    }, (i) => i == null) as ILookupSpec;
}

export interface ILookupOptions {
    tooltip?: boolean;
    id?: string | number;
    include_text?: boolean | "never" | "always" | "empty";
    append_id?: boolean;
    replace_newlines?: boolean;
    short_text?: boolean;
    ignore_selected?: boolean;
}

const LookupOptionDefaults: Partial<ILookupOptions> = {
    tooltip: false,
    id: "id",
    include_text: true,
    append_id: false,
    replace_newlines: false,
    short_text: false,
};

export function instantiateLookupDefaults(lookupSpec: ILookupSpec, fieldName: string) {
    return new LookupConfig(lookupSpec, fieldName);
    // let {type, id, source, url, name, data_path, options} = lookupSpec;
    // let fieldMatch = fieldName.match(/^(.+)_(id|identifier)$/);
    // if (fieldMatch) {
    //     id = id || fieldMatch[2];
    //     name = name || fieldMatch[1];
    // } else {
    //     id = id || "id";
    //     name = name || fieldName;
    // }
    //
    // if (!source) {
    //    source = pluralize(name);
    // }
    //
    // if (!url) {
    //     url = `/${source}/lookup`;
    // }
    //
    // if (type === "inline" && !data_path) {
    //     data_path = `webfront_relations.${name}`;
    // }
    //
    // if (!options) {
    //     options = {};
    // }
    //
    // return {
    //     type, id, source, url, name, data_path, options,
    // };
}

export class LookupConfig implements ILookupSpec {
    public type: "inline" | "values" | null;
    public id: string;
    public source: string;
    public name: string;
    public url: string;
    public data_path: string;
    public options: ILookupOptions;

    constructor(spec: ILegacyLookupConfig, public fieldName: string) {
        const data = normalizeLookupSpec(spec) as ILookupSpec;
        Object.assign(this, data);
        this.instantiateDefaults();
    }

    private instantiateDefaults(): void {
        // let {type, id, source, url, name, data_path, options} = lookupSpec;
        const fieldMatch = this.fieldName.match(/^(.+)_(id|identifier)$/);
        if (fieldMatch) {
            if (!this.id) {
                this.id = fieldMatch[2];
            }
            if (!this.name) {
                this.name = fieldMatch[1];
            }
        } else {
            if (!this.id) {
                this.id = "id";
            }
            if (!this.name) {
                this.name = this.fieldName;
            }
        }

        if (this.name.includes(".")) {
            this.name = this.name.replace(/\W+/g, "__");
        }

        if (!this.source) {
            this.source = pluralize(this.name);
        }

        if (!this.url) {
            this.url = `/${this.source}/lookup`;
        }

        if (!this.data_path) {
            this.data_path = `webfront_relations.${this.name}`;
        }

        if (!this.options) {
            this.options = {};
        }
        if (!this.options.id && this.id) {
            this.options.id = this.id;
        }
    }

    public get valueFunction(): (record: object) => string {
        if (this.type === "inline") {
            return inlineLookup(this.data_path, _.cloneDeep(this.options));
        } else {
            return iconLookup(this.fieldName, this.source, _.cloneDeep(this.options));
        }
    }

    public get expandedValueFunction(): (record: object) => string {
        const expandedOptions = _.defaults({include_text: true}, this.options);

        if (this.type === "inline") {
            return inlineLookup(this.data_path, expandedOptions);
        } else {
            return iconLookup(this.fieldName, this.source, expandedOptions);
        }
    }
}
