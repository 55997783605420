export default function addGridTabs(gridSelf: any) {
    gridSelf.setupTab = function() {
        const $tabPane = $(gridSelf.gearsGrid.element).closest(".tab-pane");
        if ($tabPane.length) {
            const id = $tabPane.attr("id");
            const $tabA = $(`a[data-toggle=tab][href="#${id}"]`);
            return $tabA.on("shown.bs.tab", function(e) {
                return gridSelf.dataSource.fetch();
            });
        }
    };
}
