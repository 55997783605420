// Generated by CoffeeScript 1.10.0
(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  exports.Companies = (function() {
    function Companies() {
      this.initialize = bind(this.initialize, this);
      this.configs = bind(this.configs, this);
      this.configs();
    }

    Companies.prototype.configs = function() {
      this.companiesConfig = new Gears.GridConfig(Gears.Grids.Companies);
      this.tasksConfig = new Gears.GridConfig({
        has_saved_filters: false,
        parent_id_field: "company_id",
        data_filters: [
          {
            field: "task_status_id",
            values: function() {
              return {
                results: [
                  {
                    id: "all",
                    text: "All",
                  },
                ].concat(Gears.lookups.task_statuses),
              };
            },
            "default": "all",
          },
        ],
        table_container_dom_id: "#tasks-tab",
        has_modal: true,
        gears_model_name: "Task",
        grid: {
          scrollable: {
            virtual: true,
          },
          columns: [
            {
              field: "subject",
              title: "Subject",
            }, {
              field: "followup_date",
              title: "Follow-up",
              format: "{0: dd MMM yyyy}",
            }, {
              field: "contact_id",
              title: "Contact",
              lookup: {
                values: "contacts",
                options: {
                  include_text: true,
                },
              },
            },
          ],
        },
      }, this.companiesConfig);
      this.invoicesConfig = new Gears.GridConfig({
        has_saved_filters: true,
        has_modal: true,
        gears_model_name: "Invoice",
        table_name: "invoices",
        parent_id_field: "company_id",
        table_container_dom_id: "#invoices-tab",
        has_server_lookups: false,
        instance_name: "invoice",
        grid: {
          scrollable: {
            virtual: true,
          },
          columns: [
            {
              field: "doc_no",
              title: "Doc #",
            }, {
              field: "doc_str2",
              title: "Name",
            }, {
              field: "note",
              title: "Note",
            }, {
              field: "company_id",
              title: "Company",
              lookup: {
                values: "companies",
              },
            }, {
              field: "doc_status_id",
              title: "Status",
              lookup: {
                values: "invoice_statuses",
              },
              filterable: {
                cell: {
                  operator: "contains",
                },
              },
            }, {
              field: "contact_id",
              title: "Contact",
              headerTemplate: Gears.headerIconTemplate({
                text: "Contact",
                icon: "fa fa-user fa-lg",
              }),
              lookup: {
                values: "contacts",
                options: {
                  include_text: false,
                },
              },
              width: 55,
            },
          ],
        },
      }, this.companiesConfig);
      return this.contactsConfig = new Gears.GridConfig({
        has_saved_filters: true,
        has_modal: true,
        gears_model_name: "Contact",
        table_name: "people",
        parent_id_field: "company_id",
        table_container_dom_id: "#contacts-tab",
        instance_name: "person",
        grid: {
          columns: [
            {
              field: "first_name",
              title: "First Name",
            }, {
              field: "last_name",
              title: "Last Name",
              width: "300px",
            }, {
              field: "email",
              title: "Email",
            },
          ],
        },
      }, this.companiesConfig);
    };

    Companies.prototype.initialize = function() {
      var companiesGrid, contactsGrid;
      companiesGrid = Gears.parentGrid(this.companiesConfig);
      this.companiesGrid = companiesGrid;
      this.tasksGrid = Gears.childGrid(this.tasksConfig, this.companiesGrid);
      contactsGrid = Gears.childGrid(this.contactsConfig, this.companiesGrid);
      this.contactsGrid = contactsGrid;
      companiesGrid.addRecord = _.wrap(companiesGrid.addRecord, function(func) {
        func();
        return companiesGrid.selectedRecord.set("company_type_id", companiesGrid.config.data_filters[0].current);
      });
      companiesGrid.bind("change", function(e) {
        if (e.field === "selectedRecord") {
          return contactsGrid.set("isMyCompany", companiesGrid.get("selectedRecord.company_id") === 0);
        }
      });
      this.companiesGrid.initialize();
      this.tasksGrid.initialize();
      return this.contactsGrid.initialize();
    };

    return Companies;

  })();

}).call(this);
