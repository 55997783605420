import * as _ from "lodash";

function gearsInterceptDragAndDrop() {
    $(window).on("dragover", (e: any) => {
        e.preventDefault();
        e.originalEvent.dataTransfer.dropEffect = "copy";
        // console.log("Intercept DND", e);
        if ($(e.target).closest(".gears-drop-zone").length) {
            e.stopPropagation();
        }
    });
    return $(window).on("drop", (e) => {
        e.preventDefault();
        const $dZone = $(e.target).closest(".gears-drop-zone");
        const $kZone = $dZone.find(".k-dropzone em");
        // console.log("drop", e, $dZone, $kZone);
        if ($kZone.length) {
            e.stopPropagation();
            const event = jQuery.Event("drop");
            event.originalEvent = e.originalEvent;
            return $kZone.trigger(event);
        }
    });
}

export const interceptDragAndDrop = _.once(gearsInterceptDragAndDrop);
