import React from "react";
import PropTypes from "prop-types";

const defaultProps = {
    filledIcon: "",
    emptyIcon: "",
    editable: false,
    rating: 0,
    styleName: null,
};

const propTypes = {
    filledIcon: PropTypes.string,
    emptyIcon: PropTypes.string,
    editable: PropTypes.bool,
    rating: PropTypes.number,
};

/**
* A 5-star rating display
*/
class Rating extends React.Component {
    /**
    * @param {Object} [props]
    * @param {string} [props.filledIcon] - className when item is filled
    * @param {string} [props.emptyIcon] - className when item is empty
    * @param {boolean} props.editable=false - whether user can change rating
    * @param {number} props.rating=0 - initial rating
    * @param {function} [onChange] - Callback when rating changes
    */
    constructor(props) {
        super(props);
        /**
        * @access private
        */
        this.state = { rating: props.rating, defaultRating: props.rating };
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }
    /**
    * @access private
    */
    componentWillReceiveProps(nextProps) {
        this.setState({
            defaultRating: nextProps.rating,
            rating: nextProps.rating,
        });
    }

    /**
     * @access private
     */
    onMouseMove(e){
        const left = e.clientX - e.currentTarget.getBoundingClientRect().left;
        const width = e.currentTarget.getBoundingClientRect().width;
        const percent = left / width;
        var rating = Math.ceil(percent * 5);
        // Special case, set it to zero if too far left
        if (percent * 5 < 0.5) {
            rating = 0;
        }
        this.setState({ rating: rating });
    }

    /**
     * @access private
     */
    onMouseDown(e){
        const left = e.clientX - e.currentTarget.getBoundingClientRect().left;
        const width = e.currentTarget.getBoundingClientRect().width;
        const percent = left / width;
        var rating = Math.ceil(percent * 5);
        // Special case, set it to zero if too far left
        if (percent * 5 < 0.5) {
            rating = 0;
        }
        this.setState({ defaultRating: rating });
        if (this.props.onChange) {
            this.props.onChange(rating);
        }
    }

    /**
     * @access private
     */
    onMouseLeave(e){
        this.setState({ rating: this.state.defaultRating });
    }
    /**
    * render
    */
    render() {
        var stars = [];
        const activeClass = `${this.props.filledIcon} rating-full`;
        const inactiveClass = `${this.props.emptyIcon} rating-empty`;
        const className = `${this.props.className} rating form`;
        for (var i = 0; i < 5; i++) {
            const active = i < this.state.rating;
            stars.push(
                <li key={i}
                    className={active ? activeClass : inactiveClass}
                />,
            );
        }
        return (
            <ul className={className}
                onMouseDown={this.props.editable && this.onMouseDown }
                onMouseMove={this.props.editable && this.onMouseMove }
                onMouseLeave={this.props.editable && this.onMouseLeave }
            >
                {stars}
            </ul>
        );
    }
}

Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;
export default Rating;
