// Generated by CoffeeScript 1.10.0
(function() {
  var bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

  exports.SavedFilters = (function() {
    function SavedFilters() {
      this.initialize = bind(this.initialize, this);
      this.configs = bind(this.configs, this);
      this.configs();
    }

    SavedFilters.prototype.configs = function() {
      return this.savedFiltersConfig = new Gears.GridConfig(Gears.Grids.SavedFilters);
    };

    SavedFilters.prototype.initialize = function() {
      var savedFiltersGrid, that;
      that = this;
      this.savedFiltersGrid = Gears.parentGrid(this.savedFiltersConfig);
      savedFiltersGrid = this.savedFiltersGrid;
      return this.savedFiltersGrid.initialize();
    };

    return SavedFilters;

  })();

}).call(this);
