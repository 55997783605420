import {notification, INotificationOptions} from "./Notification";

declare const Gears: any;
declare const global: any;

interface IError {
    message?: string;
    title?: string;
    name?: string;
    xhr: IErrorXHR;
}

interface IErrorXHR {
    error?: string;
    message?: string;
    content?: string;
    errors?: string[];
}

type Error =  IError | string;

export function handleError(error: any){
    let ref$, ref1$;
    console.log("Handling Error:");
    console.log(error);
    if (typeof console.trace === "function") {
        console.trace();
    }
    // window.e = error;
    switch (false) {
        case typeof error !== "string":
            return showError({
                title: error,
                message: error,
            });
        case !(error.message && (error.title || error.name)):
            error.title || (error.title = error.name);
            return showError(error);
        default:
            if (error.xhr != null) {
                error = error.xhr;
            }
            if (error.responseText != null) {
                error = error.responseJSON || {
                    error: error.responseText,
                };
            }
            error.error = error.error || error.errors;
            if (_.isArrayLikeObject(error.error)) {
                error.error = error.error.join("\n");
            }
            error.message = error.error || error.message || error.content;
            // console.log(error);
            error.title || (error.title = error.name);
            error.title || (error.title = (ref$ = error.error.match(/>([^>]*ERROR[^<]*)/i)) != null ? ref$[1] : void 8);
            error.title || (error.title = (ref1$ = error.error.match(/ERROR[^\n]*/i)) != null ? ref1$[0] : void 8);
            // console.log(error);
            return showError(error);
    }
}
// /**
//  *
//  * @param error
//  */
// export function handleKendoDataSourceError(error: kendo.data.DataSourceErrorEvent) : void {
//     let xhrError: JQueryXHR | null = error.xhr;
//     let title: error.error;
//
// }

export function handleJQueryXHRError(xhrError: JQueryXHR): void {
    xhrError = xhrError || (xhrError.responseText ? xhrError.responseJSON || { error: xhrError.responseText } : null);
    xhrError.error = xhrError.error || xhrError.errors;
    xhrError.error = Array.isArray(xhrError) ? xhrError.error.join("\n") : error.error;
    xhrError.message = xhrError.message || xhrError.content || xhrError.error || "";
    // console.log(xhrError);
    xhrError.title = xhrError.title || xhrError.name;
    xhrError.title = xhrError.title || xhrError.error.match(/>([^>]*ERROR[^<]*)/i)[1];
    xhrError.title = xhrError.title || xhrError.error.match(/ERROR[^\n]*/i)[0];
    // console.log(xhrError);
    showError(xhrError);
}

interface IErrorMessage {
    message?: string;
    title?: string;
    color?: string;
    type?: string;
    icon?: any;
    timeout?: number;
    query_log_id?: any;
    classNames?: string;
    contentFormat?: INotificationOptions;
}

export function showError({message, title = "Error", color = "#C79121", type = "info", icon, timeout, query_log_id, classNames, contentFormat}: IErrorMessage): void {
    if (query_log_id) {
        title = `${title} <a class='query-log-id' data-query-log-id='${query_log_id}'>#${query_log_id}</a>`;
    }
    notification({
        title,
        content: message || "",
        color,
        timeout,
        messageType: type,
        contentFormat,
        icon,
        classNames,
        width: "wp-75p wp-800",
        align: "left",
    });
}

export function customFunctionError(code: string) {
    return notification({
        title: "Runtime Error In Custom Function",
        content: `<code>${code}</code>`,
        timeout: 10000,
    });
}

export function tryCustomFunction(func: Function) {
    console.log("Try Custom Function", func);
    try {
        func();
    }
    catch (e) {
        customFunctionError(func.toSource());
    }
}
