import React from "react";
import PropTypes from "prop-types";
import DiffChanges from "../../react-components/DiffChanges";
import Tabs from "../../react-components/react-tabs";
import {parse} from "jju/lib/parse";
// import {stringify} from "jju/lib/stringify";

// (global).JSON5 = {parse,stringify};

function _someSuperDangerousHTML(html) {
    return { __html: html };
}

class DiffValuesContainer extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <i className="fa fa-lg fa-times txt-color-red" /> Old Value
                    <pre>
                        <span dangerouslySetInnerHTML={_someSuperDangerousHTML(this.props.inputA)}></span>
                    </pre>
                </div>
                <div>
                    <i className="fa fa-lg fa-check txt-color-green" /> New Value
                    <pre>
                        <span dangerouslySetInnerHTML={_someSuperDangerousHTML(this.props.inputB)}></span>
                    </pre>
                </div>
            </div>
        );
    }
}

class DiffChangesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { displayType: props.displayType || bold };
    }
    _changeDisplay(object, e) {
        object.setState({ displayType: e.target.value });
    }
    render() {
        // var currentObject = gears.changelogchangesGrid.selectedRecord
        return (
            <div>
                <div className="full-width">
                    <i className="fa fa-lg fa-check txt-color-green" /> Changed Values
                    <select defaultValue={this.props.displayType} onChange={(event) => this._changeDisplay(this, event)} className="diff-select-box">
                        <option value="bold">Bold New</option>
                        <option value="removed">Show Old / New</option>
                    </select>
                    <div className="full-width">
                        <DiffChanges inputA={this.props.inputA} inputB={this.props.inputB} type={this.props.type} displayType={this.state.displayType} />
                    </div>
                </div>
            </div>
        );
    }
}

function parseValue(value) {
    if (value == null || value === "null" || value == "") {
        return "";
    }
    let object;
    try {
        return JSON5.stringify(JSON5.parse(value));
    } catch (e) {
        return value;
    }
}

export default class ChangeLogChangeDiff extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tab: props.tab || 0 };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            tab: nextProps.tab,
        });
    }

    changeTab = (tab) => {
        this.setState({ tab: tab });
    }

    render() {
        const changeLogChange = this.props.changeLogChange;
        if (!changeLogChange || !changeLogChange.new_value_value) { return null; }
        const inputA = changeLogChange.old_value_value() + "";
        const inputB = changeLogChange.new_value_value() + "";
        // const inputAF = parseValue(changeLogChange.old_value) + '';
        // const inputBF = parseValue(changeLogChange.new_value) + '';

        const tabs = [
            {
                title: "Values",
                content: (<DiffValuesContainer inputA={inputA} inputB={inputB} />),
            },
        ];
        const maxLength = 4000;
        if (inputA.length < maxLength && inputB.length < maxLength) {
            tabs.unshift({
                title: "Changes",
                    content: (<DiffChangesContainer displayType={this.props.displayType} inputA={inputA} inputB={inputB} type={this.props.type} />),
            });
        }
        // const newJson = JSON.parse(changeLogChange.new_value) || {};
        const oldJson = parse(changeLogChange.new_value) || {};
        if (typeof (oldJson) === "object") {
            tabs.push({
                title: "Raw",
                content: (<DiffValuesContainer inputA={changeLogChange.old_raw} inputB={changeLogChange.new_raw} />),
            });
        }
        return (<Tabs defaultTab={0} tabData={tabs} />);
    }

    static defaultProps = {
        changeLogChange: null,
        type: "chars",
        tab: 0,
        displayType: "bold",
    };

    static propTypes = {
        changeLogChange: PropTypes.object,
        type: PropTypes.oneOf([
            "chars",
            "words",
            "sentences",
            "json",
        ]),
        tab: PropTypes.number,
        displayType: PropTypes.string,
    };
}
