import * as inflection from "inflection";
import deprecated from "deprecated-decorator";

export const {pluralize, singularize, inflect,camelize,underscore,humanize, capitalize, dasherize,titleize,demodulize,tableize,classify,foreign_key,ordinalize,transform} = inflection;

//Backwards compatibility for client DB code.

interface IInflectionString extends String {
    pluralize(plural?: string): string;

    singularize(singular?: string): string;

    inflect(count: number, singular?: string, plural?: string): string;

    camelize(low_first_letter?: boolean): string;

    underscore(all_upper_case?: boolean): string;

    humanize(low_first_letter?: boolean): string;

    capitalize(): string;

    dasherize(): string;

    titleize(): string;

    demodulize(): string;

    tableize(): string;

    classify(): string;

    foreign_key(drop_id_ubar?: boolean): string;

    ordinalize(): string;

    transform(arr: string[]): string;
}


const stringPrototype: Partial<IInflectionString> = String.prototype;

function defunctionalize(functional: (str: string) => string): (this: string) => string;
function defunctionalize(functional: (str: string, bool: boolean) => string): (this: string, bool: boolean) => string;
function defunctionalize(functional: (str: string, extra: string) => string): (this: string, extra: string) => string;

function defunctionalize(functional: (...args: any[]) => string): (this: string, ...args: any[]) => string {
    switch (functional.length) {
        case 1:
            return function(this: string) {
                return functional(this);
            };
        case 2:
            return function(this: string, extra: any) {
                return functional(this, extra);
            };
        default:
            return function(this: string, ...args: any[]) {
                return functional.apply(null, [this].concat(args));
            }
    }
}

if (!stringPrototype.pluralize) {
    stringPrototype.pluralize = defunctionalize(inflection.pluralize);
}

_.each(inflection, (value: Function, key: string) => {
    if (key !== "indexOf" && !(stringPrototype as any)[key]) {
        (stringPrototype as any)[key] = defunctionalize(deprecated(`Gears.${key}(str)`, value as any));
    }
});

// if (!stringPrototype.pluralize) {
//     stringPrototype.pluralize = defunctionalize(inflection.pluralize);
// }
