// Generated by CoffeeScript 1.10.0
(function() {
  exports.ListItem = {
    id: "id",
    hasChildren: false,
    iconTemplate: function(options) {
      if (options == null) {
        options = {
          include_text: false,
        };
      }
      return Gears.formatLookup(this.as_lookup(), options);
    },
    initials: function() {
      return this.get("webfront_initials");
    },
    as_lookup: function() {
      return {
        text: this.get("item"),
        icon: this.get("icon"),
        initials: this.initials(),
        initials_class: this.get("webfront_initials_class"),
      };
    },
    listUrl: function() {
      var ref, ref1;
      if (((ref = gears.gearsListGrid) != null ? (ref1 = ref.selectedRecord) != null ? ref1.list_name : void 0 : void 0) === "Location Sub Type") {
        return "/lookup/19?parent_items='only'";
      } else {
        return "/lookup/" + (this.get("gears_list_identifier")) + "?parent_items='only'";
      }
    },
    badgeIcon: function() {
      if (this.get("gears_list_id") === 24) {
        return true;
      }
    },
    noBadgeIcon: function() {
      if (this.get("gears_list_id") !== 24) {
        return true;
      }
    },
    fields: {
      id: {
        type: "number",
        editable: false,
        nullable: true,
      },
      gears_list_id: {
        type: "number",
      },
      order_field: {
        type: "number",
        defaultValue: 0,
      },
      parent_list_item_id: {
        type: "number",
        defaultValue: null,
      },
      icon: {},
      item: {},
    },
  };

}).call(this);
