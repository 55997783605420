import { diffChars, diffJson, diffSentences, diffWords } from "diff";
import IDiffResult = JsDiff.IDiffResult;
import * as _ from "lodash";
import * as React from "react";

const fnMap: {[key: string]: (a: string, b: string) => JsDiff.IDiffResult[]} = {
    chars: diffChars,
    words: diffWords,
    sentences: diffSentences,
    json: diffJson,
};

interface IDiffChangesPropTypes {
    inputA: string | number;
    inputB: string | number;
    type: "chars" | "words" | "sentences" | "json";
    displayType: "bold" | "removed";
}

function boldDiff(diff: JsDiff.IDiffResult[]) {
    return _(diff).reject("removed").map((part, index) => {
        const spanClassName = part.added ? "diff-bold" : "diff-unbold";
        return <span key={index} className={spanClassName}>{part.value}</span>;
    }).value();
}

function removedDiff(diff: JsDiff.IDiffResult[]) {
    return diff.map((part, index) => {
        const spanClassName = part.added ? "diff-added" : part.removed ? "diff-removed" : "diff-unchanged";
        return <span key={index} className={spanClassName}>{part.value}</span>;
    });
}

export default class DiffChanges extends React.Component<IDiffChangesPropTypes, never> {
    public render() {
        return this.renderDiffItems(this.calcDiff());
    }
    private calcDiff(): JsDiff.IDiffResult[] {
        const {type, inputA, inputB} = this.props;
        return fnMap[type](inputA + "", inputB + "") as JsDiff.IDiffResult[];
    }
    private renderDiffItems(diff: JsDiff.IDiffResult[]) {
        const result = (this.props.displayType === "bold") ? boldDiff(diff) : removedDiff(diff);
        return <pre className="diff-result"> {result} </pre>;
    }

    public static defaultProps: IDiffChangesPropTypes = {
        inputA: "",
        inputB: "",
        type: "chars",
        displayType: "bold",
    };
}
