declare const require: any;

import * as Compatibility from "./Compatibility";
import * as gearsFilter from "./grid/GridFilter";
import GridConfig from "./GridConfig";
export * from "./helpers";
import Initializers from "./initializers";

export * from "./GridController";
export { kendoDataSource } from "./kendo_data_source";

import customCode from "./CustomCode";
export * from "./Errors";
import GearsDataSource from "./GearsDataSource";
import gearsState from "./GearsState";
import GridView from "./GridView";
import "./Locale";
import { Model } from "./Model";
import { pdfEmailer } from "./pdf_emailer";

import * as _ from "lodash";

declare const global: any;

global.gearsFunction = customCode;

const initialize = _.once(function _initialize() {
    _.each(Initializers, (value: () => void, key: string) => {
        try {
            value();
        } catch (e) {
            console.log("Error Initialising Function", key, value, e);
        }
    });
});

export function ready() {
    if (!global.gearsState) {global.gearsState = gearsState; }
    this.initialize();
    return this.useAllAutoConcerns();
}

export {
    initialize,
    Initializers,
    gearsFilter,
    GridConfig,
    pdfEmailer,
    GridView,
    Model,
    GearsDataSource,
    Compatibility,
};
