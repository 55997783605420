import * as JSON5 from "json5";
import * as _ from "lodash";
import * as memoizee from "memoizee";
import gearsState from "../GearsState";
import { ILookup } from "../GearsState/Lookups";
import { bgFromClass, colorFromClass } from "./Colors";
import { addFaExtras, iconHtml } from "./fa";
import favAwesome from "../../extensions/FaviconAwesome";
import initials from "./initials";

declare var Gears: any;
declare var global: any;
declare var Select2: any;

function numericNoSpinners(container, options) {
    $(`<input maxlength="5" name="{options.field}"/>"`).appendTo(container).kendoNumericTextBox({
        min: 0,
        format: "#",
        decimals: 0,
        spinners: false
    });
}

function gridSelect2(container, options) {
    $(`<input  class='select2' value="${options.model[options.field]}" style='width: 100%' name="${options.field}"/>`)
        .appendTo(container)
        .select2({
            formatSelection: formatLookup,
            formatResult: formatLookup,
            data: {
                results: options.values,
                id: options
            }
        });
}

const HexReg = new RegExp("\\b(background|text||bg|txt)-hex-([0-9a-f]+)\\b", "ig");

interface IHexMap {
    bg: "background-color";
    text: "color";
    background: "background-color";
    txt: "color";
}

const hex_map: IHexMap = {
    bg: "background-color",
    text: "color",
    background: "background-color",
    txt: "color"
};

export interface IFormatLookupOptions {
    append_id?: string | number | false;
    include_text?: "empty" | "never" | "always" | "fullscreen" | boolean;
    replace_newlines?: boolean;
    html?: boolean;
    tooltip?: boolean | string;
    short_text?: string | boolean;
}

function formatLookup(value: ILookup, options: Readonly<IFormatLookupOptions> = {}) {
    let icon, text, style, full_text, basic_display, hover_title;
    let include_text = options.include_text == null ? true : options.include_text;
    const append_id = !!options.append_id;
    const replace_newlines = !!options.replace_newlines;
    const tooltip = options.tooltip == null ? false : options.tooltip;
    const short_text = options.short_text == null ? false : options.short_text;

    if (value == null) {
        return "";
    }
    icon = value.icon;
    text = (short_text && value.short_text) || value.text;
    switch (include_text) {
        case null:
        case "empty":
            include_text = !(icon || value.initials);
            break;
        case "never":
            include_text = false;
            break;
        case "always":
            include_text = true;
    }
    if (icon != null) {
        style = "";
        icon = icon.replace(HexReg, function (i: string, attrStr: keyof IHexMap, value: string) {
            let style;
            style = style + hex_map[attrStr] + ": #" + value + ";";
            return "";
        });
        if (style) {
            style = "style=\"" + style + "\"";
        }
        if (icon.match(/badge/)) {
            if (!value.initials) {
                value.initials = initials(text);
            }
            if (!value.initials_class) {
                value.initials_class = icon;
            }
            include_text = false;
            icon = null;
        }
    }
    if (replace_newlines) {
        text = text.replace(/\n/g, "<br/>");
    }
    if (include_text) {
        if (include_text === "fullscreen") {
            full_text = "<span class='icon-fulltext fullscreen-only'>  " + text + "</span>";
        } else {
            full_text = "  " + text;
        }
    } else {
        full_text = "";
    }
    if (append_id) {
        text += " (" + value.id + ")";
    }
    basic_display = (function () {
        switch (false) {
            case !value.image:
                return "<img src='" + value.image + "' width='50' height='50'/>" + full_text;
            case !icon:
                return `${iconHtml(addFaExtras(icon))}${full_text}`;
            case !value.initials:
                return `<span class='badge initials ${value.initials_class} ${style}'>${value.initials}</span>${full_text}`;
            default:
                return full_text;
        }
    })();
    if (!tooltip) {
        return basic_display;
    }
    switch (false) {
        case !icon:
            hover_title = "";
            if (value.message) {
                hover_title = text + "<br/><i class=&#39;" + icon + "&#39;></i> " + value.message;
            } else {
                hover_title = text;
            }
            return `<span data-toggle='tooltip' data-placement='top' data-html='true' title="${hover_title}" >${basic_display}</span>`;
        case !value.initials:
            return `<span data-toggle='tooltip' data-placement='top' data-html='true' title="${text}">${basic_display}</span>`;
        case !!full_text.match(/tooltip/):
            return `<span data-toggle='tooltip' data-placement='top' data-html='true' title='${text}'>${full_text}</span>`;
        default:
            return full_text;
    }
}

function alertLookupTemplate(options = {}) {
    let gears_classes, ref$;
    options.text = options.text || "";
    gears_classes = (ref$ = options.icon) != null ? ref$.match(/gears-([^\s]*)/g) : void 8;
    if (gears_classes == null) {
        return null;
    }
    return `
    <div class='alert ${gears_classes.join(" ")}'>
        ${iconHtml(addFaExtras(options.icon))}
        ${options.text}
    </div>`;
}

export function columnPopoverTemplate(model, column) {
    const field = model.fields[column.field] || {};
    const title = column.title || (field.help && field.help.title) || field.label || column.field.titleize();
    const icon = addFaExtras(column.icon || field.icon);
    const include_text =
        field.include_text != null ? field.include_text : !(field.icon && column.width && column.width < 100);
    const content = (field.help && field.help.content) || field.help;
    const placement = column.placement ? "left" : undefined;
    const conf = { title, icon, include_text, content, placement };
    return headerPopoverTemplate(conf);
}

/**
 * TODO: clean this up
 */
export function headerPopoverTemplate(arg$: any) {
    // let title, icon, content, delay, ref$, placement, include_text, trigger, full_text, icon_html;
    let { title, icon, content, delay, placement, include_text, trigger } = arg$;
    if (delay == null) {
        delay = {
            show: 600,
            hide: 1000
        };
    }
    if (placement == null) {
        placement = "top";
    }
    if (include_text == null) {
        include_text = false;
    }
    if (trigger == null) {
        trigger = "hover";
    }

    let full_text;
    if (include_text || !icon) {
        if (include_text === "fullscreen") {
            full_text = "<span class='icon-fulltext fullscreen-only'>  " + title + "</span>";
        } else {
            full_text = "  " + title;
        }
    } else {
        full_text = "";
    }
    const icon_html = icon ? iconHtml(addFaExtras(icon), "'") : "";
    if (icon_html) {
        title = icon_html + " " + title;
    }
    if (!(icon || content)) {
        return "<span>" + title + "</span>";
    }
    if (title && !content) {
        content = title;
        title = "";
    }
    const headerPop = (
        `<span data-rel='popover'
               data-placement='${placement}'
               data-delay-hide='${(delay != null ? delay.hide : void 8)}'
               data-delay-show='${(delay != null ? delay.show : void 8)}'
               data-width='200'
               data-trigger='${trigger}'
               data-title="${title}"
               data-content="${content}"
               data-html='true'>
            ${icon_html}${full_text}
        </span>`);
    return headerPop;
}

interface IFormatLookupHeaderOptions extends IFormatLookupOptions, Partial<ILookup> {
    short_text?: any;
}

function headerIconTemplate(options: Readonly<IFormatLookupHeaderOptions>) {
    let full_text;
    options == null && (options = {});
    const include_text = options.include_text == null ? false : options.include_text;
    const text = options.text || "";
    const { icon, initials } = options;
    if (include_text || !(icon || initials)) {
        if (include_text === "fullscreen") {
            full_text = `<span class='icon-fulltext fullscreen-only'>${text}</span>`;
        } else {
            full_text = "  " + text;
        }
    } else {
        full_text = "";
    }
    if (icon) {
        return `<span data-toggle='tooltip' title='${text}'>${iconHtml(icon)} ${full_text}</span>`;
    } else {
        return full_text;
    }
}

function headerIconLookup(lookup_name: string, id: string | number, options: Readonly<IFormatLookupHeaderOptions>) {
    options == null && (options = {});
    return function () {
        let value;
        value = _.find(gearsState.lookups.lists.get(lookup_name), {
            id
        });
        if (value) {
            return headerIconTemplate(_.merge({}, options, value));
        } else {
            return (
                "<span class='lookup-replace header-icon-lookup' data-lookup='" +
                lookup_name +
                "' data-id='" +
                id +
                "'/>"
            );
        }
    };
}

function replaceLookupHeaders() {
    return $(".lookup-replace.header-icon-lookup").each(function () {
        let span, lookup_name, id, value;
        span = $(this);
        lookup_name = span.attr("data-lookup");
        id = parseInt(span.attr("data-id"));
        value = _.find(gearsState.lookups.lists.get(lookup_name), {
            id
        });
        return span.replaceWith(headerIconTemplate(value));
    });
}

function iconLookup(fieldName: string, lookupName: string, options) {
    options == null && (options = {});
    return function iconLookupGenerated(): string {
        let value;
        const id = _.get(this, fieldName) as string | number;
        options.id == null && (options.id = "id");
        value = _.find(gearsState.lookups.lists.get(lookupName), function flexibleCompare(it): boolean {
            // tslint:disable-next-line:triple-equals
            return id == it.id || (it.identifier !== undefined && id == it.identifier);
        });
        if (!value) {
            value = {
                id: null,
                icon: "icon-blank"
            };
        }
        value.text == null && (value.text = "&nbsp;&nbsp;&nbsp;&nbsp;");
        return formatLookup(value, options);
    };
}

export function fetchLookup(rec, field_name: string, lookup_name: string) {
    const id = _.get(rec, field_name);
    return _.find(gearsState.lookups.lists.get(lookup_name), function (it) {
        return id === it.id;
    });
}

function inlineLookup(dataPath, options) {
    options == null && (options = {});
    return function () {
        let value;
        value = this.get(dataPath);
        if (value && value.text) {
            return formatLookup(value, options);
        } else {
            return "";
        }
    };
}

function inlineRelationAccessor(relation_name: string, field_name: string | null) {
    field_name == null && (field_name = relation_name + "_id");
    return function (new_value?: string) {
        switch (new_value) {
            case undefined:
                return this.webfront_relations[relation_name];
            default:
                this.webfront_relations[relation_name] = new_value;
                this.set("field_name", new_value.id);
                return null;
        }
    };
}

function firstLineTooltip(fullstring: string) {
    let lines;
    if (!fullstring) {
        return "";
    }
    lines = fullstring.split("\n");
    return Gears.firstLineTemplate(lines);
}

export { firstLineTooltip as firstLinePopover };

function concatFields(model, fields) {
    return _(fields)
        .map(function (i: string) {
            return model[i];
        })
        .join();
}

function concat_if_array(a: any[], b: any[]) {
    if (_.isArray(a)) {
        return a.concat(b);
    }
}

function tooltip(text: string, tooltip: string) {
    tooltip = tooltip ? tooltip : text;
    return `<span data-toggle="tooltip" title="${tooltip}">${text}</span>`;
}

export function groupHeaderCount(it) {
    let total, ref$, totalText;
    total = (ref$ = it.aggregates) != null ? ref$.total_entries : void 8;
    return (totalText = total != null ? " <span class='group-count'>(" + total + ")</span>" : "");
}

export function numberTemplate(num: number | null, options?: { style?: string, format?: string }): string {
    if (num == null) {
        return "";
    }
    const style = _.get(options, "style", "plain-number") as string;
    const format = _.get(options, "format", "n0") as string;
    const numString = kendo.toString(num, format);
    return "<span class=\"" + style + "\" data-toggle=\"tooltip\" title=\"" + numString + "\">" + numString + "</span>";
}

export function highlightNumber(num: number | null, arg?: { format?: string }): string {
    const format = _.get(arg, "format", "n0") as string;
    return numberTemplate(num, {
        style: "highlight-number",
        format
    });
}



function onMobile() {
    return global.onMobile;
}

function define_inline_property(model, property_name: string, id_field) {
    id_field == null && (id_field = property_name + "_id");
    return Object.defineProperty(
        model,
        property_name,
        {
            get() {
                let ref$;
                return (ref$ = this.webfront_relations) != null ? ref$[property_name] : void 8;
            },
            set(arg) {
                if (typeof arg === "object") {
                    this.service_tech_id = arg != null ? arg.id : void 8;
                    return (this.webfront_relations.service_tech = arg);
                } else {
                    return (this.service_tech_id = parseInt(arg));
                }
            }
        },
        {
            enumerable: false
        }
    );
}

function define_inline_properties(model, property_names: string[]) {
    return _.each(property_names, function (pn) {
        return define_inline_property(model, pn);
    });
}

type ClassType = "complete" | "cancelled" | "warning";

const Classes = {
    complete: "gears-completed",
    cancelled: "gears-cancelled",
    warning: "gears-warning"
};

function identifierClass(identifier: ClassType) {
    return Classes[identifier];
}

export function findLookupId(lookupName: string, identifier: string): string | number {
    const result = _.find(
        gearsState.lookups.lists.get(lookupName),
        (it) => it.identifier.indexOf(identifier) >= 0);
    return result && result.id;
}

const defaultId = memoizee(
    function (lookupName: string) {
        let ref$;
        return (ref$ = _.find(gearsState.lookups.lists.get(lookupName), function (it) {
            let ref$;
            return it != null ? ((ref$ = it.options) != null ? ref$.default : void 8) : void 8;
        })) != null
            ? ref$.id
            : void 8;
    },
    {
        primitive: true
    }
);

export { defaultId };

const lookupId = memoizee(findLookupId, {
    primitive: true
});

export { lookupId };

export function openFieldLink(context: string) {
    let data;
    data = $(context).data();
    return window.open(data.url + data.fieldValue, "_blank");
}

export function breakNewlines(str: string) {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
}

export function configDefault(value, defaultValue: any) {
    switch (value) {
        case "false":
            return false;
        case null:
        case undefined:
            return defaultValue;
        default:
            return value;
    }
}

global.configDefault = configDefault;

export function defaultTrue(value) {
    return configDefault(value, true);
}

export function defaultFalse(value) {
    return configDefault(value, false);
}

global.defaultTrue = defaultTrue;
global.defaultFalse = defaultFalse;

export function objectFormat(o: any, highlight?: boolean, pretty?: boolean) {
    const raw = JSON5.stringify(o, null, pretty ? "  " : null);

    return highlight ? Gears.highlight(raw, "javascript") : raw;
}

export function smartFormat(value: string | object | null) {
    let object: object;
    if (value === "null" || value === "" || value == null) {
        return "";
    }
    if (typeof value === "string") {
        try {
            object = JSON5.parse(value);
        } catch (e) {
            return value;
        }
    } else {
        object = value;
    }
    try {
        if (object == null || typeof object !== "object") {
            return object;
        }
        if (object.text != null || object.icon != null) {
            return formatLookup(object);
        }
        // return Select2.util.escapeMarkup(objectFormat(object));
        return Gears.highlight(objectFormat(object));
    } catch (e) {
        console.log("Error formatting value:", value, object);
        return "Error";
    }
}

const ensureAllTabsVisible = _.debounce(() => {
    _($("ul.nav-tabs:not(:has(> li.active > a:visible))"))
        .map((it) => $(it).find("a:visible")[0])
        .filter()
        .each((it) => $(it).tab("show"));
}, 10);

export function setGearsFavicon(icon: string): void {
    const color = colorFromClass(icon);
    // const bg = bgFromClass(icon);
    return favAwesome(icon, color);
}

export function uniqueIdString(): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
}

export function getUniqueElementClass(className: string): string {
    const elementToReserve = $(`${className}:not(._uniqueId)`).first();
    if (elementToReserve.length === 0) {
        console.log(`Error finding element: ${className}`);
        return 'no-element-found';
    }
    const uniqueClass = uniqueIdString();
    elementToReserve.addClass(['_uniqueId', uniqueClass]);
    return uniqueClass;
}

export {
    ensureAllTabsVisible,
    identifierClass,
    define_inline_properties,
    define_inline_property,
    onMobile,
    tooltip,
    concat_if_array,
    firstLineTooltip,
    inlineRelationAccessor,
    gridSelect2,
    numericNoSpinners,
    formatLookup,
    headerIconTemplate,
    alertLookupTemplate,
    headerIconLookup,
    replaceLookupHeaders,
    iconLookup,
    inlineLookup,
    concatFields
};
