/// <reference path="../global.d.ts" />
import * as Promise from "bluebird";
import * as JSON5 from "json5";
import * as _ from "lodash";
import "material-design-lite";
import * as timer from "metrics-timer";
import * as mobx from "mobx";
import * as moment from "moment-timezone";
import * as numeral from "numeral";
import * as React from "react";
import * as ReactDOM from "react-dom";
import {ActionCable, default as cable} from "../gears/actioncable";
import * as tryCatch from "../gears/helpers/tryCatch";
import {IAceWithHightlight} from "./ace";
import "./browser";
import "./kendo";
import {Loader, memoizedLoader} from "./Loader";
// import '../../vendor/assets/font-awesome/svg-with-js/js/fontawesome.js';
// import '../../vendor/assets/font-awesome/svg-with-js/js/fa-v4-shims.js';
// import '../../vendor/assets/font-awesome/svg-with-js/js/f';
// declare const FontAwesome: any;
// import solid from '@fortawesome/fontawesome-pro-solid';
// import light from '@fortawesome/fontawesome-pro-light';

// (global as any).ace = ace;
// console.log("ACE LOADING");

// declare var require: {
//     <T>(path: string): T;
//     (paths: string[], callback: (...modules: any[]) => void): void;
//     ensure: (paths: string[], callback: (require: <T>(path: string) => T) => void) => void;
// };
(global as any).Promise = Promise;
if (process.env.NODE_ENV !== "production") {
    if (Promise.longStackTraces) {
        Promise.longStackTraces();
    }
}

(global as any)._ = _;
(global as any).JSON5 = JSON5;
(global as any).mobx = mobx;
(global as any).moment = moment;
(global as any).Moment = moment;
(global as any).React = React;
(global as any).ReactDOM = ReactDOM;
(global as any).tryCatch = tryCatch;
(global as any).timer = timer;
(global as any).numeral = numeral;
(global as any).ActionCable = ActionCable;
(global as any).cable = cable;
// (global as any).fontawesome = fontawesome;
// (global as any).fontawesomeSolid = solid;
// (global as any).fontawesomeLight = light;

interface ILazyLoaderState {
    loaded?: any;
}

class PieChart extends React.Component<any, ILazyLoaderState> {
    constructor(props: any) {
        super(props);

        global.vendor.d3charts.then((loadedModule: any) => {
            console.log(loadedModule);
            this.setState({loaded: loadedModule.default});
        });

        this.state = {loaded: undefined};
    }

    public render() {
        if (!this.state.loaded) {
            return null;
        } else {
            return React.createElement(this.state.loaded, {...this.props});
        }
    }
}

(global as any).PieChart = PieChart;

export class VendorLoader extends Loader {
    private coreModules: string[] = ["kendo"];

    constructor(loadTarget?: any, preload?: string[]) {
        super(loadTarget, preload);
    }

    // @memoizedLoader
    // get domToImage(): Promise<any> {
    //     return Promise.resolve(import(/* webpackChunkName: "html2canvas" */ "dom-to-image")).get<any>("default");
    // }

    @memoizedLoader
    get ace(): Promise<IAceWithHightlight> {
        return Promise.resolve(import(/* webpackChunkName: "ace" */ "./ace")).get<IAceWithHightlight>("default");
    }

    @memoizedLoader
    get d3charts(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "d3charts" */ "./d3charts"));
        //return Promise.resolve({ PieChart: global.PieChart });
    }

    @memoizedLoader
    get browser(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "browser" */ "./browser"));
    }

    @memoizedLoader
    get timer(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "browser" */ "metrics-timer"));
    }

    @memoizedLoader
    get kendo(): Promise<any> {
        return this.browser.then(() => import(/* webpackChunkName: "kendo" */ "./kendo"));
    }

    @memoizedLoader
    get kendoDataviz(): Promise<any> {
        return this.kendo.then(() => import(/* webpackChunkName: "dataviz" */ "kendo-ui/js/kendo.dataviz"));
    }

    @memoizedLoader
    get compiler(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "core_compiler" */ "../compiler/Compiler"));
    }

    // @memoizedLoader
    // get jsZip(): Promise<any> {
    //     return System.import("jszip");
    // }

    @memoizedLoader
    get dolphin(): Promise<any> {
        return Promise.resolve(import(/* webpackChunkName: "dolphin" */ "../concerns/dolphin"));
    }

    @memoizedLoader
    get core(): Promise<any> {
        return this.loadModules(this.coreModules);
    }

    // @memoizedLoader
    // get highlightjs(): Promise<any> {
    //     return Promise.resolve(import(/* webpackChunkName: "highlightjs" */ "highlight.js"))
    //         .tap((h) => {
    //             console.log("highlight loaded", h);
    //             h.initHighlighting();
    //         });
    // }
}

export default new VendorLoader();
