/**
 * TODO: Setup proper options types
 * name is notification.ts to avoid breaking imports
 * and so switching back to livescript version can very fast
 */

import * as Bluebird from "bluebird";
import * as $ from "jquery";
import gearsDialog, {closeDialog, IDialogProps} from "../../react-components/kendo-dialog";
import {ConfigurationError, UserCancelled} from "../Errors";
import {beautify, isBeautifyFormat} from "../helpers/beautify";
import {highlight} from "./highlight";
import QuickGrid from "../../react-components/QuickGrid";

declare const Gears: any;

type DisplayType = "smallBox" | "extraSmallBox" | "bigBox" | "dialog" | "iframe" | "browser";

type MessageType = "info" | "warning" | "question" | "success" | "default" | null;

type ContentFormat = "code" | "sql" | "sqlError" | "js" | "javascript" | "json" | "grid" | null;

export interface INotificationOptions {
    animation?: string;
    buttons?: any[];
    kendo?: string;
    color?: string;
    content?: string;
    children?: any;
    html?: string;
    icon?: string;
    iconSmall?: string;
    timeout?: number;
    grid?: string;
    title?: string | null | undefined;
    displayTyped?: DisplayType;
    messageType?: MessageType;
    type?: MessageType;
    dialogStyle?: any;
    contentFormat?: ContentFormat;
    tag?: string;
}

interface IFrameINotificationOptions extends INotificationOptions {
    displayType: "iframe";
    url: string;
}

export function notification(options: INotificationOptions): Bluebird<{}> {
    console.log("Options: ", options);
    switch (options.displayType) {
        case "smallBox":
            return smartNotification(options);
        case "extraSmallBox":
            return smartNotification(options);
        case "bigBox":
            return smartNotification(options);
        case "browser":
            return browserNotification(options);
        default:
            options.buttons = options.buttons || ["Ok"];
            return dialog(options);
    }
}

function formatContent(content, options?: INotificationOptions): string | null {
    if (!options) {
        options = {};
    }
    if (!content && options.displayType !== "browser") {
        return "&nbsp;";
    }
    const longContent = typeof content !== "string" || content.length > 200;
    if (longContent) {
        options.width = options.width || "width-75p width-800px";
    }

    let format = options && options.contentFormat || "text";
    if (format === "raw") {
        return content;
    }

    if (format === "grid") {
        const grid = options.grid || {};
        options.children = <QuickGrid content={content} {...grid} />;
        options.width = "grid-dialog";
        return null;
    }

    let isCode = format === "code";
    if (isBeautifyFormat(format)) {
        content = beautify(content, format);
        isCode = true;
    }
    if (format === "sqlError") {
        isCode = true;
        format = "sql";
    }

    if (isCode) {
        return `<pre class="gears-code pre-wrap">${highlight(content, format)}</pre>`;
    } else {
        if (longContent) {
            return `<pre class="gears-code pre-wrap">${content}</pre>`;
        } else {
            return Gears.breakNewlines(content || "&nbsp;");
        }
    }
}

function formatTitle(title, options?: INotificationOptions): string {
    if (title && title.length > 200) {
        return _.truncate(title, 200);
    } else {
        return title;
    }
}

function notificationOptions(options: INotificationOptions | string): INotificationOptions {
    let actualOptions: INotificationOptions;
    if (typeof options === "string") {
        actualOptions = {content: options as string};
    } else {
        actualOptions = options;
    }
    if (!actualOptions.title && actualOptions !== null && actualOptions.displayType !== "browser") {
        actualOptions.title = "&nbsp;";
    }
    actualOptions.content = formatContent(actualOptions.content, actualOptions);
    actualOptions.title = formatTitle(actualOptions.title, actualOptions);

    return actualOptions;
}

interface INotificationDefaults {
    [key: string]: INotificationOptions;
}

const notificationDefaults: INotificationDefaults = {
    default: {
        animation: "fadeInRight animated",
        color: "#C79121",
        icon: "fa-shield",
    },
    info: {
        animation: "fadeInRight animated",
        color: "bg-color-blue bg-lighten-1",
        icon: "fa-thumbs-up",
    },
    question: {
        animation: "bounce animated",
        color: "bg-color-blue bg-lighten-1",
        icon: "fa-question",
    },
    success: {
        animation: "bounce animated",
        color: "#296191",
        icon: "fa-check",
    },
    warning: {
        animation: "fadeInLeft animated",
        color: "#C79121",
        icon: "fa-shield",
    },
};

export function browserNotification(options: INotificationOptions) {
    return Bluebird.try(() => Notification.requestPermission())
        .then((permission) => {
            if(permission !== "granted") {throw "Notification not supported";}
        }).catch(() => {
            options.displayType = 'smallBox';
            return smartNotification(options);
        }).then(() => showBrowserNotification(options));
}

function showBrowserNotification(options: INotificationOptions) {
    const processedOptions = notificationOptions(options);
    const {title, content, timeout, tag} = processedOptions;
    return new Bluebird((resolve, reject) => {
        const n = new Notification(title as string, {body: content, tag});
        n.addEventListener("close", resolve,false);
    });
}

export function smartNotification(options: INotificationOptions) {
    const processedOptions = notificationOptions(options);
    const {title, content, animation, timeout, messageType} = processedOptions;
    let {icon, displayType, color} = processedOptions;

    const defaults = messageType && notificationDefaults[messageType] || notificationDefaults.default;
    if (icon == null) {
        icon = defaults.icon;
    }
    if (animation == null) {
        animation || defaults.animation;
    }
    if (color == null) {
        color = defaults.color;
    }

    const iconString = `fa ${icon} ${animation}`;

    if (color && color[0] !== "#") {
        const colRgb = Gears.bgFromClass(color);
        if (colRgb) {
            color = colRgb;
        }
    }

    const smartOptions: INotificationOptions = {title, content, color, timeout};

    /*
    global.notifications.next({
        id: Math.random(),
        title: title,
        content: content ? content.replace(/(<([^>]+)>)/ig, "") : "",
        icon: {name: icon, colour: color},
        extra: global.moment().format("h:mm:ss a MMM Do"),
    });
    */

    if (displayType === "extraSmallBox") {
        smartOptions.iconSmall = iconString;
        displayType = "smallBox";
    } else {
        smartOptions.icon = iconString;
        if (displayType != "smallBox") {
            displayType = "bigBox";
        }
    }

    return new Bluebird((resolve, reject) => {
        ($ as any)[displayType as string](smartOptions, resolve);
    });
}

export function dialog(options: INotificationOptions): Bluebird<any> {
    console.log("dialog called");
    if (!options) {
        return Bluebird.resolve("No Confirmation Required");
    }
    options = notificationOptions(options);
    options.messageType = options.messageType || "info";
    options.icon = options.icon || options.messageType;
    return gearsDialog(options);
}

export function iFrameDialog(optionsUrl: IFrameINotificationOptions | string): Bluebird<any> {
    let options: INotificationOptions;
    let url: string;
    console.log("Iframe not", optionsUrl);
    if (typeof optionsUrl === "string") {
        options = {};
        url = optionsUrl;
    } else {
        options = optionsUrl;
        url = optionsUrl && optionsUrl.url;
    }
    if (!(options && url)) {
        console.log("Url missing for iframe notification", options, optionsUrl);
        throw new ConfigurationError("Url missing for iframe notification");
    }
    options.content = `<iframe src="${url}" width="99.6%" height="600" frameborder="0"></iframe>`;
    options.title = options.title || null;

    return gearsDialog(options);
}

export function getConfirmation(options: INotificationOptions) {
    if (!options) {
        return Bluebird.resolve("No Confirmation Required");
    }
    options = notificationOptions(options);
    options.icon = options.icon || options.messageType || "warning";
    options.messageType = options.messageType || "info";
    return dialog(options);
}

export {closeDialog};
