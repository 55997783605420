exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1tnLp0ZF0CkkLGlnYOlQGr{padding:32px;padding-bottom:16px;font-size:16px;min-width:400px}._1tnLp0ZF0CkkLGlnYOlQGr form fieldset{border:1px solid #787878;padding:8px 16px}._1tnLp0ZF0CkkLGlnYOlQGr form fieldset legend{width:auto;border:none;padding:8px;margin-bottom:0px}._1tnLp0ZF0CkkLGlnYOlQGr form>div{margin-top:16px;text-align:right}._1tnLp0ZF0CkkLGlnYOlQGr form>div button{margin-left:8px}\n", ""]);

// exports
exports.locals = {
	"account-settings": "_1tnLp0ZF0CkkLGlnYOlQGr"
};