import * as classNames from "classnames";
import {Component} from "react";
import * as React from "react";

export enum InlineEditWhenEmpty {
    Display = 1,
    Edit,
    Placeholder,
}

export interface InlineEditProps {
    value: string;
    display: (source: string) => string;
    editorClassNames?: string;
    displayClassNames?: string;
    onChange?: (value: string) => void;
    whenEmpty?: InlineEditWhenEmpty;
}

interface InlineEditState {
    editing: boolean;
}

/**
 * A modal popup that allows editing for User sidebar settings
 */
export default class InlineEdit extends Component<InlineEditProps, InlineEditState> {
    public static defaultProps: Partial<InlineEditProps> = {
        display: (source) => source,
        whenEmpty: InlineEditWhenEmpty.Edit,
    };

    private static startInEditMode(props: InlineEditProps) {
        return !props.value && props.whenEmpty === InlineEditWhenEmpty.Edit;
    }

    constructor(props: InlineEditProps) {
        super(props);
        this.state = {editing: InlineEdit.startInEditMode(this.props)};
    }

    public componentWillReceiveProps(nextProps: InlineEditProps) {
        this.setState({editing: InlineEdit.startInEditMode(nextProps)});
    }

    public render(): JSX.Element | null {
        const {editing} = this.state;
        const cn: string = classNames("inline-edit", editing ? "editing" : "display");
        return (<span className={cn} style={{width: "100%"}}>{
            editing ? this.editingRender() : this.plainRender()
        }</span>);
    }

    private editMode = (): void => {
        this.setState({editing: true});
    };

    private onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        console.log("Blur called");
        this.setState({editing: false});
        if (this.props.onChange) {
            const newValue = (event.target as HTMLInputElement).value;
            if (newValue !== this.props.value) {
                this.props.onChange(newValue);
            }
        }
    };

    private editingRender(): JSX.Element | null {
        const allClassNames: string = classNames("inline-edit-editor", this.props.editorClassNames);
        return <input autoFocus className={allClassNames} defaultValue={this.props.value} onBlur={this.onBlur} />;
    }

    private plainRender(): JSX.Element {
        const {display, value, displayClassNames} = this.props;
        const toDisplay = display ? display(value) : value;
        const allClassNames: string = classNames("inline-edit-display", displayClassNames);

        return <span onClick={this.editMode} className={allClassNames} dangerouslySetInnerHTML={{__html: toDisplay}}/>;
    }

}
