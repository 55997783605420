import * as React from "react";
import * as ReactDOM from "react-dom";

export interface ReactWidgetProps<T> {
    value: T;
    onChange?: (value: T) => void;
}

export interface ReactWidgetOptions<T> {
    value?: T;
    name?: string;
}

export default class ReactWidget<T, P extends ReactWidgetProps<T>> extends kendo.ui.Widget {
    public static fn = ReactWidget.prototype;
    public props: P;
    public reactComponent: React.ComponentClass<P>;

    // static options = {
    //     name: 'ReactWidget'
    // }
    public update = (newValue: T) => {
        this.value(newValue);
        this.trigger("change");
    };

    constructor(reactComponent: React.ComponentClass<P>, element: Element, options?: ReactWidgetOptions<T>) {
        super(element, options);
        this.reactComponent = reactComponent;
        super.init(element, options);
        this.props = $.extend(true, {}, options, {
            onChange: this.update,
        }) as P;
        this.render();
    }

    public render() {
        const element = React.createElement(this.reactComponent, this.props);
        // console.log("Rendering to", element, this.element[0]);
        ReactDOM.render(element, this.element[0]);
    }

    public get(field: string): any {
        return (this.props as any)[field];
    }

    public set(field: string, newValue: any): void {
        (this.props as any)[field] = newValue;
        this.render();
    }

    public value(newValue: T | undefined): any {
        if (newValue !== undefined) {
            this.set("value", newValue);
        } else {
            return this.get("value");
        }
    }

    public enabled(newValue: boolean | undefined) {
        if (newValue !== undefined) {
            this.set("enabled", newValue);
        } else {
            return this.get("enabled");
        }
    }
}
ReactWidget.fn.options = $.extend(true, {}, kendo.ui.Widget.fn.options, {name: "ReactWidget"});

export function installReactWidget(widgetType: any, options: any) {

    // let options  = widgetType.options  || {};
    options = options || widgetType.options;

    if (!options.name) {
        options.name = widgetType.name;
    }

    const fn = widgetType.prototype;
    widgetType.fn = fn;

    fn.options = $.extend(true, {}, ReactWidget.fn.options, options);

    kendo.ui.plugin(widgetType);
}

export function wrapSimpleReactWidget<T, P extends ReactWidgetProps<T>>(widgetClass: React.ComponentClass<P>, options: ReactWidgetOptions<T> = {}) {
    class WrappedReactWidget extends ReactWidget<any, ReactWidgetProps<any>> {
        constructor(element: Element, options?: ReactWidgetOptions<any>) {
            super(widgetClass, element, options);
        }
    }

    if (!options.name) {
        options.name = widgetClass.name;
    }
    installReactWidget(WrappedReactWidget, options);
}
