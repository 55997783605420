import {escape} from "lodash";
import vendor from "../../modules/vendor";

// <div class="code" ace-mode="ace/mode/lua" ace-theme="ace/theme/chrome" ace-gutter="true" style="width: 30em;">
export function highlight(code: string, language?: string): string {
    vendor.ace;
    const ace = vendor.modules.ace;
    if (ace) {
        return ace.highlight(code, language || "json5");
    } else {
        return escape(code); // .replace(/\n/g, "<br/>");
    }
}

export function maxLines(source: string, max?: number): string {
    if (!source) {return ""; }
    return source.split("\n", max || 1).join("\n");
}

export function stripWhitespace(source: string): string {
    return (source || "").replace(/\s+/g, ' ').trim();
}

// export function highlightjs(code: string, language?: string) {
//     vendor.highlightjs;
//     const highlightjs = vendor.modules.highlightjs;
//     if (highlightjs) {
//         if (language) {
//             try {
//                 return highlightjs.highlight(language, code, true).value;
//             } catch (e) {
//                 // noop
//             }
//         }
//         return highlightjs.highlightAuto(code).value;
//     } else {
//         return escape(code); // .replace(/\n/g, "<br/>");
//     }
// }
