import * as Promise from "bluebird";
import {bindAll} from "lodash-decorators";
import {action, computed, observable, ObservableMap, reaction} from "mobx";
import * as pluralize from "pluralize";
import {InvalidDataError, SubvertKendo, UserCancelled} from "./Errors";
import {standardizeAllDates} from "./GearsDataSource";
import {GridController} from "./GridController";

declare const Gears: any;

export function addMultiSelect(gridController: any) {
    console.log("addMultiSelect called");
    (global as any).Gears.MultiSelect = MultiSelect;
    new MultiSelect(gridController);
}

interface IMultiSelectGridController extends GridController {
    multiSelect: MultiSelect;
    cancelRollthrough: () => void;
}

@bindAll()
export class MultiSelect extends kendo.data.ObservableObject {
    public readonly selectedIds: ObservableMap<boolean> = new ObservableMap();
    @observable public enabled: boolean = false;
    @observable private rollthroughRecord?: any;
    private modalRollthroughView?: any;
    private gridController: IMultiSelectGridController;

    constructor(gridController: GridController) {
        super();
        // super.init(this);
        gridController.config.gearsModel.prototype._multiSelect = this;
        // console.log("Setting multiselect on model", gridController.config.gearsModel, gridController.config.gearsModel.prototype, gridController.config.gearsModel.prototype._multiSelect);
        gridController.gearsGrid.table.on("click", "input.multi-select.checkbox", this.onCheckboxClick);
        gridController.set("multiSelect", this);
        this.gridController = gridController as IMultiSelectGridController;
        _.each(["enableMultipleSelectionButton", "unselectAllButton", "disableMultipleSelectionButton", "multiSelectEnabled", "multiSelectDisabled", "showMultiButton", "selectAllInFilter", "cancelRollthrough", "openRollthrough", "saveRollthroughButton", "saveRollthrough"], (f: string) => (gridController as any)[f] = (this as any)[f]);
        reaction(() => this.enabled, () =>  this.trigger("change", {field: "enabled"}));
        reaction(() => this.hasMultiSelection, () => console.log("ms changed", this.hasMultiSelection) || this.gridController.trigger("change", {field: "hasMultiSelection"}));
        reaction(() => this.rollthroughRecord, () => this.trigger("change", {field: "rollthroughRecord"}));
    }

    public unselectAllButton() {
        this.selectedIds.clear();
        return this.gridController.refresh();
    }

    public disableMultipleSelectionButton() {
        this.selectedIds.clear();
        this.enabled = false;
        this.gridController.gearsGrid.refresh();
    }

    public enableMultipleSelectionButton() {
        this.enabled = true;
        this.gridController.dataSource.read();
    }

    public multiSelectDisabled(): boolean {
        return !this.enabled;
    }

    public showMultiButton() {
        return this.enabled;
    }

    @computed
    public get multiSelectionIds(): Array<number | string> {
        return this.selectedIds.keys();
    }

    @computed
    public get multiSelectionIdsCount(): number {
        return this.selectedIds.size;
    }

    @computed
    public get multiSelectionIdsCountFormatted() {
        return pluralize("record", this.multiSelectionIdsCount, true);
    }

    @computed
    public get hasMultiSelection() {
        return this.multiSelectionIds.length > 0;
    }

    @action
    public setSelection(input: HTMLInputElement) {
        const checked = input.checked;
        const $row = $(input).closest("tr");
        const id = $row.data("id");
        if (checked) {
            this.selectedIds.set(id, true);
            $row.addClass("gears-multi-selected");
        } else {
            this.selectedIds.delete(id);
            $row.removeClass("gears-multi-selected");
        }
        $row.addClass("gears-row");
    }

    public onCheckboxClick(input: any) {
        input = input.toElement || input.target;
        console.log("input click", input);
        return this.setSelection(input);
    }

    public filterMultiSelection() {
        const ids = this.multiSelectionIds;
        if (!ids.length) {
            return null;
        }
        this.gridController.dataSource.filter({
            field: "id",
            operator: "in",
            value: ids,
        });
        return this.gridController.dataSource.read();
    }

    public selectAllInFilter() {
        return this.getFilterIds().then((ids) => {
            if (ids.length > 50) {
                return Gears.getConfirmation({
                    title: "Are you sure?",
                    content: "Adding " + ids.length + " records to selection.",
                }).then(function() {
                    return ids;
                });
            } else {
                return ids;
            }
        }).then((ids) => {
            console.log("Setting ids", ids);
            const selectedIds = this.gridController.multiSelect.selectedIds;
            _.each(ids, (id: string) => selectedIds.set(id, true));
            this.gridController.multiSelect.set("selectedIds", selectedIds);
            return this.gridController.refresh();
        }).catch(UserCancelled, () => console.log("User cancelled"))
            .catch(this.gridController.showDataSourceError);
    }

    public multiple_recordsConfirmation(name: string) {
        return {
            title: "Are  you want to run " + name + "?",
            message: this.multiSelectionIds.length + " records are selected.",
        };
    }

    public getFilterIds(): Promise<Array<string | number>> {
        const parentGrid = this.gridController.parentGrid;
        const url = this.gridController.config.readUrl((parentGrid != null ? parentGrid.get("selectedRecord.id") : undefined));
        return Promise.resolve($.ajax({
            url,
            data: {
                just_ids: true,
                filter: this.gridController.dataSource.filter(),
            },
            dataType: "json",
            type: "POST",
        })).then((data) => {
            if (data.error) {
                throw data;
            }
            if (data.length == null) {
                console.log(data);
                throw new InvalidDataError(data);
            }
            return data;
        });
    }

    public setupRollthroughModalView() {
        if (this.modalRollthroughView) {
            return;
        }
        try {
            const rollthroughTemplate = this.gridController.config.rollthroughTemplate;
            const modalRollthroughView = new kendo.View(rollthroughTemplate, {
                model: this,
                evalTemplate: true,
            });
            modalRollthroughView.render($(this.gridController.config.modalRollthroughDomId() + "-body"));
            this.modalRollthroughView = modalRollthroughView;
        } catch (e) {
            console.error("Error rendering rollthrough modal for grid:", this, this.gridController.config.rollthroughTemplate, e);
        }
    }

    public openRollthrough(it: any) {
        if (!this.hasMultiSelection) {
            return;
        }
        this.gridController.disableFullscreenForModal();
        this.set("rollthroughRecord", new this.gridController.config.gearsModel({}));
        console.log("Rollthrough", it);
        this.setupRollthroughModalView();
        return $(this.gridController.config.modalRollthroughDomId()).modal({
            backdrop: "static",
            keyboard: false,
        });
    }

    public cancelRollthrough() {
        return $(this.gridController.config.modalRollthroughDomId()).modal("hide");
    }

    public cancelRollthroughButton() {
        return this.cancelRollthrough();
    }

    public saveRollthrough() {
        const confirmation = "Are you sure you want to update " + this.multiSelectionIdsCountFormatted + "?";
        return this.gridController.callWebFunctionWithConfirmation("rollthrough", {
            type: "multiple_records",
            confirmation,
            dataToSend: standardizeAllDates(this.rollthroughRecord.toJSON()),
        }, $(this.gridController.config.modalRollthroughDomId()).find(".save-button")).then(() => {
            this.gridController.cancelRollthrough();
            return this.gridController.refreshButton();
        });
    }

    public saveRollthroughButton() {
        return this.saveRollthrough();
    }

    public deleteAll(): Promise<any> {
        let confirmation;
        confirmation = "Are you sure you want to delete " + this.multiSelectionIdsCountFormatted() + "?";
        return this.gridController.callWebFunctionWithConfirmation("delete_all", {
            type: "multiple_records",
            confirmation,
        }).then(() => this.gridController.refreshButton());
    }

    public deleteAllButton() {
        return this.deleteAll();
    }
}
