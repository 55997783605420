import * as Class from "classnames";
import * as $ from "jquery";
import * as React from "react";
import styles from "./SidebarItem.scss";
import { bind } from "decko";

import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import { getFaIconProps } from "../gears/helpers/fa";

export interface ISidebarItem {
    href: string;
    target?: string;
    icon?: string;
    open: boolean;
    active?: boolean;
    colour?: string | string[] | null;
    title: string;
    children: ISidebarItem[];
}

interface ISidebarItemProps extends ISidebarItem {
    onClick: (sidebar: SidebarItem) => void;
    onNavigate: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

//FontAwesome.findIconDefinition({iconName: 'circle', prefix: 'fas'})

/**
 * A single item or group of items in a sidebar
 */
class SidebarItem extends React.Component<ISidebarItemProps, never> {
    private submenuRef: HTMLElement;
    private menuRef: HTMLElement;
    private iconRef: HTMLElement;

    public componentDidMount() {
        if (this.props.open) {
            this.show();
        }
    }

    @bind
    private onClick() {
        $(this.submenuRef).slideToggle(200);
        $(this.menuRef).toggleClass("open");
        $(this.iconRef).toggleClass("fa-plus").toggleClass("fa-minus");
        if (this.props.onClick) {
            this.props.onClick(this);
        }
    }

    @bind
    private onNavigate(e: React.MouseEvent<HTMLAnchorElement>) {
        if (this.props.onNavigate) {
            this.props.onNavigate(e);
        }
    }

    public hide() {
        $(this.submenuRef).slideUp(200);
        $(this.menuRef).removeClass("open");
        $(this.iconRef).addClass("fa-plus");
        $(this.iconRef).removeClass("fa-minus");
    }

    public show() {
        $(this.submenuRef).slideDown(200);
        $(this.menuRef).addClass("open");
        $(this.iconRef).addClass("fa-minus");
        $(this.iconRef).removeClass("fa-plus");
    }

    public render() {
        const { children, icon, colour, title, active, target, href } = this.props;
        const faIcon = getFaIconProps(Class(icon, colour, "icon"));

        if (children && children.length) {
            return (
                <div className={Class(styles.item)}>
                    <div ref={(ref) => this.menuRef = ref as HTMLElement} className={Class(styles.menu)} onClick={this.onClick}>
                        <FontAwesomeIcon {...faIcon} />
                        <span>{title}</span>
                        <FontAwesomeIcon
                            fixedWidth
                            icon="plus"
                            className={Class(styles.group)} />
                    </div>
                    <div
                        className={Class(styles.submenu)}
                        ref={(ref) => this.submenuRef = ref as HTMLElement}>
                        {children.map((item: any, index: number) => {
                            const itemFA = item.icon ? getFaIconProps(item.icon + " " + Class(styles.icon)) : { icon: "circle", className: Class(styles.empty, styles.icon) };
                            return (<a key={index} href={item.href} target={item.target} data-href={item.href} onClick={this.onNavigate}>
                                <div className={Class(styles.menu, { [styles.open]: item.open })}>
                                    <FontAwesomeIcon fixedWidth size="lg" {...itemFA} />
                                    <FontAwesomeIcon icon="circle" className={Class(styles.icon, styles.dot)} size="xs" />
                                    <span className={item.colour}>{item.title}</span>
                                </div>
                            </a>);
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={Class(styles.item, styles["single-item"])}>
                    <a href={href} target={target} data-href={href} onClick={this.onNavigate}>
                        <div ref={(ref) => this.menuRef = ref as HTMLElement} className={Class(styles.menu, { open: active })}
                            onClick={this.onClick}>
                            {faIcon.icon && <FontAwesomeIcon {...faIcon} />}
                            <span className={Class(colour)}>{title}</span>
                        </div>
                    </a>
                </div>
            );
        }
    }
}

export default SidebarItem;
