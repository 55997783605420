exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3PyEfdsnX-50r2Sm4Rsl_f{position:relative;width:100%;height:160px;font-family:\"Roboto\", \"Verdana\", \"Helvetica\", sans-serif;cursor:pointer}._3PyEfdsnX-50r2Sm4Rsl_f>.-fgxeQ-Cn1dGFhl2PpC1g{position:absolute;right:0px;top:0px;color:white}._3PyEfdsnX-50r2Sm4Rsl_f>.-fgxeQ-Cn1dGFhl2PpC1g>span{background:rgba(0,0,0,0.4);padding:8px}._3PyEfdsnX-50r2Sm4Rsl_f ._396SkuOkNOWPJZuLKunKPi{position:absolute;bottom:0px;width:100%;height:54px;background:linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#59000000', GradientType=0)}._3PyEfdsnX-50r2Sm4Rsl_f ._376KOmKUZ7BQy0KlBBJQCO{position:absolute;bottom:10px;font-size:12px;left:16px;color:white}._3PyEfdsnX-50r2Sm4Rsl_f ._376KOmKUZ7BQy0KlBBJQCO>span{vertical-align:middle;display:inline-block}._3PyEfdsnX-50r2Sm4Rsl_f ._376KOmKUZ7BQy0KlBBJQCO>span ._3G8F04xIKF5mEkl7LJ6MDC{font-size:14px;display:block}._3PyEfdsnX-50r2Sm4Rsl_f ._376KOmKUZ7BQy0KlBBJQCO>span .-fgxeQ-Cn1dGFhl2PpC1g{font-size:10px;display:block;text-transform:uppercase}\n", ""]);

// exports
exports.locals = {
	"header": "_3PyEfdsnX-50r2Sm4Rsl_f",
	"extra": "-fgxeQ-Cn1dGFhl2PpC1g",
	"backdrop": "_396SkuOkNOWPJZuLKunKPi",
	"frontdrop": "_376KOmKUZ7BQy0KlBBJQCO",
	"name": "_3G8F04xIKF5mEkl7LJ6MDC"
};