import * as React from "react";
import FilterRow from "./FilterRow";
import { observer } from "mobx-react";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import * as styles from "./FilterBuilder.scss";
import { bind } from "decko";
import { action, observable, extendObservable, toJS } from "mobx";
import Input from "commonui/Input";
import Select from "commonui/Select";
import Button from "commonui/Button";
import * as Class from "classnames";

const logic = [
    { value: "and", label: "And" },
    { value: "or", label: "Or" },
];

interface FilterGroupProps {
    filter: any;
    filterIndex?: number;
    data: any;
    parentFilter?: any;
}

interface RenderedFilter {
    index: number;
    filter: any;
}

@observer
export default class FilterGroup extends React.Component<FilterGroupProps, never> {
    @observable private collapsed: boolean = false;
    @observable private mayDelete: boolean = false;

    constructor(props: FilterGroupProps) {
        super(props);

        if (!props.filter.logic) {
            extendObservable(props.filter, {
                logic: "and",
            });
        }
    }

    @bind
    @action
    private toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }

    @bind
    @action
    private addFilter() {
        this.props.filter.filters.push({ field: "", operator: "", value: "" });
    }

    @bind
    @action
    private addFilterGroup() {
        this.props.filter.filters.push({ logic: "and", filters: [{ field: "", operator: "", value: "" }, { field: "", operator: "", value: "" }] });
    }

    @bind
    @action
    private destroyFilterGroup() {
        this.props.parentFilter.splice(this.props.filterIndex as number, 1);
    }

    @bind
    @action
    private onToggleLogic() {
        if (this.props.filter.logic === "and") {
            this.props.filter.logic = "or";
        } else {
            this.props.filter.logic = "and";
        }
    }

    public render() {
        let groupEndIndex = -1;
        let containsGroups = false;
        const disableDeletion = this.props.filter.filters.filter((a) => !a.filters).length === 1;
        const renderedFilter: RenderedFilter[] = [];
        this.props.filter.filters
            .map((item, index) => {
                renderedFilter.push({
                    index: index,
                    filter: item
                })
                return item;
            });
        const forms: JSX.Element[] = renderedFilter
            .sort((a, b) => {
                // Make sure filter groups always appear below this group
                if (a.filter.filters) {
                    return -1;
                }
                if (!a.filter.filters && !b.filter.filters) {
                    return 0;
                }
                return 1;
            })
            .map((item, index) => {
                if (!item.filter.filters) {
                    return <FilterRow
                        logic={this.props.filter.logic}
                        onToggleLogic={this.onToggleLogic}
                        key={index}
                        filterRow={item.filter}
                        filterRowIndex={item.index}
                        parentFilter={this.props.filter.filters}
                        data={this.props.data}
                        disableDelete={disableDeletion}
                        isFirst={index === 0} />
                } else {
                    containsGroups = true;
                    groupEndIndex = index;
                    return <FilterGroup
                        parentFilter={this.props.filter.filters}
                        key={index}
                        filter={item.filter}
                        filterIndex={item.index}
                        data={this.props.data} />;
                }
            });

        // Insert the new group button inbetween groups and rules
        forms.splice(groupEndIndex + 1, 0,
            <div key="add-group" className={Class(styles["add-group"], { [styles["is-first"]]: groupEndIndex === -1 })} >
                <Button icon="fa-plus-circle" flat title="Add Group" onClick={this.addFilterGroup} />
            </div>
        );

        const arrow = <FontAwesomeIcon
            className={styles["collapser"]}
            icon={this.collapsed ? "folder" : "folder-open"}
            onClick={this.toggleCollapsed} />

        return (
            <div
                className={Class(styles["filter-group"], { [styles["may-delete"]]: this.mayDelete, [styles.collapsed]: this.collapsed })}>
                {this.props.parentFilter != null ? arrow : ""}
                <div>
                    {!this.collapsed && this.props.parentFilter && !containsGroups &&
                        <Button
                            icon="fa-minus"
                            title="Delete Group"
                            coloured
                            onMouseOver={action(() => this.mayDelete = true)}
                            onMouseOut={action(() => this.mayDelete = false)}
                            className={Class("red", styles["delete-group"])}
                            flat
                            onClick={this.destroyFilterGroup} />
                    }
                    {!this.collapsed && forms}
                    {!this.collapsed &&
                        <div className={Class(styles["add-rule"])} >
                            <Button
                                icon="fa-plus-circle"
                                flat title="Add Rule"
                                onClick={this.addFilter} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

/*
 <button className={styles["icon-add"]} onClick={this.addFilter}>
                            <FontAwesomeIcon fixedWidth icon="plus" />
                            <span>Add Rule</span>
                        </button>
*/
