import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import * as Class from "classnames";
import Icon from "commonui/Icon";
import Menu from "commonui/Menu/Menu";
import "jquery.nicescroll";
import { observable } from "mobx";
import { createTransformer } from "mobx-utils";
import { observer } from "mobx-react";
import * as React from "react";
import { default as NotificationState, INotification as IStateNotification } from "../../gears/GearsState/NotificationState";
import { getFaIconProps, IFAIconProps } from "../../gears/helpers";
import Layer from "../../gears/helpers/FaLayer";
import ErrorDisplayComponent from "../ErrorDisplayComponent";
import "./NotificationMenu.scss";

interface NotificationIcon {
    name: string;
    color?: string;
    colour?: string;
    className?: string;
    background?: string;
}

interface INotification {
    id: number;
    title: string;
    content: string;
    icon?: IFAIconProps;
    extra?: string;
    unread: boolean;
    important?: boolean;
    hidden?: boolean;
}

interface INotificationMenuProps {
    notifications: NotificationState;
    className: string;
}

interface NotificationMenuState {
    hasChecked: boolean;
}

function toMenuNotification(notification: IStateNotification): INotification {
    const details = notification.details;
    return {
        id: notification.id,
        title: details.title || "",
        content: details.content || (details as any).message,
        icon: details.icon ? getFaIconProps(details.icon) : undefined,
        unread: false,
    };
}

function NotificationItem(params: { stateItem: IStateNotification }) {
    const item = NotificationMenu.toMenuNotification(params.stateItem);

    let icon = item.icon;
    if (!icon) {
        icon = NotificationMenu.IconWarning;
    }

    let content = item.content as any;
    if (typeof content === "object") {
        content = JSON.stringify(content, null, 4);
    }
    return (
        <ErrorDisplayComponent>
            <li key={item.id}
                className={Class({ unread: item.unread, important: item.important })}>
                <Layer className={Class("icon-container", icon.className)} >
                    <FontAwesomeIcon icon={{ prefix: "fal", iconName: "circle" }} />
                    <FontAwesomeIcon icon={icon.icon} transform={{ size: 9 }} />
                </Layer>
                {/*<div*/}
                {/*className={Class("icon-container", icon.className)}*/}
                {/*style={{ color: icon.color || icon.colour }}>*/}
                {/*<Icon className="circle" icon={icon.background || {prefix: "fal", iconName: "circle"}} />*/}
                {/*<Icon className="content-icon" icon={icon.name} />*/}
                {/*</div>*/}
                <div className="content-container">
                    <h6>{item.title}</h6>
                    <p>{content}</p>
                    <span className="extra">{item.extra}</span>
                </div>
            </li>
        </ErrorDisplayComponent>
    );
}

@observer
export default class NotificationMenu extends React.Component<INotificationMenuProps, NotificationMenuState> {
    // public static IconError = <FontAwesomeIcon icon="times" style={{colour: "#F44336" }}/>;
    // public static IconWarning = <FontAwesomeIcon icon="exclamation" style={{colour: "#FF9800" }}/>;
    // public static IconCheck = <FontAwesomeIcon icon="check" style={{colour: "#4CAF50" }}/>;
    // public static IconInfo = <FontAwesomeIcon icon="info" style={{colour: "#2196F3" }}/>;
    // public static IconQuestion = <FontAwesomeIcon icon="question" style={{colour: "#9E9E9E" }}/>;
    public static IconError = { name: "fa-times", colour: "#F44336" };
    public static IconWarning = { icon: { prefix: 'fas', iconName: "exclamation" }, style: { color: "#FF9800" } };
    public static IconCheck = { name: "fa-check", colour: "#4CAF50" };
    public static IconInfo = { name: "fa-info", colour: "#2196F3" };
    public static IconQuestion = { name: "fa-question", colour: "#9E9E9E" };
    public static toMenuNotification = createTransformer(toMenuNotification);
    private menuRef: Menu;

    constructor(props: INotificationMenuProps) {
        super(props);
        this.onOpenMenu = this.onOpenMenu.bind(this);
    }

    public componentDidUpdate() {
        /**
         * Currently disabled due to weird hover over bugs
         */
        /*
        const scroller = $(".notification-scroller")[0];
        if (scroller) {
            console.debug("Adding nicescroll");
            $(".notification-scroller").niceScroll();
        } */
    }

    private renderEmpty() {
        return (
            <div className="empty">
                <span>No Notifications</span>
            </div>
        );
    }

    private renderList() {
        // TODO: Use the listview from commonui to render list
        return (
            <div className="notification-scroller">
                {
                    this.props.notifications.notifications.map((item, index) => {
                        return <NotificationItem stateItem={item} key={item.id} />;
                    })
                }
            </div >
        );
    }

    private onOpenMenu() {
        this.props.notifications.markAllAsRead();
    }

    /*
    private renderItem(stateItem: IStateNotification) {
        const item = NotificationMenu.toMenuNotification(stateItem);

        let icon = item.icon;
        if (!icon) {
            icon = NotificationMenu.IconWarning;
        }
        return (
            <li key={item.id}
                className={Class({ unread: item.unread, important: item.important })}>
                <div
                    className={Class("icon-container", icon.className)}
                    style={{ color: icon.color || icon.colour }}>
                    <Icon className="circle" icon={icon.background || "fa-circle-thin"} />
                    <Icon className="content-icon" icon={icon.name} />
                </div>
                <div className="content-container">
                    <h6>{item.title}</h6>
                    <p>{item.content}</p>
                    <span className="extra">{item.extra}</span>
                </div>
            </li>
        );
    }
    */

    public render() {
        const notificationState = this.props.notifications;
        const isEmpty = notificationState.notifications.length === 0;
        const unreadCount = notificationState.unreadCount;
        return (
            <span className="notification-menu-container">
                <Layer className={this.props.className}>
                    <FontAwesomeIcon id="notification-menu-icon" icon="bell" />
                    {unreadCount > 0 && <Layer.Counter className="counter">{unreadCount}</Layer.Counter>}
                </Layer>
                <Menu
                    ref={(ref: any) => this.menuRef = ref}
                    className="notification-menu"
                    target="#notification-menu-icon"
                    mount="left-top"
                    onOpen={this.onOpenMenu}>
                    <span>
                        <h5>Notifications</h5>
                        <ul>
                            {isEmpty ? this.renderEmpty() : this.renderList()}
                        </ul>
                        <span className="full-list">
                            <a href="/notifications" target="_blank">View All</a>
                        </span>
                    </span>
                </Menu>
            </span>
        );
    }
}
// Just a quick global to get access to Icons
(global as any).NotificationMenu = NotificationMenu;
