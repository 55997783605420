// Generated by CoffeeScript 1.10.0
(function() {
  exports.Document = {
    id: "id",
    hasChildren: false,
    fields: {
      id: {
        editable: false,
        nullable: true,
      },
      parent_document_id: {
        type: "number",
      },
      record_state_id: {
        type: "number",
      },
      type: {
        type: "text",
      },
      record_number: {
        type: "number",
      },
      record_version: {
        type: "number",
      },
      status_id: {
        type: "number",
      },
      reference_number: {
        type: "text",
      },
      title: {
        type: "text",
      },
      notes: {
        type: "text",
      },
      created_at: {
        type: "date",
      },
      updated_at: {
        type: "date",
      },
      company_id: {
        type: "number",
      },
      contact_id: {
        type: "number",
      },
    },
  };

}).call(this);
