import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {set,observe,extendObservable} from "mobx";
// import $ from "jquery";
// import _ from "lodash";
import {GearsDialog, closeDialog} from "commonui/Dialog";

function gearsDialog(options) {
    return new Promise((resolve, reject) => {
        ReactDOM.unmountComponentAtNode($("#modalContainer")[0]);
        console.log("Rendering kendo gears dialog", options);
        ReactDOM.render(
            React.createElement(options.kendo ? KendoGearsDialog : GearsDialog, _.merge({}, options, {onClose: resolve})),
            $("#modalContainer")[0],
        );
    }).then(function(test) {
        // console.log("Testthen", test);
        return test.toJSON ? test.toJSON() : test;
    });
}

let observableObject, observableObjectInit;
if (typeof kendo === "undefined") {
    observableObject = Object;
    observableObjectInit = function() {
        return null;
    };
} else {
    observableObject = global.kendo.data.ObservableObject;
    observableObjectInit = observableObject.fn.init;
}

export class DialogResult extends observableObject {
    constructor(data) {
        super(data);
        observableObjectInit.call(this, data || {});
        this.set("button", null);
        // extendObservable(this, null);
        // set(this, this);
        // this.setupObserve();
    }

    setupObserve() {
        observe(this, (change) => this.trigger("change", {field: change.name}));
    }
}

class ViewRenderer extends React.Component {
    static propTypes = {
        template: PropTypes.string,
        model: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.kendoView = new window.kendo.View('<span style="width: 100%;">' + this.props.template + "</span>", {
            model: this.props.model,
            evalTemplate: true,
        });
    }

    render() {
        return <div className="dialog-content" ref={this.contentRendered}></div>;
    }

    contentRendered = (element) => {
        console.log("Appending to element", element, this);
        this.kendoView.render($(ReactDOM.findDOMNode(element)));
    }
}

export class KendoGearsDialog extends GearsDialog {
    constructor(props) {
        super(props);
        const {defaultResult} = this.props;
        this.result = new DialogResult(defaultResult ? _.clone(defaultResult) : {});
    }

    buttonResult(button, e) {
        this.result.set("button",  button.value || button.name);
        return button.onClick ? button.onClick.call(this, e) : this.result;
    }

    // handleClick(button, e: any) {
    //     let res = button.onClick ? Promise.try(() => button.onClick.call(this, e)) : Promise.resolve({ button: button.value || button.name });
    //     res.then((result) => {
    //         if (button.reject) {
    //             result = Promise.reject(result);
    //         }
    //         if (this.props.onClose) {
    //             this.props.onClose(this.result);
    //         }
    //         this.close();
    //         console.debug("Called close", this.props.onClose, result);
    //         return result;
    //     });
    //     return res;
    // }

    renderContent() {
        // return <DialogContent ref={this.dialogContentRendered}/>;
        return <ViewRenderer template={typeof (this.props.kendo) === "string" ? this.props.kendo : this.props.content}
                             model={this.result}/>;
    }
}

//export default GearsDialog;
export default gearsDialog;
export {closeDialog};
