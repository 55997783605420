const datetimeOperators = [
    {
        value: "eq",
        label: "Equals",
        icon: "=",
    },
    {
        value: "neq",
        label: "Not Equals",
        icon: "≠",
    },
    {
        value: "lt",
        label: "Less Than",
        icon: "<",
    },
    {
        value: "gt",
        label: "Greater Than",
        icon: ">",
    },
    {
        value: "lte",
        label: "Less Than Or Equal",
        icon: "≤",
    },
    {
        value: "gte",
        label: "Greater Than Or Equal",
        icon: "≥",
    },
];

export default datetimeOperators;
