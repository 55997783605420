import * as _ from "lodash";

const xOffset = -10;
const yOffset = -10;

function imagePreviewHoverIn(event) {
    let c, pv;
    imagePreviewHoverOut.cancel();
    this.t = this.title;
    this.title = "";
    c = this.t ? `<br/>${this.t}` : "";
    pv = $("image-preview");
    console.log("imprev", pv);
    if (pv.length > 0) {
        pv.find("img").attr("src", this.href).html(c);
    } else {
        $("body").append(`
        <p id='image-preview'>
            <img src='${this.href}' alt='Image preview' />${c}
        </p>
        `);
    }
    return $("#image-preview").fadeIn("fast");
}

function hidePreview() {
    this.title = this.t;
    return $("#image-preview").remove();
}

const imagePreviewHoverOut = _.debounce(hidePreview, 2000);

export function setupImagePreview() {
    _.once(function() {
        $("body").on("mouseenter", "a.preview", imagePreviewHoverIn);
        return $("body").on("mouseleave", "a.preview", imagePreviewHoverOut);
    });
}
