import * as Promise from "bluebird";
import * as $ from "jquery";
import {notification} from "./Notification";

declare var Gears: any;

export function clickLogId(it) {
    const data = $(it.target).data();
    const queryLogId = data.queryLogId;
    console.log("log id clicked");
    const user = gearsState.user;
    if (user && user.user_group_id === 100) {
        return window.open(`/webfront_logs/${queryLogId}`, "_blank").focus();
    } else {
        return Promise.resolve(
            $.ajax({
                url: "/webfront_logs/" + queryLogId + "/report_log",
                dataType: "json",
                type: "POST",
            }),
        ).then((data) => {
            console.log(data);
            if (data.error) {
                throw data;
            }
            return notification({
                content: "Reported error: " + queryLogId,
                title: "Email Sent",
                messageType: "info",
                displayType: "bigBox",
                timeout: 10000,
            });
        });
    }
}
