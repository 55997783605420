import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import * as Class from "classnames";
import createCustomDialog from 'commonui/CustomDialog';
import { Menu, MenuItem } from "commonui/Menu";
import { bind } from "decko";
import { titleize } from "inflection";
import * as _ from "lodash";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as moment from "moment";
import * as React from "react";
import GearsState from "../../gears/GearsState/GearsState";
import ErrorDisplayComponent from "../ErrorDisplayComponent";
import Sidebar from "../Sidebar";
import AccountSettings from "./AccountSettings";
import styles from "./Header.scss";
import NotificationMenu from "./NotificationMenu";
import gearsDialog from '../kendo-dialog';
import GearsUser, { initialsClassForUser } from '../../gears/GearsState/GearsUser';

interface HeaderProps {
    gearsState: GearsState;
}

function BuildInfoItem(props: { title: string, children: React.ReactNode }): React.ReactElement<any> {
    return (
        <div>
            <strong>{props.title}:</strong>
            <span>{props.children}</span>
        </div>
    );
}

function BuildInfo({ gearsState }: HeaderProps): React.ReactElement<any> | null {
    if (!gearsState.buildInfo) {
        return null;
    }
    const { gears_core_ref, build_time, gears_online_ref, ...remainingBuildInfo } = gearsState.buildInfo;
    return <MenuItem
        id="admin-info"
        title="Build Info"
        icon="fa-info-circle">
        <Menu
            target="#admin-info"
            className={Class(styles["header-admin-info"])}
            mount="left-top">
            <BuildInfoItem title="Gears Core Ref">
                <a
                    target="_blank"
                    href={`https://gitlab.com/webfrontgears/gears_core/commit/${gears_core_ref}`}>
                    {gears_core_ref || ""}
                </a>
            </BuildInfoItem>
            <BuildInfoItem title="Gears Online Ref">
                <a
                    target="_blank"
                    href={`https://gitlab.com/webfrontgears/gears_online/commit/${gears_online_ref}`}>
                    {gears_online_ref || ""}
                </a>
            </BuildInfoItem>
            <BuildInfoItem title="Build Time">
                {moment(build_time, "YYYY-MM-DD HH:mm:SS Z")
                    .format("hh:mma Do MMMM YYYY")}
            </BuildInfoItem>
            {_.map(remainingBuildInfo, (value: string, key: string) => {
                return <BuildInfoItem key={key} title={titleize(key)}>{value || ""}</BuildInfoItem>;
            })}
        </Menu>
    </MenuItem>;
}

@observer
export default class Header extends React.Component<HeaderProps, never> {
    @observable private sidebarOpen: boolean = false;
    @observable private impersonateUsers: GearsUser[] = [];
    private backgroundColour: string;
    private logoURL: string;
    private logoLink: string;
    private isAdmin: boolean = false;
    private devMessage: string;
    private deployment: string;

    constructor(props: HeaderProps) {
        super(props);

        const element = document.getElementById("header-container");
        if (element) {
            this.backgroundColour = element.dataset.colour as string;
            this.logoURL = element.dataset.logo as string;
            this.logoLink = element.dataset.logoLink as string;
            this.isAdmin = element.dataset.admin !== "";
            this.devMessage = element.dataset.dev as string;
            this.deployment = element.dataset.deployment as string;
        }

        if (this.isAdmin) {
            $.ajax({
                url: "/users/gears_index",
                method: "POST",
                dataType: "json",
                data: JSON.stringify({
                    filter: {
                        logic: "and",

                    }
                })
            }).then((results) => {
                runInAction(() => {
                    for (const data of results.data) {
                        this.impersonateUsers.push(new GearsUser(data));
                    }
                });
            });
        }
    }

    @bind
    @action
    private onToggleSidebar() {
        this.sidebarOpen = !this.sidebarOpen;
    }

    @bind
    private onOpenAccountSettings() {
        createCustomDialog(<AccountSettings />);
    }

    @bind
    private onDebug() {
        $.post(`/users/${global.gearsState.user.id}/enable_query_log`, { duration: 60 * 5 })
            .then(() => global.gears.rootGrid.readLookups())
            .then(() => {
                gearsDialog({
                    title: "Debug Mode Activated",
                    content: "This mode will last for 5 minutes",
                    buttons: ["OK"],
                });
            });
    }

    @bind
    private onImpersonate(e: React.MouseEvent<HTMLElement>) {
        const id = e.currentTarget.dataset.id;
        $.ajax({
            url: "/users/" + id + "/impersonate_user",
            method: "POST",
        }).then(() => {
            window.location.reload();
        });
    }

    public render() {
        const userLookup = global.gearsState.user ? global.gearsState.user.lookup : undefined;
        const { menuState, notifications } = this.props.gearsState;
        return (
            <div>
                <header className={Class(styles.header, "header")}
                    style={{ backgroundColor: this.backgroundColour }}>
                    <ErrorDisplayComponent>
                        <div
                            onClick={this.onToggleSidebar}
                            className={Class(styles.hamburger, "hamburger", {
                                [styles.active]: this.sidebarOpen,
                                active: this.sidebarOpen,
                            })}>
                            <span />
                        </div>
                        <h1>
                            <a href={'/'}>
                                <img src={this.logoURL} />
                            </a>
                        </h1>
                        {this.devMessage && this.deployment !== "production" &&
                            <div className={Class(styles["dev-message"])}>
                                {this.devMessage}
                            </div>
                        }
                        <div className={Class(styles["action-container"], "action-container")}>
                            {notifications.active && <NotificationMenu notifications={notifications} className={styles["header-icon"]} />}
                            <div id="header-account"
                                className={userLookup.initials_class}>
                                {userLookup.initials}
                            </div>
                            <Menu target="#header-account" mount="left-top">
                                <MenuItem
                                    onClick={this.onOpenAccountSettings}
                                    title="Settings"
                                    icon="fa-cogs" />
                                <MenuItem
                                    onClick={window.logout}
                                    title="Logout"
                                    icon="fa-sign-out" />
                            </Menu>
                            <FontAwesomeIcon id="header-overflow" className={styles["header-icon"]} icon="ellipsis-v" />
                            <Menu target="#header-overflow" mount="left-top">
                                <MenuItem
                                    onClick={() => window.screenfull.toggle()}
                                    title="Fullscreen"
                                    icon="fa-expand-arrows" />

                                {this.isAdmin &&
                                    <MenuItem
                                        onClick={() => window.location.href = "/users/sudo"}
                                        title="Admin"
                                        icon="fa-user" />
                                }
                                {this.isAdmin &&
                                    <MenuItem
                                        onClick={this.onDebug}
                                        title="Debug Mode"
                                        icon="fa-bug" />
                                }
                                {this.impersonateUsers.length > 0 &&
                                    <MenuItem
                                        id="admin-impersonate"
                                        title="Impersonate"
                                        icon="fa-user-friends">
                                        <Menu
                                            target="#admin-impersonate"
                                            mount="left-top">
                                            {this.impersonateUsers.map((item) => {
                                                return (
                                                    <MenuItem
                                                        key={item.id}
                                                        onClick={this.onImpersonate}
                                                        data-id={item.id}
                                                        title={item.name} />
                                                )
                                            })}
                                        </Menu>
                                    </MenuItem>
                                }
                                <BuildInfo gearsState={this.props.gearsState} />
                            </Menu>
                        </div>
                    </ErrorDisplayComponent>
                </header>
                {menuState.loaded &&
                    <Sidebar
                        open={this.sidebarOpen}
                        menuState={menuState}
                        onBackdropClicked={this.onToggleSidebar} />
                }
            </div>
        );
    }
}
