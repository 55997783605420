// Generated by CoffeeScript 1.10.0
(function() {
  exports.ListItems = {
    has_saved_filters: true,
    has_server_lookups: true,
    has_modal: true,
    has_gears_grid_container: true,
    gears_model_name: "ListItem",
    table_name: "list_items",
    instance_name: "list_item",
    data_source: {
      sort: {
        field: "order_field",
        dir: "asc",
      },
    },
    grid: {
      columns: [
        {
          field: "order_field",
          title: "Order",
          width: 75,
          headerTemplate: Gears.headerIconTemplate({
            text: "Sort",
            icon: "fa fa-sort-numeric-asc fa-fw fa-lg",
          }),
        }, {
          field: "parent_list_item_id",
          title: "Parent",
          headerTemplate: Gears.headerIconTemplate({
            text: "Parent",
            icon: "fa fa-link fa-fw fa-lg",
          }),
          lookup: {
            inline: "parent_list_item",
            options: {
              include_text: false,
            },
          },
          width: 45,
        }, {
          field: "icon",
          title: "Icon",
          encoded: false,
          headerTemplate: Gears.headerIconTemplate({
            text: "Icon",
            icon: "fa fa-flag fa-fw fa-lg",
          }),
          template: function(c) {
            return c.iconTemplate({
              include_text: false,
            });
          },
          width: 45,
          attributes: {
            "class": "gears-table-icon",
          },
        }, {
          field: "item",
          title: "Item",
        }, {
          field: "id",
          title: "ID",
          width: 75,
        },
      ],
    },
  };

}).call(this);
