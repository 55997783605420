declare var global: any;

export function resizeFillRemainingHeight(parent, child, modifier?: number) {
    let gridElement, dataArea, gridHeight, otherElements, otherElementsHeight;
    modifier == null && (modifier = 0);
    if (parent != null) {
        gridElement = $(parent);
        dataArea = gridElement.find(child);
    } else {
        dataArea = $(child);
        gridElement = dataArea.parent();
    }
    gridHeight = gridElement.innerHeight();
    otherElements = gridElement.children().not(child).not(".gears-hidden");
    otherElementsHeight = 0;
    otherElements.each(function() {
        return otherElementsHeight += $(this).outerHeight();
    });
    dataArea.height(gridHeight - otherElementsHeight + modifier);
    return dataArea;
}

export function resizeGrid(grid) {
    return resizeFillRemainingHeight(grid, ".k-grid-content");
}

global.Gears = { ...global.Gears, resizeFillRemainingHeight, resizeGrid };
