import * as React from "react";
import * as ReactDOM from "react-dom";
import * as $ from "jquery";
import { when } from "mobx";
import { delay } from "lodash";
import Header from "../../react-components/Header";
import { default as gearsState, GearsState, GearsUser } from "../GearsState";

interface GearsGlobals {
    gearsState: GearsState;
    Gears: any;
}

declare const global: GearsGlobals;

function createHeader() {
    const headerElement = document.getElementById("header-container");
    if (headerElement) {
        ReactDOM.render(
            <Header gearsState={gearsState} />,
            headerElement,
        );
    }
}

const init: Function = _.once(() => {
    $(function () {
        delay(createHeader, 1);
    });
});
init();

export { init as header };
