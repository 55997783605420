import { action, computed, observable } from "mobx";
import { initials } from "../helpers/initials";

declare const _: _.LoDashStatic;

const GroupInitialsClass: { [group: number]: string } = {
    1: "bg-color-blue txt-color-white",
    2: "bg-color-red txt-color-white",
    3: "bg-color-green txt-color-white",
    100: "bg-color-purple txt-color-white"
};

export function initialsClassForUser(user: GearsUser): string {
    return user.webfront_initials_class || GroupInitialsClass[user.user_group_id] || "";
}

interface IRoles {
    [role: string]: true | undefined;

    administrator?: true | undefined;
    superuser?: true | undefined;
    notifications?: true | undefined;
    staff?: true | undefined;
}


export default class GearsUser {
    @observable public id: number;
    @observable public webfront_relations: any;
    @observable public webfront_initials: string;
    @observable public webfront_initials_class: string;
    @observable public backdrop_url: string;
    @observable public user_group_id: number;
    @observable public name: string = "";
    @observable public email_address: string = "";
    @observable public company_id: number;
    @observable public person_id: number;
    @observable public state: string;
    @observable public email_footer: string;
    @observable public created_at: Date;
    @observable public updated_at: Date;
    @observable public pin_code: number;
    @observable public config: any;
    @observable public user_config: any;
    @observable public as_lookup: any;

    constructor(userJS?: any) {
        if (userJS) {
            this.fromJS(userJS);
        }
    }

    @action
    public fromJS(newJS: any): void {
        this.id = newJS.id;
        this.webfront_relations = newJS.webfront_relations;
        this.webfront_initials = newJS.webfront_initials;
        this.webfront_initials_class = newJS.webfront_initials_class;
        this.backdrop_url = newJS.backdrop_url;
        this.user_group_id = newJS.user_group_id;
        this.name = newJS.name;
        this.email_address = newJS.email_address;
        this.company_id = newJS.company_id;
        this.person_id = newJS.person_id;
        this.state = newJS.state;
        this.email_footer = newJS.email_footer;
        this.created_at = newJS.created_at;
        this.updated_at = newJS.updated_at;
        this.pin_code = newJS.pin_code;
        this.config = newJS.config;
        this.user_config = newJS.user_config;
        this.as_lookup = newJS.as_lookup;
    }

    // @action loadUser() {
    //     $.get('/homelookups.js').then(action("loadUserCallback", (lookups) => {
    //         this.user = lookups.user;
    //     }));
    // }
    @computed get roles(): IRoles {
        return this.webfront_relations && this.webfront_relations.tags || {};
    }

    @computed get backdrop(): string {
        const url = this.user_config && (this.user_config.background || this.user_config.backdrop_url) || this.backdrop_url;
        if (url) {
            return url;
        }
        if (this.id) {
            return `/users/${this.id}/backdrop`;
        } else {
            return "/backdrop";
        }
    }

    @computed get companyName(): string {
        return _.get(this, "webfront_relations.company.company", "");
    }

    @computed get avatar(): string {
        return this.user_config && this.user_config.avatar || "";
    }

    @computed get debugMode(): boolean {
        return new Date(this.config.log_queries_until) > new Date();
    }

    @computed get lookup(): any {
        return {
            id: this.id,
            identifier: this.email_address,
            text: this.name,
            initials: this.webfront_initials || initials(this.name),
            initials_class: initialsClassForUser(this),
        };
    }
}
