// Generated by CoffeeScript 1.10.0
(function() {
  exports.GearsList = {
    id: "id",
    hasChildren: true,
    iconTemplate: function() {
      if (this.get("icon")) {
        return "<i class='" + (this.get("icon")) + " fa-lg'/>";
      } else {
        return "";
      }
    },
    demoIcon: function() {
      return "" + (this.get("icon"));
    },
    fields: {
      id: {
        type: "number",
        nullable: true,
      },
    },
  };

}).call(this);
