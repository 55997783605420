import * as Class from "classnames";
import * as React from "react";
import styles from "./Sidebar.scss";

import SidebarHeader, { IHeaderData } from "./SidebarHeader";
import SidebarItem, { ISidebarItem } from "./SidebarItem";

import { observer } from "mobx-react";
import MenuState from "../gears/GearsState/MenuState";
import { bind } from "decko";

export { IHeaderData };

declare var global: any;

interface ISidebarProps {
    menuState: MenuState;
    open?: boolean;
    onBackdropClicked: () => void;
}

@observer
export default class Sidebar extends React.Component<ISidebarProps, never> {
    private itemRefs: SidebarItem[] = [];

    @bind
    private onClick(child: SidebarItem) {
        for (let i = 0; i < this.props.menuState.menuItems.length; i++) {
            const item: SidebarItem = this.itemRefs[i];
            if (item !== child) {
                item.hide();
            }
        }
    }

    public render() {
        const { header, footer } = this.props.menuState;
        const sidebar = this.props.menuState.menuItems;

        return (
            <div>
                {this.props.open &&
                    <div
                        className={Class(styles.backdrop)}
                        onClick={this.props.onBackdropClicked} />
                }
                <div className={Class(styles.sidebar, { [styles.open]: this.props.open })}>
                    <div>
                        <SidebarHeader header={header} />
                        {sidebar.map((item, index) => {
                            return (
                                <SidebarItem
                                    ref={(ref) => this.itemRefs[index] = ref as SidebarItem}
                                    onClick={this.onClick}
                                    key={item.title}
                                    {...item}
                                />
                            );
                        })}
                    </div>
                    <div className={Class(styles.footer)}>
                        <span dangerouslySetInnerHTML={{ __html: footer }} />
                    </div>
                </div>
            </div>
        );
    }
}
