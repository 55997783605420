import * as Class from "classnames";
import * as gravatar from "gravatar";
import * as $ from "jquery";
import * as React from "react";
import * as ReactDOM from "react-dom";
import styles from "./SidebarHeader.scss";

import CircleImage from "commonui/CircleImage";
import SideBarUserModal from "./SidebarUserModal";

export interface IHeaderData {
    user: string;
    extra: string;
    background: string;
    avatar: string;
    email: string;
}

interface ISidebarHeaderProps {
    header: IHeaderData;
    // user?: string;
    // extra?: string;
    // background?: string;
    // avatar?: string;
    // email?: string;
}

/**
 * Creates the large open background display in sidebar
 */
export default class SidebarHeader extends React.Component<ISidebarHeaderProps, never> {
    public render() {
        const { user, extra, background, avatar, email } = this.props.header;
        return (
            <div
                className={Class(styles.header)}
                style={{ background: `url(${background || ""}) center / cover` }}>
                <div>
                    <CircleImage
                        src={avatar || gravatar.url(email || "", { d: "mm" })}
                        size={58}
                        margin={16} />
                </div>
                <div className={Class(styles.backdrop)} />
                <div className={Class(styles.frontdrop)}>
                    <span>
                        <span className={Class(styles.name)}>{user}</span>
                        <span className={Class(styles.extra)}>{extra}</span>
                    </span>
                </div>
            </div>
        );
    }
}
