/// <reference path="../../../typings/index.d.ts" />
import {default as ReactInlineEdit, InlineEditProps as ReactInlineEditProps}  from "../InlineEdit";
import {default as ReactWidget, installReactWidget, ReactWidgetOptions, ReactWidgetProps, wrapSimpleReactWidget} from "./ReactWidget";

interface InlineEditProps extends ReactInlineEditProps, ReactWidgetProps<string> {

}

export default function setupKendoInlineEdit() {
    // class InlineEdit extends ReactWidget<string, InlineEditProps> {
    //     constructor(element: Element, options?: ReactWidgetOptions<string>) {
    //         super(ReactInlineEdit, element, options);
    //     }
    // }
    //
    // installReactWidget(InlineEdit, {editorClassNames: 'test'});
    wrapSimpleReactWidget(ReactInlineEdit, {editorClassNames: null, displayClassNames: null});
}
